import React from "react";
// Customizable Area Start
import { Box, Typography, styled } from "@material-ui/core";
import { uploadImg } from "./assets";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area End

// Customizable Area Start
const PlaceholderText = styled(Typography)({
  fontFamily: "Poppins",
  fontSize: "20px",
  lineHeight: 0,
  color: "darkgray",
  opacity:0.4,
  whiteSpace: "nowrap",

  "@media(max-width: 650px)": {
    fontSize: "12px",
  },
});

const UploadIcon = styled("img")({
  width: "21px",
  height: "21px",

  "@media(max-width: 600px)": {
    width: "16px",
    height: "16px",
  },
});

const UploadBox = styled(Box)({
  height: '100%',
  "@media(max-width: 650px)": {
    zIndex:1,
    width:'100%',
    height:'44px'
  }
});

// Customizable Area End

export interface Props {
  // Customizable Area Start
  openBulkUploadImages(eventFields: React.MouseEvent,uniqueId: number): void;
  // Customizable Area End
}
interface S {}
interface SS {}
export class CustomFileInput extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    // Customizable Area Start
    const {
      openBulkUploadImages
    } = this.props;
    return (
      <UploadBox>

      <Box
        component={"label"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        border={"1px solid #E5E5E5"}
        padding={"0 8px"}
        height={"100%"}
        borderRadius={"8px"}
        style={{ gap: "15px", boxSizing:"border-box", cursor:"pointer" }}
        width={'100%'}
        data-test-id = "uploadInputId"
        onClick={(eventFields: React.MouseEvent)=>openBulkUploadImages(eventFields,0)}
      >
        <PlaceholderText>Upload image/video</PlaceholderText>
        <UploadIcon src={uploadImg} alt="upload-image" />
      </Box>
      </UploadBox>
    );
// Customizable Area End
  }
}