// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import CheckEmail from "../../blocks/forgot-password/src/CheckEmail.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import ResetConfirmation from "../../blocks/forgot-password/src/ResetConfirmation.web";
import ChangePassword from "../../blocks/forgot-password/src/ChangePassword.web";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import ContactList from "../../blocks/contactlist/src/ContactList";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf.web";
import Settings2 from "../../blocks/settings2/src/Settings2";
import UserProfileAdvance from "../../blocks/user-profile-advanced/src/UserProfileAdvance";
import Emaildeliverability from "../../blocks/emaildeliverability/src/Emaildeliverability";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Cfspeechtotext5 from "../../blocks/cfspeechtotext5/src/Cfspeechtotext5";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import CameraAccess from "../../blocks/cameraaccess/src/CameraAccess";
import Datastorage2 from "../../blocks/datastorage2/src/Datastorage2";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import PrivacyPolicy from "../../blocks/termsconditions/src/PrivacyPolicy.web";
import Account from '../../blocks/user-profile-advanced/src/Account.web'
import MobileNameSetting from '../../blocks/user-profile-advanced/src/MobileNameSetting'
import MobilePhoneSetting from '../../blocks/user-profile-advanced/src/MobilePhoneSetting'
import MobileCompanySettings from '../../blocks/user-profile-advanced/src/MobileCompanySettings'
import TermsConditionsWeb from "../../blocks/termsconditions/src/TermsConditions.web";
import DeleteConfirmation from '../../blocks/user-profile-advanced/src/DeleteConfirmation.web';
import CustomForm from '../../blocks/customform/src/CustomForm.web';
import BuildingInformation from "../../blocks/customform/src/BuildingInformation.web";
import ChangePasswordMobileScreen from "../../blocks/user-profile-advanced/src/ChangePasswordMobileScreen.web"
import UploadNewPhotoMobile from "../../blocks/customform/src/UploadNewPhotoMobile.web";
import GuestDashboard from "../../blocks/dashboardguests/src/GuestDashboard.web";

const routeMap = {
  BulkUploading: {
    component: BulkUploading,
    path: "/BulkUploading"
  },
  UploadNewPhotoMobile:{
    component: UploadNewPhotoMobile,
    path: "/UploadNewPhotoMobile"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/passwords",
    exact: true,
  },
  CheckEmail: {
    component: CheckEmail,
    path: "/passwords/check_email",
    exact: true,
  },
  ChangePassword: {
    component: ChangePassword,
    path: "/passwords/forgot_password",
    exact: true,
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  ResetConfirmation: {
    path: '/passwords/reset_confirmation',
    component: ResetConfirmation,
    exact: true,
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  TermsAndConditions: {
    component: TermsConditionsWeb,
    path: "/TermsAndConditions"
  },
  PrivacyPolicy: {
    component: PrivacyPolicy,
    path: "/PrivacyPolicy"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  ContactList: {
    component: ContactList,
    path: "/ContactList"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  SaveAsPdf: {
    component: SaveAsPdf,
    path: "/SaveAsPdf/:pdfSurveyId"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2"
  },
  UserProfileAdvance: {
    component: Account,
    exact: true,
    path: "/profile"
  },
  UserProfileAdvance2: {
    component: Account,
    exact: true,
    path: "/profile/logout"
  },
  CustomForm: {
    component: CustomForm,
    path: '/survey/:templateId/:surveyId/:sectionId/:subSectionId?',
    exact: true,
  },
  ProfileName: {
    component: MobileNameSetting,
    exact: true,
    path: "/profile/name"
  },
  ProfilePhone: {
    component: MobilePhoneSetting,
    exact: true,
    path: "/profile/phone"
  },
  ProfileCompany: {
    component: MobileCompanySettings,
    exact: true,
    path: "/profile/company"
  },
  Emaildeliverability: {
    component: Emaildeliverability,
    path: "/Emaildeliverability"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Cfspeechtotext5: {
    component: Cfspeechtotext5,
    path: "/Cfspeechtotext5"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    exact: true,
    path: "/"
  },
  BuildingInformation: {
    component: BuildingInformation,
    exact: true,
    path: "/BuildingInfo"
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: "/NavigationMenu"
  },
  LandingPage: {
    component: LandingPage,
    path: "/dashboard"
  },
  CameraAccess: {
    component: CameraAccess,
    path: "/CameraAccess"
  },
  Datastorage2: {
    component: Datastorage2,
    path: "/Datastorage2"
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: "/AdvancedSearch"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },

  Home: {
    component: AdvancedSearch,
    path: '/Home',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  DeleteConfirmation: {
    component: DeleteConfirmation,
    path: "/DeleteConfirmation"
  },
  ChangePasswordMobileScreen:{
    component: ChangePasswordMobileScreen,
    path: "/profile/ChangePasswordMobileScreen"
  },
  GuestDashboard:{
    component: GuestDashboard,
    path: "/survey-report/:linkShareToken/:linkShareId"
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh' }}>
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
