import React from "react";
import { logo } from "./assets";
import { styled } from "@material-ui/core";

const LogoImage = styled("img")({
  marginLeft: "42px",
  marginBottom: "60px",
  marginTop: "7px",
  maxWidth: '120px',
  maxHeight: '60px',

  "@media (max-width: 1320px)": {
    marginLeft: "25px",
  },
  "@media (max-width: 550px)": {
    marginLeft: "0px",
    marginTop: "0px",
    marginBottom: "0px",
    width: '71px',
    height: '36px',
  }
});

export const Logo = () => <LogoImage src={logo} />;
