import React, { FC } from "react";
import {
  FieldBox,
  LabelText,
  TextFieldCustom,
} from "../../email-account-login/src/EmailAccountLoginBlock.web";
import { styled } from "@material-ui/core";

interface Props {
  handleChange?: (e: any) => void;
  title: string;
  placeholder: string;
  error: any;
  value: string;
  name: string;
  disabled?: boolean;
  handleBlur?: (e: any) => void;
}

export const TextInputField = styled(TextFieldCustom)({
  color: "#A6B1B7",
  borderColor: "#DCE1E4",

  "& .MuiOutlinedInput-root": {
    backgroundColor: "#F9FAFA",
    "& :focused": {
      backgroundColor: "white",
    },

    "& input": {
      padding: "12px 8px !important",
      fontSize:'20px !important',
      "@media(max-width: 650px)": {
        fontSize: "12px !important",
      },
      "@media(max-width: 550px)": {
        fontSize: "12px !important",
         padding: "0px 8px !important",
      },
    },
  },
});

export const FieldLabel = styled(LabelText)({
  marginBottom: "0 !important",
  fontSize: '20px !important',
  "@media(max-width: 650px)": {
    fontSize: "12px !important",
  },
  "& >p": {
    marginBottom: "0 !important",
  },
});

export const TextInput: FC<Props> = ({
  title,
  placeholder,
  error,
  value,
  name,
  handleChange,
  disabled,
  handleBlur,
}) => {
  return (
    <FieldBox style={{ marginBottom: 0 }}>
      <FieldLabel>{title}</FieldLabel>
      <TextInputField
        id="outlined-basic"
        data-test-id="txtInputEmail"
        name={name}
        placeholder={placeholder}
        variant="outlined"
        type="text"
        value={value}
        onChange={handleChange}
        error={!!error}
        disabled={disabled}
        onBlur={handleBlur}
      />
    </FieldBox>
  );
};
