Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.headerInfo =
  "This is your profile, Here you can see and update your personal information.";
exports.btnTxtUploadFromStorage = "UPLOAD FROM STORAGE";
exports.btnTxtUploadFromCamera = "UPLOAD FROM CAMERA";
exports.btnTxtDelete = "DELETE IMAGE";
exports.btnTxtSave = "SAVE CHANGES";
// exports.placeHolder = require("../assets/placeholder.jpg");

exports.photoApiEndPoint =
  "profile/photo";

exports.photoUpdateApiMethod = "PUT";
exports.photoUpdateApiContentType = "multipart/form-data";

exports.photoGetApiContentType = "application/json";
exports.photoGetApiMethod = "GET";
// Customizable Area Start
exports.accountDeleteApiContentType = "application/json";
exports.accountDeleteApiMethod = "DELETE";
exports.accountDeleteAPIEndPoint = "account_block/accounts";
exports.userAccountPostAPIEndPoint = "bx_block_login/login";
exports.logoutParaOne = "Are you sure you want to log out";
exports.logoutParaTwo = "of your account?";
exports.logoutConfirmationText = "Are you sure you want to log out of your account?";
exports.cancel = "Cancel";
exports.logout = "Log Out";
exports.deleteParaOne = "Are you sure you want to";
exports.deleteParaTwo = "delete your account?";
exports.deleteConfirmationText = "Are you sure you want to delete your account?";
exports.deleteParagraphText = "Please note that this action is irreversible, and once completed, all data stored in your account will be permanently inaccessible.";
exports.deleteAccount = "Delete Account";
exports.keepAccount = "Keep Account";
exports.deleteConfirmHeading = "Your account was successfully deleted";
exports.deleteConfirmPara = "Goodbye for now! If you've decided to part ways with us, we understand. Your satisfaction matters to us, and we respect your decision. Remember, you can always come back whenever you're ready. Thank you for being a part of our community!";
exports.goToHomePage = "Go Back to Homepage";
exports.LogIn = "Log In";
exports.ChangePasswordApiContentType = "application/json";
exports.ChangePasswordApiEndPoint = "/bx_block_forgot_password/change_password";
exports.ChangePasswordApiMethod = "POST";
exports.patchMethod = "PATCH";
exports.changePasswordEndPoint = "bx_block_forgot_password/change_password";
exports.changeYourPassword = "Change your password";
exports.currentPassword = "Current password *";
exports.newPasswordInput = "New password *";
exports.confirmPasswordInput = "Confirm new password *";
exports.belowValidation = "Your password must contain";
exports.continuePassword = "Password successfully changed";
exports.continue = "Continue";
exports.confirm = "Confirm";
exports.capitalLetter = "At least one capital letter";
exports.lowerLetter = "At least one lowercase letter";
exports.numbers = "At least one number";
exports.character = "Minimum character length is 8 characters";
exports.lowerLetterRegax = /[a-z]/g;
exports.upperRegax = /[A-Z]/g;
exports.digitRegax = /\d/g;
// Customizable Area End
