import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");

// Customizable Area Start
export interface ValidResponseType {
  data: object;
}

export interface InvalidResponseType {
  errors: Array<ErrorPayloadType>;
}

export interface ErrorPayloadType {
  key: string;
}

export interface APIPayloadType {
  contentType: string;
  method: string;
  endPoint: string;
}

export interface PrivacyPolicyType {
  privacy_policy: {
    data: {
      attributes: {
        description: string;
      };
    }
  }
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  privacyPolicyData: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}
export default class PrivacyPolicyController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getPrivacyPolicyApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      privacyPolicyData: ""
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getPrivacyPolicyData();
    // Customizable Area End
  }

  // Customizable Area Start
  goBackToLogin = () => {
    window.history.back();
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (this.isInValidResponse(responseJson)) {
        this.apiFailCallBack(apiRequestCallId, responseJson);
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start
  apiCall = (data: APIPayloadType) => {
    let { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
    };

    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };


  apiFailCallBack = (apiRequestCallID: string, responseJSON: InvalidResponseType) => {
    if (apiRequestCallID === this.getPrivacyPolicyApiCallId) {
      this.setState({
        privacyPolicyData: ""
      })
    }
  }

  isValidResponse = (responseJson: PrivacyPolicyType) => {
    return responseJson && responseJson.privacy_policy;
  }

  isInValidResponse = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors;
  }

  apiSuccessCallBacks = (apiRequestCallID: string, responseJson: PrivacyPolicyType) => {
    if (apiRequestCallID === this.getPrivacyPolicyApiCallId) {
      this.setState({
        privacyPolicyData: responseJson.privacy_policy.data.attributes.description
      })
    }
  }

  getPrivacyPolicyData = () => {
    this.getPrivacyPolicyApiCallId = this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getPrivacyPolicyAPIEndPoint,
    });
  }
}
// Customizable Area End