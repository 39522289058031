import React from "react";
import AccountController, { Props } from "./AccountController.web";
import { MobileSettingWrapper } from "./MobileSettingWrapper";
import { Formik } from "formik";
import * as Yup from "yup";
import { TextInput } from "./TextInput.web";
import { Box, styled } from "@material-ui/core";

const NAME_ERROR = "Please enter a valid first name/last name.";

const NameBox = styled(Box)({
  "@media (max-width: 900px)": {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  }
})

export default class MobileNameSettings extends AccountController {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    return (
      <Formik
        initialValues={{
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          countryCode: this.state.phoneCode,
          phoneNumber: this.state.phoneNumber,
          company: this.state.company
        }}
        onSubmit={values => {
          this.handleSubmit(values);
        }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          firstName: Yup.string()
            .required(NAME_ERROR)
            .matches(/^[a-zA-Z][a-zA-Z\- ]{0,48}[a-zA-Z]$/, NAME_ERROR),
          lastName: Yup.string()
            .required(NAME_ERROR)
            .matches(/^[a-zA-Z][a-zA-Z\- ]{0,48}[a-zA-Z]$/, NAME_ERROR)
        })}
      >
        {({
          handleSubmit,
          setValues,
          values,
          errors,
          handleBlur,
          handleChange,
        }) => (
          <MobileSettingWrapper
          onSubmit={() => handleSubmit()}
          subtitle="Name"
          error={errors.firstName! || errors.lastName!}
          success={this.state.showSuccessMessage}
          navigation={this.props.navigation}
          onCancel={() => {
              setValues({
                countryCode: this.state.phoneCode,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                company: this.state.company,
                phoneNumber: this.state.phoneNumber
              });
            }}
            goBack={this.props.navigation.goBack}
            account={{
              name: this.fullName,
              id: localStorage.getItem("user-id")
            }}>
            <NameBox>
              <TextInput
                value={values.firstName}
                name="firstName"
                title="First Name *"
                placeholder="User first name"
                data-test-id="firstName"
                handleChange={handleChange}
                error={
                  (errors.firstName) ||
                  (errors.lastName)
                }
                handleBlur={handleBlur}
              />
              <TextInput
                value={values.lastName}
                name="lastName"
                title="Last Name *"
                placeholder="User last name"
                handleChange={handleChange}
                error={
                  (errors.firstName) ||
                  (errors.lastName)
                }
                handleBlur={handleBlur}
              />
            </NameBox>
          </MobileSettingWrapper>
        )}
      </Formik>
    );
  }
}
