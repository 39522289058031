import React from "react";
// Customizable Area Start
import { Box, Tooltip, styled } from "@material-ui/core";
import { LabelText } from "../../email-account-login/src/EmailAccountLoginBlock.web";
import { CustomFileInput } from "./UplodaFileButton";
import "../assets/styles/index.css";
import { CustomFieldText } from "./CustomFieldText";
import { CustomFieldCheckboxes } from "./CustomFieldCheckboxes";
import { CustomFieldRadio } from "./CustomFieldRadio";
import { CustomFieldSelect } from "./CustomFieldSelect";
import { CustomFieldController, Props } from "./CustomFieldController";
import { withStyles } from '@material-ui/core/styles';
// Customizable Area End

// Customizable Area Start
const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "white",
    border: "1px solid #F87171",
    color: "black",
    top:"-10px !important",
    height: "18px",
    padding: "4px 10px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    fontSize: "12px",
    "& .MuiTooltip-arrow": {
      color: "#F87171",
      left: "50% !important",
      transform: "none !important"
    }}
}))(Tooltip);

const FieldBox = styled(Box)({
  // display: "grid",
  // gridTemplateColumns: "3fr auto",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: "10px",
  "@media(max-width: 650px)": {
    // gridTemplateColumns: "1fr",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "9px",
    // gridTemplateRows: '1fr 1fr',
  },
});

const SidefieldsWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  gap: "10px",
  alignItems: "center",

  "@media(max-width: 650px)": {
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100%"
  },
});
// Customizable Area End

export class CustomField extends CustomFieldController {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
// Customizable Area Start
    const {
      field_name,
      field_options,
      field_type,
      id,
      is_speech_to_text_required,
      is_upload_image_video_required,
      fieldRef,
      openBulkUploadImages,
      isError,
      is_mandatory,
      dynamic_dropdown_type,
      handleMouseDown,
      handleMouseRelease
    } = this.props;
    const { error, inputCheckboxValues, inputValue } = this.state;
    const hasErrorCheck = this.hasError || isError || !!error;
    return (
      <CustomTooltip
        placement="bottom-start"
        title={this.state.error || this.hasError ? this.state.error || "Please enter data" : ""}
        open={this.hasError || !!this.state.error}
        arrow
        PopperProps={
        {
          popperOptions: {
            modifiers: {
              flip: { enabled: false }
            }
          },  
        style:{
          zIndex: 0
        },
        disablePortal: true
        }
      }
      >
        <div ref={fieldRef}>
          <LabelText>{field_name}{is_mandatory && ' *'}</LabelText>
          <FieldBox
            style={{
              ...(is_upload_image_video_required && { gap: "20px" }),
            }}
          >
            {(field_type === "text" || field_type === "numeric") && (
              <CustomFieldText
                data-test-id="speechTestId"
                handleBlur={this.handleBlur}
                handleChange={this.changeHandler}
                handleMouseDown={handleMouseDown}
                handleMouseRelease={handleMouseRelease}
                inputValue={inputValue}
                id={id}
                is_speech_to_text_required={is_speech_to_text_required}
                hasError={hasErrorCheck}
                type={field_type}
              />
            )}
            {field_type === "checkbox" && (
              <CustomFieldCheckboxes
                handleBlur={this.handleBlur}
                handleChange={this.checkboxChangeHandler}
                options={field_options}
                selectedOptions={inputCheckboxValues}
                hasError={hasErrorCheck}
              />
            )}
            {field_type === "radio" && (
              <CustomFieldRadio
                handleBlur={this.handleBlur}
                handleChange={this.changeHandler}
                options={field_options}
                inputValue={inputValue}
                hasError={hasErrorCheck}
              />
            )}
            {field_type === "select" && (
              <CustomFieldSelect
                handleBlur={this.handleBlur}
                handleChange={this.changeHandler}
                options={field_options}
                inputValue={inputValue}
                hasError={hasErrorCheck}
                placeHolder={'Select'}
              />
            )}
            {field_type === "dynamic_dropdown" && (
              <CustomFieldSelect
                handleBlur={this.handleBlur}
                handleChange={this.changeHandler}
                options={this.getDynamicOptions(dynamic_dropdown_type)}
                inputValue={inputValue}
                hasError={hasErrorCheck}
                placeHolder={this.getPlaceHolderValue(dynamic_dropdown_type)}
              />
            )}
            <SidefieldsWrapper>
              {field_type !== "text" && is_speech_to_text_required && (
                <CustomFieldText
                  data-test-id="speechTestId"
                  handleMouseDown={handleMouseDown}
                  handleMouseRelease={handleMouseRelease}
                  handleBlur={this.handleBlur}
                  handleChange={this.changeHandler}
                  inputValue={inputValue}
                  id={id}
                  isAudioInput
                  is_speech_to_text_required={is_speech_to_text_required}
                  hasError={hasErrorCheck}
                  type={"text"}
                />
              )}
              {is_upload_image_video_required && <CustomFileInput openBulkUploadImages={(eventFields: React.MouseEvent)=>openBulkUploadImages(eventFields,0)} /> }
            </SidefieldsWrapper>
          </FieldBox>
        </div>
      </CustomTooltip>
    );
  }
  // Customizable Area End
}
