import React from "react";
// Customizable Area Start
import Dialog from "@material-ui/core/Dialog";
import { Typography, styled } from "@material-ui/core";
import { confirmationIcon, errorMessageIcon } from "./assets";
import {
  SaveButton as CloseBtn,
  NextButton as DoneBtn,
} from "./CustomFormWrapper";
import ConfirmationPopUpController, {Props} from "./ConfirmationPopUpController";

const PopUpIcon = styled("img")({
  height: "92px",
  width: "92px",
});

const Message = styled(Typography)({
  fontFamily: "Poppins, Inter, sans-serif",
  fontSize: "25px",
  lineHeight: "37px",
  fontWeight: 400,
  textAlign: "center",
  maxWidth: "555px",
});

const CustomConfiramtionDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    padding: "60px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
  },
});

class ConfirmationDialog extends ConfirmationPopUpController {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    const { open, handleClose, error, saveBtnResponse } = this.props;
    if (!open && saveBtnResponse) {
      return null;
    }
    return (
      <CustomConfiramtionDialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <PopUpIcon src={error ? errorMessageIcon : confirmationIcon} />
        <Message id="alert-dialog-description">
          {this.popUpText}
        </Message>
        {error ? (
          <CloseBtn data-test-id="error-popup-btn" onClick={handleClose}>
            Close
          </CloseBtn>
        ) : (
          <DoneBtn data-test-id="success-popup-btn" onClick={handleClose}>
            Done
          </DoneBtn>
        )}
      </CustomConfiramtionDialog>
    );
  }
}

export default ConfirmationDialog;
// Customizable Area End