import React from "react";
// Customizable Area Start
import { Box, Button, Drawer, Typography, styled } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import DeleteConfirmationController, {
  Props,
  configJSON
} from "./DeleteConfirmationController.web";
import { logo, login, rightIcon } from "./assets";
// Customizable Area End

export default class DeleteConfirmation extends DeleteConfirmationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderDrawer = () => {
    return (
      <LeftNavigation>
        <DrawerLoginContainer onClick={this.handleLoginProfile}>
          <img src={login} alt="login" data-testid="drawerOpenId" />
          <LoginHeading>{configJSON.LogIn}</LoginHeading>
        </DrawerLoginContainer>
      </LeftNavigation>
    );
  };

  render() {
    return (
      <DeleteConfirmationContainer>
        <DeleteConfirmHeader>
          <LogoIcon>
            <MenuIcon data-testid="drawerId" onClick={this.handleDrawer} />
          </LogoIcon>
          <LogoImage src={logo} alt="logo" />
          <RightLoginContainer onClick={this.handleLoginProfile}>
            <img src={login} alt="login" />
            <LoginHeading>{configJSON.LogIn}</LoginHeading>
          </RightLoginContainer>
        </DeleteConfirmHeader>
        <DeleteConfirmMainContainer>
          <DeleteConfirmContainer>
            <ImageIcon src={rightIcon} alt="rightIcon" />
            <DeleteConfirmationHeading>
              {configJSON.deleteConfirmHeading}
            </DeleteConfirmationHeading>
            <DeleteConfirmationParagraphOne>
              {configJSON.deleteConfirmPara}
            </DeleteConfirmationParagraphOne>
          </DeleteConfirmContainer>
          <GoToHomePageButton
            data-testid="goToHomePageId"
            onClick={this.handleLoginProfile}
          >
            {configJSON.goToHomePage}
          </GoToHomePageButton>
        </DeleteConfirmMainContainer>
        <Box>
          <Drawer open={this.state.openDrawer} onClose={this.handleDrawer}>
            {this.renderDrawer()}
          </Drawer>
        </Box>
      </DeleteConfirmationContainer>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const DeleteConfirmationContainer = styled(Box)({
  height: "100vh"
});

const DeleteConfirmMainContainer = styled(Box)({
  marginTop: "50px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  position: "relative",
  "@media(max-width:550px)": {
    height: "90%",
    marginTop: "20px",
    borderRadius: "8px 8px 0px 0px",
    background: "var(--Scio-Services-Basic-White, #FFF)",
    boxShadow: "0px -1px 8px 0px rgba(0, 0, 0, 0.08)"
  }
});

const ImageIcon = styled("img")({
  height: "141px",
  width: "141px",
  "@media(max-width:550px)": {
    height: "44px",
    width: "44px"
  }
});

const DeleteConfirmContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "20px",
  width: "80%"
});

const DeleteConfirmationHeading = styled(Typography)({
  marginTop: "30px",
  fontFamily: "Poppins",
  fontWeight: 600,
  fontSize: "30px",
  textAlign: "center",
  "@media(max-width:550px)": {
    fontSize: "20px",
    marginTop: "0px",
    width: "300px"
  }
});
const DeleteConfirmationParagraphOne = styled(Typography)({
  fontFamily: "Poppins",
  fontSize: "18px",
  fontWeight: 500,
  lineHeight: "27px",
  letterSpacing: "0em",
  textAlign: "center",
  color: "#A6B1B7",
  "@media(max-width:550px)": {
    fontSize: "14px",
    width: "324px",
    lineHeight: "normal",
    marginBottom: "100px"
  },
  "@media(max-width:320px)": {
    width: "90%"
  }
});

const GoToHomePageButton = styled(Button)({
  position: "absolute",
  bottom: 25,
  color: "white",
  height: "44px",
  width: "258px",
  borderRadius: "8px",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "24px",
  letterSpacing: "0em",
  textAlign: "left",
  textTransform: "capitalize",
  background: "linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 100%)",
  "@media(max-width:550px)": {
    marginBottom: "0px"
  }
});

const DeleteConfirmHeader = styled(Box)({
  display: "flex",
  height: "76px",
  padding: "8px 63px 8px 42px",
  alignItems: "center",
  justifyContent: "space-between",
  boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
  "@media(max-width:550px)": {
    height: "48px",
    padding: "0px"
  }
});

const LogoImage = styled("img")({
  width: "118.723px",
  height: "60px",
  flexShrink: 0,
  "@media(max-width:767px)": {
    textAlign: "center",
    margin: "auto"
  },
  "@media(max-width:550px)": {
    height: "36px",
    width: "71px",
    alignSelf: "center",
    paddingRight: "20px"
  }
});

const RightLoginContainer = styled(Box)({
  display: "flex",
  gap: "10px",
  "@media(max-width:767px)": {
    display: "none"
  }
});

const DrawerLoginContainer = styled(Box)({
  cursor: "pointer",
  display: "flex",
  gap: "10px"
});

const LeftNavigation = styled(Box)({
  display: "none",
  "@media(max-width:767px)": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "50px",
    width: "300px",
    marginTop: "20px"
  }
});

const LogoIcon = styled(Typography)({
  display: "none",
  "@media(max-width:767px)": {
    display: "block"
  },
  "@media(max-width:550px)": {
    marginLeft: "20px"
  }
});

const LoginHeading = styled(Typography)({
  fontSize: "18px",
  fontWeight: 500,
  cursor: "pointer"
});
// Customizable Area End
