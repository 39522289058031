// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";

export interface Props {
  open: boolean;
  handleClose: () => void;
  error: string;
  saveBtnResponse: boolean;
}

interface S {}

interface SS {}

export default class ConfirmationPopUpController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: any) {
    super(props);

    this.state = {};
  }

  get popUpText() {
    if (this.props.error) {
      return this.props.error;
    }

    if (this.props.saveBtnResponse) {
      return "The survey you wanted to save has been saved successfully";
    }

    return "The survey you wanted to submit has been submitted successfully";
  }
}
// Customizable Area End
