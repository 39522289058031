import { styled, InputAdornment } from "@material-ui/core";
import React, { MouseEventHandler, TouchEventHandler } from "react";
import { CustomIconButton } from "../../email-account-login/src/ChangePasswordModal.web";
import { audioImg } from "./assets";
import { BlockComponent } from "../../../framework/src/BlockComponent";

const VoiceIcon = styled("img")({
  width: "32px",
  height: "32px",
  pointerEvents:'none',
  "&::-webkit-user-select":'none',
  "@media(max-width: 650px)": {
    width: "24px",
    height: "24px",
    pointerEvents: 'none',
  },
});

interface Props {
  handleMouseDown?: MouseEventHandler<HTMLButtonElement> | TouchEventHandler<HTMLButtonElement>;
  handleMouseRelease?: MouseEventHandler<HTMLButtonElement> | TouchEventHandler<HTMLButtonElement>;
}

interface S {}

interface SS {}

export class VoiceInputButton extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { handleMouseDown, handleMouseRelease } = this.props
    return (
      <InputAdornment position="end">
        <CustomIconButton aria-label="vice-input" edge="end"
         data-test-id="voiceButtonId"
         onMouseDown={handleMouseDown as MouseEventHandler<HTMLButtonElement>}
         onMouseUp={handleMouseRelease as MouseEventHandler<HTMLButtonElement>}
         onTouchStart={handleMouseDown as TouchEventHandler<HTMLButtonElement>}
         onTouchEnd={handleMouseRelease as TouchEventHandler<HTMLButtonElement>}
        >
          <VoiceIcon src={audioImg}
           
          />
        </CustomIconButton>
      </InputAdornment>
    );
  }
}
