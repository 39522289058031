import React, { ReactNode } from "react";
// Customizable Area Start
import AccountController, { Props, configJSON } from "./AccountController.web";
import {
  Box,
  styled,
  Button,
  Typography,
  Modal,
  Fade,
  Backdrop
} from "@material-ui/core";
import ContentWrapper from "./ContentWrapper.web";
import { PhotoForm } from "./PhotoForm.web";
import { AccountDetails } from "./AccountDetailsSection.web";
import { SettingsTitle } from "./SettingTitle";
import { AccountActionSection } from "./AccountActionsSection";
import { MobileMainSection } from "./MobileMainSection";
import { logoutModalEmailIcon, deleteModalIcon } from "./assets";
import ChangePabsswordModal from "./ChangePasswordModal.web";
import ProfileImageAdjustPopUp from "./ImageAdjustPopUp";
import Back from "@material-ui/icons/ArrowBackIosRounded";
// Customizable Area End

// Customizable Area Start
const BLUE = "rgba(79, 195, 253, 1)";
const GREY = "rgba(192, 200, 205, 1)";

const FormContainer = styled(Box)({
  padding: "32px 140px",

  borderTop: `11px solid ${BLUE}`,
  borderRadius: "16px",
  borderLeft: `1px solid ${GREY}`,
  borderRight: `1px solid ${GREY}`,
  borderBottom: `1px solid ${GREY}`,
  position: "relative",

  "@media(max-width: 900px)": {
    border: "none",
    padding: 0,
    marginTop:'-46px'
  },
  "@media(max-width: 550px)": {
    marginTop:'-46px',
    margin: "0px 10px 0px 10px"
  }
});

export const MainContent = styled(Box)({
  flex: 1,
  "@media(max-width: 900px)": {
    maxWidth: "100%",
    borderRadius: "8px",
    boxShadow: "rgba(17, 17, 26, 0.3) -1px -5px 5px -2px",
    padding: "15px 24px",
    marginTop: "20px"
  },
  "@media(max-width: 550px)": {
    padding: "0px",
    display: "flex",
    flexDirection: "column",
    boxShadow: "rgba(17, 17, 26, 0.3) -1px -5px 5px -2px",
    marginTop: "48px"
  }
});

const LogoutModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px"
});

const LogoutModalParagraphContainer = styled(Fade)({
  backgroundColor: "white",
  width: "50%",
  height: "250px",
  borderRadius: "8px",
  lineHeight: "2rem",
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "25px",
  "@media(max-width: 767px)": {
    width: "80%"
  },
  "@media(max-width: 600px)": {
    gap: "15px"
  }
});

const LogutParaOne = styled(Typography)({
  fontSize: "30px",
  textAlign: "center",
  fontWeight: 600,
  fontFamily: "Poppins",
  "@media(max-width: 992px)": {
    fontSize: "22px"
  },
  "@media(max-width: 550px)": {
    display: "none"
  }
});

const LogutParaContinue = styled(Typography)({
  fontWeight: 600,
  fontFamily: "Poppins",
  fontSize: "30px",
  textAlign: "center",
  "@media(max-width: 992px)": {
    fontSize: "22px"
  },
  "@media(max-width: 550px)": {
    fontSize: "20px"
  }
});

const LogoutConfirmationStyleText = styled(Typography)({
  "@media(min-width: 551px)": {
    display: "none"
  },
  "@media(max-width: 550px)": {
    color: "var(--Scio-Services-Basic-Black, #181818)",
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    width: "280px",
    alignSelf: "center",
    marginBottom: "200px"
  },
  "@media(max-width: 290px)": {
    fontSize: "14px",
    width: "90%",
    margin: "auto"
  }
});

const DeleteConfirmationStyleText = styled(Typography)({
  "@media(min-width: 551px)": {
    display: "none"
  },
  "@media(max-width: 550px)": {
    color: "var(--Scio-Services-Basic-Black, #181818)",
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    width: "280px"
  },
  "@media(max-width: 290px)": {
    fontSize: "14px",
    width: "90%",
    margin: "auto"
  }
});

const DeleteParaContainer = styled(Box)({
  width: "74%",
  "@media(max-width: 550px)": {
    width: "100%",
    margin: "0px",
    padding: "0px",
    display: "flex",
    justifyContent: "center"
  }
});

const DeletePara = styled(Typography)({
  fontSize: "18px",
  fontWeight: 400,
  fontFamily: "Poppins",
  color: "#A6B1B7",
  textAlign: "left",
  "@media(max-width: 992px)": {
    fontSize: "16px"
  },
  "@media(max-width: 550px)": {
    color: "var(--Scio-Services-Neutral-300---Neutral, #A6B1B7)",
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    marginBottom: "200px",
    width: "335px",
    alignSelf: "center"
  },
  "@media(max-width: 320px)": {
    width: "90%"
  }
});

const LogoutButtonContainer = styled(Box)({
  display: "flex",
  gap: "18px",
  justifyContent: "center",
  width: "85%",
  "@media(max-width:900px)": {
    position: "absolute",
    bottom: 0
  },
  "@media(max-width:550px)": {
    height: "98px",
    alignItems: "space-between",
    flexDirection: "column-reverse",
    marginBottom: "50px"
  }
});

const CancelButton = styled(Button)({
  backgroundColor: "rgb(237,245,253)",
  fontFamily: "Poppins",
  color: "#0090CF",
  fontWeight: 500,
  borderRadius: "8px",
  textTransform: "capitalize",
  height: "44px",
  fontSize: "16px",
  width: "235px",
  padding: "10px 16px 10px 16px",
  lineHeight: "24px",
  "&:hover": {
    backgroundColor: "rgb(237,245,253)",
    color: "#0090CF"
  },
  "@media(max-width: 992px)": {
    fontSize: "14px"
  },
  "@media(max-width: 550px)": {
    height: "45px",
    width: "100%",
    padding: "0px"
  }
});

const LogoutButton = styled(Button)({
  background: "linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 100%)",
  color: "#FFF",
  borderRadius: "8px",
  height: "44px",
  width: "235px",
  fontSize: "16px",
  fontWeight: 500,
  padding: "10px 16px 10px 16px",
  lineHeight: "24px",
  fontFamily: "Poppins",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 100%)",
    color: "#FFF"
  },
  "@media(max-width: 992px)": {
    fontSize: "14px"
  },
  "@media(max-width: 550px)": {
    height: "45px",
    width: "100%",
    padding: "0px"
  }
});

export const BlurBackground = styled(Box)({
  background: "rgba(127, 136, 149, 0.47)",
  backdropFilter: "blur(5px)",
  "-webkit-backdrop-filter": "blur(5px)",
  borderRadius: "16px",
  position: "absolute",
  zIndex: 1,
  width: "100%",
  height: "100%",
  top: 0,
  left: 0
});

const UserLogoutPopupParagraphContainer = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  zIndex: 2,
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
  height: "250px",
  lineHeight: "2rem",
  width: "50%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "25px",

  "@media(max-width: 900px)": {
    transform: "none",
    width: "100%",
    height: "100%",
    zIndex: 2,
    top: 0,
    left: 0,
    boxSizing: "border-box",
    boxShadow: "none"
  },
  "@media(max-width: 550px)": {
    gap: "15px"
  }
});

const UserDeletePopupParagraphContainer = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  zIndex: 2,
  height: "350px",
  lineHeight: "2rem",
  width: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "8px",
  justifyContent: "center",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "25px",
  "@media(max-width: 550px)": {
    gap: "15px",
    top: 0,
    left: 0,
    zIndex: 0,
    height: "100%",
    lineHeight: "2rem",
    width: "330px",
    transform: "translate(-50%, -50%)",
    padding: "0px",
    borderRadius: "8px"
  },
  "@media(max-width: 900px)": {
    transform: "none",
    width: "100%",
    height: "100%",
    zIndex: 2,
    top: 0,
    left: 0,
    boxSizing: "border-box",
    boxShadow: "none"
  }
});

const UserChangePasswordContinuePopup = styled(Box)({
  top: "50%",
  position: "absolute",
  zIndex: 2,
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "8px",
  backgroundColor: "white",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
  height: "250px",
  padding: "20px",
  width: "50%",
  lineHeight: "2rem",
  flexDirection: "column",
  display: "flex",
  alignItems: "center",
  gap: "25px",
  "@media(max-width: 767px)": {
    height: "200px",
    width: "80%"
  },
  "@media(max-width: 510px)": {
    height: "707px",
    width: "100%",
    padding: "25px",
    borderRadius: 0,
    top: "46%"
  }
});

const MainContainerAccount = styled(Box)({
  "@media(max-width:900px)": {
    marginTop: "0px"
  },
  "@media(max-width:550px)": {
    marginTop: "0px",
    borderRadius: "8px 8px 0px 0px",
    background: "var(--Scio-Services-Basic-White, #FFF)",
    boxShadow: "0px -1px 8px 0px rgba(0, 0, 0, 0.08)"
  }
});

const LogoutEmailIcon = styled("img")({
  "@media(max-width:550px)": {
    height: "34px",
    width: "40px"
  },
  "@media(max-width: 290px)": {
    height: "25px",
    width: "30px"
  }
});

const BackHeaderDiv = styled("div")({
  display: "none",
  "@media(max-width:510px)": {
    height: "30px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "6px"
  }
});

const UserSettingHeaderText = styled(Typography)({
  fontSize: "14px",
  color: "black",
  fontWeight: 500
});

const BackArrowButton = styled(Back)({
  fontSize: "16px"
});

const PasswordHeaderText = styled(Typography)({
  fontSize: "12px",
  color: "black"
});

const MiddelContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "468px",
  gap: "20px"
});

// Customizable Area End
export default class Account extends AccountController {
  constructor(props: Props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
  }

  render(): ReactNode {
    return (
      // Customizable Area Start
      <ContentWrapper
      data-test-id="contentWrapperDefaultId"
        handleLogoutClick={
          window.innerWidth > 900
            ? this.handleLogoutModal
            : this.handleLogoutPopup
        }
        account={{
          id: localStorage.getItem("user-id"),
          name: this.fullName
        }}
        navigation={this.props.navigation}
        profileImg={this.uploadedImageSrc}
        handleProfileNavigate={this.handleprofileClicks}
      >
        <MainContainerAccount>
          <MainContent>
            <SettingsTitle
              data-test-id={"settingTitleId"}
              error={this.state.error}
              success={this.state.showSuccessMessage}
              onTitleClick={this.goBack}
            />
            {this.state.account && (
              <FormContainer>
                <PhotoForm
                data-test-id = 'photoFormId'
                  src={this.uploadedImageSrc}
                  onFileUpload={this.handleFileUpload}
                  error={window.innerWidth > 900 ? this.currentErrorMessage : ''}
                  success={window.innerWidth > 900 ? this.state.showSuccessMessage && this.showMessage: false}
                  onFileDelete={this.deletePhoto}
                  onEditPhotoClick={this.togglePhotoAdjustModal}
                />
                {window.innerWidth > 900 ? (
                  <>
                    <AccountDetails
                      data-testid="changePassModalId"
                      phoneNumber={this.state.phoneNumber}
                      countryCode={this.state.phoneCode}
                      company={this.state.company}
                      email={this.state.account.email}
                      firstName={this.state.firstName}
                      lastName={this.state.lastName}
                      setError={err => this.setState({ error: err })}
                      handleSubmit={this.handleSubmit}
                      isLoading={this.state.isFetching}
                      handleChangeModal={this.handleChangePassModalClose}
                    />
                    <AccountActionSection
                      data-testid="modalDeleteId"
                      onDeleteAccountClick={this.handleDeleteModal}
                      onLogoutClick={this.handleLogoutPopup}
                    />
                  </>
                ) : (
                  <MobileMainSection
                  data-testid = "mobileScetionId"
                    onLogoutClick={this.handleLogoutPopup}
                    onDeleteAccountClick={this.handleDeleteModal}
                    phoneNumber={this.state.phoneNumber}
                    countryCode={this.state.phoneCode}
                    company={this.state.company}
                    email={this.state.account.email}
                    firstName={this.state.firstName}
                    lastName={this.state.lastName}
                    navigation={this.props.navigation}
                    handleChangePassModalClose={this.handleChangePassModalClose}
                  />
                )}
                {this.state.openLogoutPopup && <BlurBackground />}
                {this.state.openLogoutPopup && (
                  <UserLogoutPopupParagraphContainer>
                    <LogoutEmailIcon
                      src={logoutModalEmailIcon}
                      alt="emailIcon"
                      width={35.89}
                      height={26.67}
                    />
                    <Box>
                      <LogutParaOne data-testid="popupTaskId">
                        {configJSON.logoutParaOne}
                      </LogutParaOne>
                      <LogutParaOne>{configJSON.logoutParaTwo}</LogutParaOne>
                      <LogoutConfirmationStyleText>
                        {configJSON.logoutConfirmationText}
                      </LogoutConfirmationStyleText>
                    </Box>
                    <LogoutButtonContainer>
                      <CancelButton
                        data-testid="popupLogoutId"
                        variant="contained"
                        onClick={this.handleLogoutPopupCancle}
                      >
                        {configJSON.cancel}
                      </CancelButton>
                      <LogoutButton
                        data-testid="logoutProfileId"
                        variant="contained"
                        onClick={this.handleLogoutProfile}
                      >
                        {configJSON.logout}
                      </LogoutButton>
                    </LogoutButtonContainer>
                  </UserLogoutPopupParagraphContainer>
                )}
                {this.state.openDeleteModal && <BlurBackground />}
                {this.state.openDeleteModal && (
                  <UserDeletePopupParagraphContainer>
                    <img
                      src={deleteModalIcon}
                      alt="deleteIcon"
                      width={26.65}
                      height={26.67}
                    />
                    <Box>
                      <LogutParaOne>{configJSON.deleteParaOne}</LogutParaOne>
                      <LogutParaOne>{configJSON.deleteParaTwo}</LogutParaOne>
                      <DeleteConfirmationStyleText>
                        {configJSON.deleteConfirmationText}
                      </DeleteConfirmationStyleText>
                    </Box>
                    <DeleteParaContainer>
                      <DeletePara>{configJSON.deleteParagraphText}</DeletePara>
                    </DeleteParaContainer>
                    <LogoutButtonContainer>
                      <CancelButton
                        data-testid="deleteUserId"
                        variant="contained"
                        onClick={this.deleteUserProfile}
                      >
                        {configJSON.deleteAccount}
                      </CancelButton>
                      <LogoutButton
                        data-testid="modalLogoutId"
                        variant="contained"
                        onClick={this.handleDeleteModal}
                      >
                        {configJSON.keepAccount}
                      </LogoutButton>
                    </LogoutButtonContainer>
                  </UserDeletePopupParagraphContainer>
                )}
                {this.state.openSecModal && <BlurBackground />}
                {this.state.openPhotoAfjustModal && (
                  <ProfileImageAdjustPopUp
                    open={this.state.openPhotoAfjustModal}
                    onClose={this.togglePhotoAdjustModal}
                    image={this.uploadedImageSrc}
                    onAdjust={this.handleImageAdjust}
                  />
                )}
                {this.state.openSecModal && (
                  <ChangePabsswordModal
                    data-testid="changePasswordTestID"
                    belowValidation={configJSON.belowValidation}
                    confirmPasswordInput={configJSON.confirmPasswordInput}
                    changepasswordHeading={configJSON.changeYourPassword}
                    currentPasswordInput={configJSON.currentPassword}
                    newPasswordInput={configJSON.newPasswordInput}
                    validationError={this.state.validationError}
                    currentPass={this.state.currentPass}
                    enableCurrentPassField={this.state.enableCurrentPassField}
                    setCurrentPass={this.setCurrentPassword}
                    currentPassError={this.state.currentPassError}
                    handleClickShowCurrentPass={this.handleClickShowCurrentPass}
                    newPassError={this.state.newPassError}
                    conNewPassError={this.state.conPassError}
                    handleChangePassModalClose={this.handleChangePassModalClose}
                    setNewPassword={this.handlePassword}
                    handleClickShowNewPass={this.handleClickShowNewPass}
                    enableNewPassField={this.state.enableNewPassField}
                    setConfNewPassword={this.setConfNewPassword}
                    handleClickShowConPass={this.handleClickShowConPass}
                    newPass={this.state.newPass}
                    enableConPassField={this.state.enableConPassField}
                    conNewPass={this.state.confirmPass}
                    handleConfirmSubmit={this.handleConfirmSubmit}
                    toLowerCase={this.state.toLowerCase}
                    toUpperCase={this.state.toUpperCase}
                    toNumber={this.state.toNumber}
                    toCharacter={this.state.toCharacter}
                    cancel={configJSON.cancel}
                    confirm={configJSON.confirm}
                    character={configJSON.character}
                    numbers={configJSON.numbers}
                    lowerLetter={configJSON.lowerLetter}
                    capitalLetter={configJSON.capitalLetter}
                    isIcon={this.isIcon}
                  />
                )}
                {this.state.continuePopup && <BlurBackground />}
                {this.state.continuePopup && (
                  <UserChangePasswordContinuePopup>
                    <BackHeaderDiv onClick={this.handleContinuePopup}>
                      <BackArrowButton />
                      <UserSettingHeaderText>
                        User Settings
                      </UserSettingHeaderText>
                      <PasswordHeaderText>Password</PasswordHeaderText>
                    </BackHeaderDiv>
                    <MiddelContainer>
                      <img
                        src={logoutModalEmailIcon}
                        alt="emailIcon"
                        width={35.89}
                        height={26.67}
                      />
                      <LogutParaContinue>
                        {configJSON.continuePassword}
                      </LogutParaContinue>
                    </MiddelContainer>
                    <LogoutButton
                      data-testid="continuePopupId"
                      variant="contained"
                      onClick={this.handleContinuePopup}
                    >
                      {configJSON.continue}
                    </LogoutButton>
                  </UserChangePasswordContinuePopup>
                )}
              </FormContainer>
            )}
          </MainContent>
          <LogoutModal
            open={this.state.openLogoutModal}
            BackdropComponent={Backdrop}
            closeAfterTransition
            BackdropProps={{
              timeout: 500
            }}
          >
            <LogoutModalParagraphContainer in={this.state.openLogoutModal}>
              <Box>
                <img
                  src={logoutModalEmailIcon}
                  alt="emailIcon"
                  width={35.89}
                  height={26.67}
                />
                <Box>
                  <LogutParaOne>{configJSON.logoutParaOne}</LogutParaOne>
                  <LogutParaOne>{configJSON.logoutParaTwo}</LogutParaOne>
                </Box>
                <LogoutButtonContainer>
                  <CancelButton
                    data-testid="logoutModalId"
                    variant="contained"
                    onClick={this.handleLogoutModal}
                  >
                    {configJSON.cancel}
                  </CancelButton>
                  <LogoutButton
                    data-testid="logoutId"
                    variant="contained"
                    onClick={this.handleLogoutProfile}
                  >
                    {configJSON.logout}
                  </LogoutButton>
                </LogoutButtonContainer>
              </Box>
            </LogoutModalParagraphContainer>
          </LogoutModal>
        </MainContainerAccount>
      </ContentWrapper>
      // Customizable Area End
    );
  }
}
