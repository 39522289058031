import React from "react";
import ForgotPasswordGenericController, {
  Props
} from "./ForgotPasswordGenericController.web";
import { ForgotPasswordWrapper } from "./ForgotPasswordWrapper";
import { imgConfirm } from "./assets";

export default class ResetConfirmation extends ForgotPasswordGenericController {
  constructor(props: Props) {
    super(props);
    this.goBackToLogin = this.goBackToLogin.bind(this);
  }

  render() {
    return (
      <ForgotPasswordWrapper
        title="Password reset"
        titleImage={imgConfirm}
        subtitile="Your password has been successfully reset. Click below to login. "
        titleImageAlt={"key"}
        onSubmitClick={this.goBackToLogin}
        onCancelClick={this.goBackToLogin}
        submitButtonText={"Continue"}
        cancelButtonText={"Back to login"}
        children={null}
        showCancelButton={true}
        labelContainerClasses="reset-confirmation"
      />
    );
  }
}
