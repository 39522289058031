import React, { useState, useEffect } from 'react';
import { IconButton, styled } from '@material-ui/core';

// src/global.d.ts
interface SpeechRecognition {
  continuous: boolean;
  interimResults: boolean;
  lang: string;
  start(): void;
  stop(): void;
  onresult: ((event: SpeechRecognitionEvent) => void) | null;
  onerror: ((event: SpeechRecognitionErrorEvent) => void) | null;
}

interface SpeechRecognitionAlternative {
  transcript: string;
  confidence: number;
}

interface SpeechRecognitionResult {
  isFinal: boolean;
  [index: number]: SpeechRecognitionAlternative;
  length: number;
}

interface SpeechRecognitionEvent extends Event {
  readonly resultIndex: number;
  readonly results: SpeechRecognitionResult[];
}

interface SpeechRecognitionErrorEvent extends Event {
  readonly error: string;
}
//  const audioImg = ;

const SpeechToText = ({handleGetText}:{handleGetText:Function}) => {
  const [listening, setListening] = useState(false);
  const [text, setText] = useState('');
  const [recognition, setRecognition] = useState<SpeechRecognition | null>(null);
  const VoiceIcon = styled("img")({
    width: "32px",
    height: "32px",
    pointerEvents:'none',
    "&::-webkit-user-select":'none',
    "@media(max-width: 650px)": {
      width: "24px",
      height: "24px",
      pointerEvents: 'none',
    },
  });
  useEffect(()=>{
    handleGetText(text);
  },[text])
  useEffect(() => {
    const SpeechRecognition = 
      (window as any).SpeechRecognition || 
      (window as any).webkitSpeechRecognition;

if (SpeechRecognition) {
  const recognitionInstance = new SpeechRecognition();
  recognitionInstance.continuous = true;
  recognitionInstance.interimResults = true;
  recognitionInstance.lang = 'en-US';

  recognitionInstance.onresult = (event: SpeechRecognitionEvent) => {
    let interimTranscript = '';
    let finalTranscript = '';
    for (let i = event.resultIndex; i < event.results.length; i++) {
      const transcript = event.results[i][0].transcript;
      if (event.results[i].isFinal) {
        finalTranscript += transcript;
      } else {
        interimTranscript += transcript;
      }
    }
    setText((prevText) => finalTranscript);
  };

  recognitionInstance.onerror = (event: SpeechRecognitionErrorEvent) => {
    console.error('Speech recognition error', event.error);
  };

  setRecognition(recognitionInstance);
} else {
  console.error('Speech recognition not supported in this browser.');
}
  }, []);

  const handleListen = () => {
    if (recognition) {
      if (listening) {
        recognition.stop();
      } else {
        recognition.start();
      }
      setListening(!listening);
    }
  };

  const handlePointerClick = () => {
    if (recognition) {
      if (listening) {
        recognition.stop();
      } else {
        recognition.start();
      }
      setListening(!listening);
    }
  }

  return (
      <div onMouseDown={handleListen} onPointerDown={handlePointerClick}>
        <IconButton>
          <VoiceIcon src={require('./audio.svg')}/>
        </IconButton>
      </div>
  );
};

export default SpeechToText;