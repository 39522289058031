import React from "react";

// Customizable Area Start
import {
  styled,
  Box,
  Typography,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import {searchIconSmall} from "./assets"

const surveyForms1 = [
  {
    id: 1,
    name: "Property Info",
  },
  {
    id: 2,
    name: "Building Information",
  },
  {
    id: 3,
    name: "Midrise or highrise floor info",
  },
  {
    id: 4,
    name: "MDF Info",
  },
  {
    id: 5,
    name: "IDF detail info",
  },
  {
    id: 6,
    name: "Unit Survey",
  },
  {
    id: 7,
    name: "Common Areas",
  },
  {
    id: 8,
    name: "OSP Survey",
  },
];


const MainDrawer1 = styled("div")({
  "@media (min-width: 1200px)": {
    flexShrink: 0,
  },
});




const SurveyFormsSection1 = styled(Box)({
  flexDirection: "column",
  border: "none",
  gap: "20px",
  display: "flex",
  padding: "15px",
  textAlign:"left",
  "@media (max-width: 900px)": {
    display: "none",
  },
});



const LeftSectionBox1 = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "13px",
  marginTop: "130px",
  padding: "20px 19px 19px 19px",
  border: "1px solid #34B9FC",
  borderRadius: "8px",
});

const LeftSectionBox1Text = styled(Typography)({
  color: "#181818",
  fontFamily: "Poppins",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  cursor: "pointer",
});

const LeftSectionBox1TextSelected = styled(Typography)({
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  color: "#4FC3FD",
  fontFamily: "Poppins",
  fontSize: "18px",
  
});

const LeftSectionSearchTextField1 = styled(TextField)({
  width: "100%",
  border: "1px solid #34B9FC",
  borderRadius: "8px",
  

  "& input::placeholder": {
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    fontFamily: "Poppins",
    fontSize: "18px",
  },

  "& .MuiOutlinedInput-input": {
    width: "100%",
    borderRadius: "8px",

    "@media (max-width: 340px)": {
      minWidth: "280px",
      width: "100%",
    },
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#DCE1E4",
    borderRadius: "8px",
  },

  "& .MuiSelect-outlined.MuiSelect-outlined": {
    textAlign: "left",
    color: "black !important",
  },

  '& input[type="number"]': {
    "-moz-appearance": "textfield",

    "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },

  "& .MuiFormHelperText-contained": {
    marginRight: "14px",
    marginLeft: "4px",
    
  },
});

const LeftBoxSearchIcon1 = styled("img")({
  cursor: "pointer",
  paddingTop: "8px",

});


interface BuildingInfoNavInterface{
  mobileOpen:boolean, 
  handleDrawerToggle:any, 
  selectedForm:number,
  openDrawer:boolean
}

// Customizable Area End



const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class BuildingInfoNav extends React.Component<any, BuildingInfoNavInterface> {
  constructor(props: BuildingInfoNavInterface) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    const {  selectedForm } = this.props;
    return (
      // Customizable Area Start
      // Required for all blocks

      <MainDrawer1>
        <SurveyFormsSection1>
          <LeftSectionBox1>
            {surveyForms1.map((elm) => {
              return (
                <>
                  {elm.id === selectedForm ? (
                    <LeftSectionBox1TextSelected key={elm.id}>
                      {elm.name}
                    </LeftSectionBox1TextSelected>
                  ) : (
                    <LeftSectionBox1Text key={elm.id}>
                      {elm.name}
                    </LeftSectionBox1Text>
                  )}
                 </>
              );
            })}
          </LeftSectionBox1>
          <LeftSectionSearchTextField1
            id="outlined-basic"
            data-test-id="searchBox"
            placeholder={"Jump to specific section"}
            variant="outlined"
            type="text"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LeftBoxSearchIcon1 src={searchIconSmall}/>
                </InputAdornment>
              ),
            }}
          />
        </SurveyFormsSection1>
      </MainDrawer1>

      // Customizable Area End
    );
  }
}
