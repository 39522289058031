import { Avatar, Box, IconButton, Typography, styled } from "@material-ui/core";
import React, { FC } from "react";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";

interface Props {
  imgSrc: string;
  onImageUpload: (event: any) => void;
}
const FallBackAvatar = styled(Box)({
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  borderRadius: "50%",
  fontSize: "72px",
  "& >p, >svg": {
    background:
      "linear-gradient(90deg, rgba(52,185,252,1) 0%, rgba(91,137,253,1) 50%, rgba(123,97,253,1) 100%)",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent"
  }
});
const Camera = styled(CameraAltOutlinedIcon)({
  color: "rgba(91,137,253,1)",
  width: "20px",
  height: "18px"
});

const InputWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  position: "relative",

  borderRadius: "50%",

  backgroundClip: "padding-box",
  border: "solid 2px transparent",
  "& .MuiIconButton-label": {
    borderRadius: '50%'
  },
  "&::before": {
    content: '""',
    position: "absolute",
    top: "0; right: 0; bottom: 0; left: 0",
    zIndex: -1,
    margin: "-2px",
    borderRadius: "inherit",
    background:
      "linear-gradient(90deg, rgba(52,185,252,1) 0%, rgba(91,137,253,1) 50%, rgba(123,97,253,1) 100%)"
  }
});

const InputHint = styled(Typography)({
  fontFamily: "Poppins",
  fontWeight: 400,
  fontSize: "12px"
});

export const PhotoInput: FC<Props> = ({ imgSrc, onImageUpload }) => {
  const imgUrl = localStorage.getItem("user-info")  
  const data = imgSrc?.length ? imgSrc : imgUrl;  
  return (
    <InputWrapper>
      <input
        style={{ display: "none" }}
        accept="image/*"
        id="contained-button-file"
        multiple
        type="file"
        onChange={onImageUpload}
        data-test-id="photoInput"
      />
      <label htmlFor="contained-button-file" style={{borderRadius: '50%'}}>
        <IconButton
          disableFocusRipple
          disableRipple
          disableTouchRipple
          component="span"
          style={webStyles.IconImageStyle}
        >
          <Avatar
            src={data!}
            style={{
              backgroundColor: "white",
              width: "155px",
              height: "155px"
            }}
          >
            <FallBackAvatar>
              <Camera />
              <InputHint>Add photo</InputHint>
            </FallBackAvatar>
          </Avatar>
        </IconButton>
      </label>
    </InputWrapper>
  );
};

const webStyles = {
  IconImageStyle: {
    padding: "1px",
    background: "linear-gradient(90deg, #34B9FC ,#7B61FD)"
  }
};
