import React from "react";
// Customizable Area Start
import {
  Button, Box, Typography, Modal, Fade, LinearProgress
} from "@material-ui/core";

import { styled } from "@material-ui/styles";
// import Spinner from "../../../components/src/Spinner.web";
import CustomFormController, {
  Dropdown,
  Props,
  configJSON
} from "./CustomFormController.web";
import { CustomFormWrapper } from "./CustomFormWrapper";
import { Survey } from "./Survey";
import { Close } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import { plusIcon, circlePlusIcon, circleOutline, highlightOff, deleteIcon,galleryImage, cameraImage, buildingInfoLogo, cameraAccess, deny } from "./assets";
import Back from "@material-ui/icons/ArrowBackIosRounded";
// Customizable Area End

export default class CustomForm extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  cameraModal = () => {
    return (
        <Modal
            open={this.state.isCameraModalOpen}>
            <AccessModalMainBox>
                <HeaderMainBox>
                    <Logo src={buildingInfoLogo} />
                </HeaderMainBox>
                <AccessModalContentMainInnerBox>
                    <ModalImageMainBox>
                        <ImageContainBox>
                            <ModalCameraImage src={cameraAccess} />
                        </ImageContainBox>
                        {!this.state.isGalleryAccessAllowed && <DenyImage src={deny} />}
                    </ModalImageMainBox>
                    <ModalTextMainBox>
                        <AllowAccessText>{this.state.isGalleryAccessAllowed ? configJSON.allowCameraAccessText : configJSON.noCameraAccess}</AllowAccessText>
                        <AllowAccessDetailText>{this.state.isGalleryAccessAllowed ? configJSON.cameraAccessDetailText : configJSON.cameraDeniedDetailText}</AllowAccessDetailText>
                    </ModalTextMainBox>
                    <ModalButtonMainBox >
                        <UploadAllowCameraButton
                            htmlFor="bulkUploadingFileInputBox"
                            data-test-id="uploadButtonTestId"
                            onClick={this.permissionGranted}>
                             {this.state.ispermissionGranted ? <FileInputBox data-testid="imageFileHandlerid" type="file" id="bulkUploadingFileInputBox" onChange={(e) => { this.imgFilehandlerSubsection(e) }} data-test-id="degreeid" multiple /> : <FileInputBox type="file" accept="image/*;capture=camera" />}
                            {configJSON.allowAcessText}
                        </UploadAllowCameraButton>
                        <CancelCameraReturnText
                            data-test-id="permissionDenyTestId"
                            onClick={!this.state.isGalleryAccessAllowed ? this.handleCloseBulkUploadModal : this.galleryAccessPermission}>
                            {this.state.isGalleryAccessAllowed ? configJSON.AccessDeniedText : configJSON.ReturnHomeText}
                        </CancelCameraReturnText>
                    </ModalButtonMainBox>
                </AccessModalContentMainInnerBox>
            </AccessModalMainBox>
        </Modal>
    )
};
galleryModal = () => {
  return (
      <Modal
          open={this.state.isPermissionModalOpen}>
          <AccessModalMainBox>
              <HeaderMainBox>
                  <Logo src={buildingInfoLogo} />
              </HeaderMainBox>
              <AccessModalContentMainInnerBox>
                  <ModalImageMainBox>
                      <ImageContainBox>
                          <ModalImage src={galleryImage} />
                      </ImageContainBox>
                      {!this.state.isGalleryAccessAllowed && <DenyImage src={deny} />}
                  </ModalImageMainBox>
                  <ModalTextMainBox>
                      <AllowAccessText>{this.state.isGalleryAccessAllowed ? configJSON.allowGalleryaccessText : configJSON.noGalleryAccess}</AllowAccessText>
                      <AllowAccessDetailText>{this.state.isGalleryAccessAllowed ? configJSON.allowAcessParaText : configJSON.galleryAccessDeniedDetail}</AllowAccessDetailText>
                  </ModalTextMainBox>
                  <ModalButtonMainBox >

                      <UploadAllowButton
                      htmlFor="bulkUploadingFileInputBox"
                          data-test-id="uploadButtonTestId"
                          onClick={this.permissionGranted}
                          >
                            {configJSON.allowAcessText}
                              
              {this.state.ispermissionGranted ? <FileInputBox data-testid="imageFileHandlerid" type="file" id="bulkUploadingFileInputBox" onChange={(e) => { this.imgFilehandlerSubsection(e) }} data-test-id="degreeid" multiple /> : <FileInputBox type="file" accept="image/*;capture=camera" />}
                      </UploadAllowButton>
                      <CancelReturnText
                          data-test-id="permissionDenyTestId"
                          onClick={!this.state.isGalleryAccessAllowed ? this.handleCloseBulkUploadModal : this.galleryAccessPermission}>
                          {this.state.isGalleryAccessAllowed ? configJSON.AccessDeniedText : configJSON.ReturnHomeText}
                      </CancelReturnText>
                  </ModalButtonMainBox>
              </AccessModalContentMainInnerBox>
          </AccessModalMainBox>
      </Modal>
  )
}
renderSelectMainBox = () => {
  return (
    <div style={{ width: '100%' }} data-test-id="galleryCameraBoxId">
      <UploadMobileParagraph style={{ marginLeft: '20px' }} onClick={this.goBack}>
        <BackButton />
        {configJSON.uploadNewPhotoVideoMobile}
      </UploadMobileParagraph>
      <DashedContainer>
        <GalleryBox
          data-test-id="galleryButtonId"
          onClick={this.handleGalleryClick}>
          <GalleryImage src={galleryImage} />
          <ImageTitleText>{configJSON.fromGalleryText}</ImageTitleText>
        </GalleryBox>
        <GalleryBox
          data-test-id="cameraButtonId"
          onClick={this.handleCameraClick}>
          <GalleryImage src={cameraImage} />
          <ImageTitleText>{configJSON.fromCameraText}</ImageTitleText>
        </GalleryBox>
      </DashedContainer>
    </div>

  )
}

renderMobileUploadedFiles =()=>{
  return (
    <MobileUploadFileContainer>
    
    {( Object.values(this.state.selectedImages).some((files: File[]) => files.length > 0) ||
  Object.values(this.state.selectedVideos).some((files: File[]) => files.length > 0)) &&
        <>
        {[...Object.values(this.state.selectedImages).flat(), ...Object.values(this.state.selectedVideos).flat()].map((file, index) => (
            <ProgressContainer key={index}>
            <ProgressFileName>
              <FileName>{configJSON.fileName} {file.name}</FileName>
              <FileSize>
                {(file.size / (1024 * 1024)).toFixed(2)} {configJSON.mbName}
              </FileSize>
            </ProgressFileName>
            {this.state.startDownloading && (
              <ProgressBarMain>
                {this.state.uploadSuccessfully ? (
                <ProgressReload>
                  <Box width="100%">
                    <SuccessLinearProgress variant="determinate" value={this.state.uploadProgress[index] || 0} />
                  </Box>  
                    <RemoveFile>
                    <img src={circleOutline} alt="Circle Outline" />
                    </RemoveFile>
                    </ProgressReload>
                  ) : (
                    <ProgressReload>
                    <Box width="100%">
                    <CustomLinearProgress variant="determinate" value={this.state.uploadProgress[index] || 0} />
                  </Box>
                    <RemoveFile onClick={() => this.removeFile(index)}>
                      <img src={highlightOff} alt="Remove" />
                    </RemoveFile>
                  </ProgressReload>
                )}
                <PercentageContainer>
                  <FileSize variant="body2" color="textSecondary">
                    {`${Math.floor(this.state.uploadProgress[index] || 0)}%`}
                  </FileSize>
                  <FileSize>{this.state.uploadSpeed[index]}</FileSize>
                </PercentageContainer>
              </ProgressBarMain>
            )}
          </ProgressContainer>
        ))}
        </>

    }
</MobileUploadFileContainer>
  )
}

  renderFileUploadLabelMobileBox = () => {
    return(
      <>
        <FileUploadLabelBox>
          {this.state.fileLimitExceeded ? (
            <CirclePlusIconButton>
              <PlusIcon src={circlePlusIcon} />
            </CirclePlusIconButton>
          ) : (
            <PlusIconButton>
              <PlusIcon src={plusIcon} />
            </PlusIconButton>
          )}
          <FileInputBox disabled={this.state.uploadingLoader} data-testid="imageFileHandlerid" type="file" id="bulkUploadingFileInputBox" onChange={(e) => { this.imgFilehandlerSubsection(e) }} data-test-id="degreeid" multiple />
        </FileUploadLabelBox>
        <FileUploadLabelMobileBox data-test-id="fileUploadBOxId" onClick={this.handleFileContainerClick} >
          {this.state.fileLimitExceeded ? (
            <CirclePlusIconButton>
              <PlusIcon src={circlePlusIcon} />
            </CirclePlusIconButton>
          ) : (
            <PlusIconButton>
              <PlusIcon src={plusIcon} />
            </PlusIconButton>
          )}
        </FileUploadLabelMobileBox>
      </>

    )
  }

  renderModalButtonContainer = () => {
    return (
      <ModalButtonContainer>
        <ModalCancelButton
          data-testid="uploadCancelId"
          variant="contained"
          disabled={this.state.uploadingLoader}
          onClick={this.handleCloseBulkUploadModal}
        >
          {configJSON.cancel}
        </ModalCancelButton>
        <ModalUploadButton
          disabled={this.state.uploadingLoader || (Object.keys(this.state.selectedImages).length == 0 &&
            Object.keys(this.state.selectedVideos).length === 0)}
          data-testid="uploadDocuments"
          variant="contained"
          onClick={() => this.handleUploadDocuments()}
        >
          {this.state.uploadingLoader ? <CircularLoader /> : configJSON.uploadDocuments}
        </ModalUploadButton>
      </ModalButtonContainer>
    )
  }
  renderImageUploadModal() {
    const { handleCloseBulkUploadModal, imgFilehandlerSubsection, removeFile, state, handleUploadDocuments } = this;
    const { openUploadModal,fileLimitExceeded, uploadSpeed, uploadingLoader, uploadProgress, startDownloading, uploadSuccessfully, selectedImages, selectedVideos, limitValidationError, limitVideoValidationError } = state;

        return (<>  <ModalBlurBackground />
        <ImageUploadModal
        data-test-id="imageUploadModal"
          open={openUploadModal}
          closeAfterTransition
          BackdropProps={{
            timeout: 500,
            invisible: true }}
        >
          <ImageUploadParagraphContainer in={this.state.openUploadModal}>
              <ImageUploadContainer data-test-id = "uploadPopUpId">
                {this.state.limitValidationError !== "" && (
                  <ErrorValidationBox>
                    <ErrorValidationAlert>
                      <ErrorText>{limitValidationError}</ErrorText>
                    </ErrorValidationAlert>
                  </ErrorValidationBox>
            )}
            {limitVideoValidationError !== "" && (
                <ErrorValidationBox>
                  <ErrorValidationAlert>
                    <ErrorText>{limitVideoValidationError}</ErrorText>
                    </ErrorValidationAlert>
                  </ErrorValidationBox>
            )}
                {this.state.isFileContainerOpen ?  <ImageVideoUpload>
                  <ImageWidth>
                    <TopHeader>
                      <UploadMobileParagraph onClick={handleCloseBulkUploadModal}>
                        <BackButton />
                      {configJSON.uploadNewPhotoVideoMobile}
                      </UploadMobileParagraph>
                      <UploadParagraph>
                        {configJSON.uploadNewPhotoVideo}
                      </UploadParagraph>
                      <CloseIcon  onClick={handleCloseBulkUploadModal}>
                        <Close/>
                      </CloseIcon>
                    </TopHeader>
                    <InnerModal>
                      <Label htmlFor="bulkUploadingFileInputBox">
                        {this.renderFileUploadLabelMobileBox()}
                      </Label>
                      {fileLimitExceeded ? (
                        <ExceedLimit>{configJSON.progressBarUploads}</ExceedLimit>
                      ) : (<SelectFilePara>{configJSON.selectFile}</SelectFilePara>
                      )}
                      {(
                    Object.values(selectedImages).some((files: File[]) => files.length > 0) ||
                    Object.values(selectedVideos).some((files: File[]) => files.length > 0)
                      ) && (
                          <MainProgressContainer>
                            <HrTopHeading></HrTopHeading>
                            <ProgressBarMainContainer>
                              {[...Object.values(selectedImages).flat(), ...Object.values(selectedVideos).flat()].map(
                                (file, index) => (
                                  <ProgressContainer key={index}>
                                    <ProgressFileName>
                                      <FileName>{configJSON.fileName} {file.name}</FileName>
                                      <FileSize>
                                        {(file.size / (1024 * 1024)).toFixed(2)} {configJSON.mbName}
                                      </FileSize>
                                    </ProgressFileName>
                                    {startDownloading && (
                                      <ProgressBarMain>
                                        {uploadSuccessfully ? (
                                        <ProgressReload>
                                          <Box width="70%">
                                            <SuccessLinearProgress variant="determinate"  value={uploadProgress[index] || 0} style={{ width: '100%' }} />
                                          </Box>  
                                            <RemoveFile>
                                            <img src={circleOutline} alt="Circle Outline" />
                                            <SuccessMessage>{configJSON.successMessage}</SuccessMessage>
                                            </RemoveFile>
                                            </ProgressReload>
                                          ) : (
                                            <ProgressReload>
                                            <Box width="70%">
                                            <CustomLinearProgress variant="determinate"  value={uploadProgress[index] || 0} style={{ width: '100%' }} />
                                          </Box>
                                            <RemoveFile data-testid="removeFileId" onClick={() => removeFile(index)}>
                                              <img src={highlightOff} alt="Remove" />
                                            </RemoveFile>
                                          </ProgressReload>
                                        )}
                                        <PercentageContainer>
                                          <FileSize variant="body2" color="textSecondary">
                                            {Math.floor(uploadProgress[index] || 0)}%
                                          </FileSize>
                                          <FileSize>{uploadSpeed[index]}</FileSize>
                                        </PercentageContainer>
                                      </ProgressBarMain>
                                    )}
                                  </ProgressContainer>
                                )
                              )}
                            </ProgressBarMainContainer>
                          </MainProgressContainer>
                        )}
                    </InnerModal>
                    <>
                    {this.renderMobileUploadedFiles()}
                    </>
                    {this.renderModalButtonContainer()}
                  </ImageWidth>
                </ImageVideoUpload> : 
                <>
                    {this.renderSelectMainBox()}
                    {this.cameraModal()}
                    {this.galleryModal()}
                </>
                }


              </ImageUploadContainer> 
          </ImageUploadParagraphContainer>
          
         </ImageUploadModal>
        </>)
        }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { openAccordionsNew, deleteUploadedFile, handleDeleteFilePopup, handleCloseDeleteFilePopup, headingRefs, handleBulkUploadModal, handleDeleteSubsectionPopup, resetSelectedGlobalSearchOption, togleCopyLastFilledData, openAccordionsOpen, handleFieldChange, handleAccordionToggle, addNewSection, handleCloseDeleteSubsecPopup, handleDeleteElementAccordion, linksSectionsSubsections, openAccordions, handleGlobalSearch, handleSectionSearchBar, customFormLinks,handlePopUpClose, hasNextSection, handleEror, onLickClick, handleScrollToHeading, handleNextButtonClick,handleBlurFields, handleDescriptionChange, handleBackButtonClick, handleSurveySubmit, props, state,} = this;
    const { deleteSubSecPopup, currentFields, fileFormat, isMandatoryError, photosUpdated, deleteFileConfirmationPopup, copyLastFilledData, isUploadingTrue, selectedGlobalSearchOption, fieldValues, subSecName, currentSection, showPopUp, searchSpecificSec, allFields, listOfSectionsSubsections, sectionSubSectionList, navigationSearchSections, specificSecSearchesData, error, saveBtnResponse, openUploadModal, dynamicOptions } = state;
    const { navigation } = props;
    const surveyName = currentSection?.survey_info_section_name || "";
        return (
      <>
      <CustomFormWrapper
        data-testid="customId"
        navigation={navigation}
        handleError={handleEror}
        surveyName={surveyName}
        onLinkClick={onLickClick}
        handleScrollToHeading={handleScrollToHeading}
        onNextBtnClick={handleNextButtonClick}
        onBackBtnClick={handleBackButtonClick}
        onSaveBtnClick={handleSurveySubmit}
        onPopUpClose={handlePopUpClose}
        showPopUp={showPopUp}
        onSubmit={handleSurveySubmit}
        hasNextSection={hasNextSection}
        links={customFormLinks}
        error={error}
        saveBtnResponse={saveBtnResponse}
        searchSpecificSec={searchSpecificSec}
        specificSecSearchesData={specificSecSearchesData}
        handleSectionSearchBar={handleSectionSearchBar}
        sectionSubSectionList={sectionSubSectionList}
        handleGlobalSearch={handleGlobalSearch}
        globalSearchOptions={allFields}
        navigationSearchSections={navigationSearchSections}
        listOfSectionsSubsections={listOfSectionsSubsections}
        linksSectionsSubsections={linksSectionsSubsections}
        name={surveyName}
        openAccordions={openAccordions}
        deleteSubSecPopup={deleteSubSecPopup}
        handleCloseDeleteSubsecPopup={handleCloseDeleteSubsecPopup}
        subSecName={subSecName}
        handleDeleteElementAccordion={handleDeleteElementAccordion}
      >
        <Survey
                data-testid="surveyId"
                fields={currentFields}
                name={surveyName}
                openAccordions={openAccordions}
                handleAccordionToggle={handleAccordionToggle}
                handleAddBuilding={addNewSection}
                openAccordionsOpen={openAccordionsOpen}
                handleFieldChange={handleFieldChange}
                add_similar_section={currentSection?.add_similar_section}
                add_similar_section_text={currentSection?.add_similar_section_text}
                copy_from_last_filled={currentSection?.copy_from_last_filled}
                fieldValues={fieldValues[surveyName]}
                isMandatoryError={isMandatoryError[surveyName]}
                fieldToScrollName={selectedGlobalSearchOption}
                resetFieldToScroll={resetSelectedGlobalSearchOption}
                handleCopyDataPreviousField={togleCopyLastFilledData}
                copyFromLastFilledChecked={copyLastFilledData}
                handleDeleteSubsectionPopup={handleDeleteSubsectionPopup}
                openAccordionsNew={openAccordionsNew}
                openBulkUploadImages={handleBulkUploadModal}
                isUploadingTrue={isUploadingTrue}
                uploadPictures={photosUpdated}
                videoNotSupported={configJSON.videoNotSupported}
                deleteIcon={deleteIcon}
                deleteImageButton={configJSON.deleteImage}
                handleDeleteFiles={deleteUploadedFile}
                deleteFileConfirmationPopup={deleteFileConfirmationPopup}
                handleCloseDeleteFilePopup={handleCloseDeleteFilePopup}
                handleOpenDeletePopup={handleDeleteFilePopup}
                fileFormat={fileFormat}
                subsectionName={subSecName}
                handleScrollToHeading={handleScrollToHeading}
                headingRefs={headingRefs} 
                handleDescriptionChange={handleDescriptionChange}
                handleBlurFields={handleBlurFields}
                dynamicOptions={dynamicOptions}
                />
      </CustomFormWrapper>
      {openUploadModal && this.renderImageUploadModal()}
      </>
      // Customizable Area End
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const CircularLoader = styled(CircularProgress)({
 height: '25px !important',
 width: '25px !important',
 color: 'white'
})
const userTypeList: Dropdown[] = [
  { label: "Tenant", value: "Tenant" },
  { label: "Owner", value: "Owner" },
  { label: "Supervisor", value: "Supervisor" }
];

const ratingList: Dropdown[] = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" }
];

const genderList: Dropdown[] = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" }
];

const SubmitButton = styled(Button)({
  backgroundColor: "rgb(98, 0, 238)",
  color: "white",
  borderRadius: "10px",
  textTransform: "none",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "18px",
  padding: "6px 31px",
  "&:hover": {
    color: "white",
    backgroundColor: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 27px",
  },
});

const CancelButton = styled(Button)({
  backgroundColor: "white",
  padding: "6px 17px",
  border: "1px solid rgb(98, 0, 238)",
  color: "rgb(98, 0, 238)",
  fontSize: "18px",
  borderRadius: "8px",
  marginRight: "15px",
  textTransform: "none",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  "&:hover": {
    backgroundColor: "white",
    color: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 13px",
  },
});

const webStyle = {
  tableData: {
    fontSize: "16px",
    color: "#000",
  },
  statusColumn: {
    display: "flex",
    justifyContent: "center",
    color: "#000",
    alignItems: "center",
    fontSize: "16px",
  },
  noRecord: {
    fontSize: "18px",
    color: "#000",
  },
  mainTitle: {
    marginBottom: "5px",
    color: "#1b3c69",
    fontSize: "30px"
  },
  mainWrapper: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  pagination: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "end"
  },
  buttonStyle: {
    fontSize: "18px",
    borderRadius: "8px",
    padding: "6px 17px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    color: "white",
    cursor: "pointer"
  },
};

const ProgressContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  marginTop: "30px",
  "@media (max-width: 550px)":{
    border:'1px solid #DCE1E4',
    padding:'10px 20px'
  }
});

const ProgressFileName = styled(Box)({
  display: "flex",
  gap: "30px",
  alignItems:'center'
});

const ProgressBarMain = styled(Box)({
  width: "100%"
});

const ProgressReload = styled(Box)({
  display: "flex",
  gap: "20px",
  alignItems: "center"
});

const SelectFilePara = styled(Typography)({
  background: "linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 100%)",
  backgroundClip: "text",
  webkitBackgroundClip: "text",
  color: "transparent",
  display: "inline",
  fontSize: "16px",
  fontWeight: 500
});

const FileUploadLabelBox = styled(Box)({
  borderRadius: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  boxSizing: "border-box",
  "@media (max-width: 550px)":{
    display:'none'
  }
});

const FileUploadLabelMobileBox = styled(Box)({
  display:'none',
  "@media (max-width: 550px)":{
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    boxSizing: "border-box",
  }
})

const PlusIconButton = styled(Box)({
  borderRadius: "50px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const CirclePlusIconButton = styled(Box)({
  justifyContent: "center",
  borderRadius: "50px",
  alignItems: "center",
  display: "flex",
  marginTop: "10px"
});


const FileInputBox = styled("input")({
  display: "none",
});

const ImageWidth = styled(Box)({
  width: "80%",
  "@media(max-width: 550px)":{
    width: "90%",
    backgroundColor:'white'
  }
});

const ImageVideoUpload = styled(Box)({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column"
});

const ImageUploadContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%"
});

const Label = styled("label")({
  width: "100%",
  display: "flex",
  justifyContent: "center"
});

const InnerModal = styled(Box)({
  display: "flex",
  padding: "18px",
  justifyContent: "center",
  alignItems: "center",
  height: "230px",
  flexDirection: "column",
  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='1' stroke='%23332' stroke-width='1' stroke-dasharray='56%2c20' stroke-dashoffset='4' stroke-linecap='round'/%3e%3c/svg%3e")`,
  borderRadius: "1px",
  "@media (max-width: 550px)":{
    height:'127px'
  }
});

const PlusIcon = styled("img")({
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  cursor: 'pointer',
  height: "50px",
  width: "50px"
});

const ImageUploadModal = styled(Modal)({
  zIndex: 6,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  overflowY: "auto",
  "& .MuiBackdrop-root": {
    backgroundColor: "red"
  },
  "@media(max-width: 900px)":{
    padding:'0'
  },
  "@media(max-width: 550px)":{
    overflowY: 'hidden',
  }

});

const ImageUploadParagraphContainer = styled(Fade)({
  backgroundColor: "white",
  width: "80%",
  height: "440px",
  padding: "0px 0px 20px 0px",
  position: "fixed",
  top: "133",
  borderRadius: "8px",
  display: "flex",
  flexDirection: "column",
  gap: "25px",
  "@media(max-width: 767px)": {
    width: "80%",
    position: "initial"
  },
  
  "@media(max-width: 600px)": {
    gap: "15px",
  },
  "@media(max-width: 550px)":{
    zIndex:4,
    boxShadow: "rgba(17, 17, 26, 0.3) -1px -5px 5px -2px",
    width: "100%",
    height:'100%',
    marginTop:'260px',
    },
  "@media(max-width: 435px)":{
    width: "100%",
    height:'100%',
    marginTop:'165px',  
}});

const UploadParagraph = styled(Typography)({
  textAlign: "start",
  fontSize: "20px",
  fontWeight: 600,
  fontFamily: "Poppins",
  "@media(max-width: 992px)": {
    fontSize: "22px"
  },
  "@media(max-width: 550px)": {
    display: 'none'
  },
});

const UploadMobileParagraph = styled(Box)({
  display:'none',
"@media(max-width: 550px)":{
  margin:'15px 0 40px 0',
  display:"flex",
  textAlign: "start",
  fontSize: "18px",
  fontWeight: 500,
  fontFamily: "Poppins",
  alignItems:'center',
  cursor:'pointer'
}
})

const ModalButtonContainer = styled(Box)({
  display: "flex",
  gap: "40px",
  margin: "40px 0px 45px 0px",
  justifyContent: "flex-end",

  "@media(max-width:550px)": {
    flexDirection: "column-reverse",
    alignItems: "center",
    gap:'20px'
  }
});

const ModalCancelButton = styled(Button)({
  backgroundColor: "#EBF5FE",
  fontFamily: "Poppins",
  color: "#0090CF",
  fontWeight: 500,
  borderRadius: "8px",
  textTransform: "capitalize",
  height: "44px",
  fontSize: "16px",
  width: "235px",
  padding: "10px 16px 10px 16px",
  lineHeight: "24px",
  "&:hover": {
    backgroundColor: "#EBF5FE",
    color: "#0090CF"
  },
  "@media(max-width: 992px)": {
    fontSize: "16px"
  },
  "@media(max-width: 550px)": {
    fontSize: "16px",
    width:'100%'
  }
});

const ModalUploadButton = styled(Button)({
  background: "linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 100%)",
  color: "#FFF",
  borderRadius: "8px",
  height: "44px",
  width: "235px",
  fontSize: "16px",
  fontWeight: 500,
  padding: "10px 16px 10px 16px",
  lineHeight: "24px",
  fontFamily: "Poppins",
  textTransform: "capitalize",
  "&.Mui-disabled": {
    cursor: "not-allowed",
    color: "lightgrey",
  },
  "&:hover": {
    backgroundColor: "linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 100%)",
    color: "#FFF"
  },
  "@media(max-width: 992px)": {
    fontSize: "16px"
  },
  "@media(max-width: 550px)": {
    fontSize: "16px",
    width:'100%'
  }
});

const CloseIcon = styled(Box)({
  marginRight: "-95px",
  cursor: "pointer",
  padding: "25px 9px 20px 0px",
  "@media(max-width:1440px)": {
    marginRight: "-80px"
  },
  "@media(max-width:1280px)": {
    marginRight: "-72px"
  },
  "@media(max-width:992px)": {
    marginRight: "-50px"
  },
  "@media(max-width:550px)": {
    display:'none'
  }
});

const TopHeader = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
});

const ErrorValidationBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  width: "100%",
  justifyContent: "center",
  gap: "68px",
  marginBottom: "-40px",
  "@media (max-width: 900px)": {
    justifyContent: "flex-start",
    zIndex:50
  }
});

const ErrorValidationAlert = styled(Box)({
  minWidth: "30%",
  borderRadius: "4px",
  backgroundColor: "#FEE2E2",
  borderTop: "4px solid #DC2626",
  padding: "12px 10px",
  "@media (max-width: 960px)": {
    minWidth: "77%"
  },
  "@media (max-width: 500px)": {
    minWidth: "75%"
  },
  "@media (max-width: 375px)": {
    minWidth: "50%"
  }
});

const ErrorText = styled(Typography)({
  color: "#DC2626",
  fontWeight: 400,
  fontSize: "12px !important",
  fontStyle: "normal",
  fontFamily: "Poppins",
  lineHeight: "18px"
});

const ProgressBarMainContainer = styled(Box)({
  padding: "0px 60px 0px 0px",
  overflowY: "auto",
  height: "130px",
  width: "100%",
  "@media(max-width:767px)": {
    padding: "0px 35px 0px 0px"
  }
});

const MainProgressContainer = styled(Box)({
  width: "86%",
  marginTop: "20px",
  "@media(max-width: 550px)":{
    display:'none'
  }
});

const HrTopHeading = styled(Box)({
  width: "96%",
  border: "1px solid #C0C8CD",
  marginBottom: "20px"
});

const CustomLinearProgress = styled(LinearProgress)({
  height: "8px",
  borderRadius: "8px",
  "& .MuiLinearProgress-barColorPrimary": {
    backgroundColor: "#4FC3FD"
  }
});

const SuccessLinearProgress = styled(LinearProgress)({
  borderRadius: "8px",
  "& .MuiLinearProgress-barColorPrimary": {
    backgroundColor: "#34D399"
  }, 
  height: "8px"
});

const RemoveFile = styled(Box)({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  gap: "10px"
});

const ExceedLimit = styled(Typography)({
  color: "#C0C8CD",
  fontSize: "10.629px",
  fontWeight: 500,
  textAlign: "center",
  fontFamily: "Poppins"
});

const SuccessMessage = styled(Typography)({
  color: "#34D399",
  fontSize: "12px",
  fontWeight: 500,
  fontFamily: "Poppins"
});

const FileName = styled(Typography)({
  fontSize: "16px",
  fontWeight: 400,
  fontFamily: "Poppins",
  color: "#58646C",
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: "80%",
  "@media (max-width: 550px)":{
    maxWidth: "77%",
  }
});

const FileSize = styled(Typography)({
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: 400,
  color: "#58646C"
});

const PercentageContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "70%"
});

export const ModalBlurBackground = styled(Box)({
  position: "fixed",
  top: 77,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(127, 136, 149, 0.47)",
  backdropFilter: "blur(5px)",
  "-webkit-backdrop-filter": "blur(5px)",
  zIndex: 9998,
  "@media(max-width:550px)": {
    background: "none"
  },
});
const MobileUploadFileContainer = styled('div')({
  display:'none',
  "@media (max-width: 550px)":{
    display: 'block',
    minHeight: '250px',
    height:'250px',
    width: '100%',
    boxSizing: 'border-box',
    overflow:'auto'
  },
  
})
const BackButton = styled(Back)({
  display: "none",
  "@media(max-width: 550px)": {
    display:"block",
    width: "20px",
    height: "20px",
    marginRight: "10px",
  }
});
const AccessModalMainBox = styled(Box)({
  height: "100vh",
  background: "white"
});

const HeaderMainBox = styled(Box)({
  height: "48px",
  marginBottom: "29px",
  display: "flex",
  justifyContent: "Center",
  alignItems: "center",
  background: "var(--Scio-Services-Basic-White, #FFF)",
});

const Logo = styled("img")({
  height: "36px",
  width: "71px",
});


const AccessModalContentMainInnerBox = styled(Box)({
  height: "calc(100% - 77px)",
  background: "#fff",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around"
});

const ModalImageMainBox = styled(Box)({
  height: "252px",
  width: "213px",
  background: "linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 100%)",
  alignSelf: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  borderRadius: "4px"
});

const ImageContainBox = styled(Box)({
  background: "#fff",
  height: "99%",
  width: "99%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "4px",
  position: "absolute",
});

const DenyImage = styled("img")({
  margin: "40px",
  background: "red",
  position: "absolute",
  top: -10,
  left: -70,
});
const ModalCameraImage = styled("img")({
  height: "104px",
  width: "104px"
});

const ModalButtonMainBox = styled(Box)({
  display: "flex",
  height: "100px",
  flexDirection: "column",
  margin: "0px 24px 0px 24px"
});

const ModalTextMainBox = styled(Box)({
  display: "flex",
  height: "100px",
  flexDirection: "column",
  margin: "0px 24px 0px 24px"
});

const AllowAccessText = styled(Typography)({
  color: " var(--Scio-Services-Basic-Black, #181818)",
  fontFamily: "Poppins",
  fontSize: "25px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  textAlign: "center"
});
const AllowAccessDetailText = styled(Typography)({
  color: "var(--Scio-Services-Neutral-400---Neutral, #8B989F)",
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  width: "335px",
  margin: "auto"
});


const UploadAllowCameraButton = styled('label')({
  background: "linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 100%)",
  height: "44px",
  fontStyle: "normal",
  borderRadius: "8px",
  fontWeight: 500,
  lineHeight: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  fontFamily: "Poppins",
  fontSize: "16px",
  padding: "0px",
  cursor:'pointer'
});

const CancelCameraReturnText = styled(Box)({
  height: "44px",
  display: "flex",
  color: "#4FC3FD",
  fontStyle: "normal",
  lineHeight: "24px",
  padding: "0px",
  borderRadius: "8px",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: 500,
  alignItems: "flex-end",
  justifyContent: "center",
  cursor:'pointer'
});
const ModalImage = styled("img")({
  height: "134px",
  width: "134px"
});

const DashedContainer = styled(Box)({
  height: "441px",
  margin: "10px 20px 0px 20px",
  backgroundImage: "linear-gradient(to right, black 33%, rgba(255,255,255,0) 0%)",
  backgroundPosition: "bottom",
  backgroundSize: "3px 1px",
  backgroundRepeat: "repeat-x",
});

const GalleryBox = styled(Box)({
  height: "50%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  border: "1px dashed #C0C8CD",
  cursor:'pointer'
});

const GalleryImage = styled("img")({
  height: "90px",
  width: "90px",
  marginBottom: "10px"
});

const ImageTitleText = styled(Typography)({
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "24px",
  background: "var(--Scio-Services-Gradient, linear-gradient(90deg, #34B9FC, #7B61FD 100%))",
  backgroundClip: "text"
});

const UploadAllowButton = styled('label')({
  background: "linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 100%)",
  height: "44px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  fontStyle: "normal",
  borderRadius: "8px",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "24px",
  padding: "0px",
  cursor:'pointer'
});
const CancelReturnText = styled(Typography)({
  height: "44px",
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
  color: "#4FC3FD",
  fontStyle: "normal",
  borderRadius: "8px",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "24px",
  padding: "0px",
  cursor:'pointer'
});
// Customizable Area End
