export const settingIcon = require("../assets/image_iconsettings.svg");
export const phoneIcon = require("../assets/phone.svg");
export const emailIcon = require("../assets/email.svg");
export const logoutIcon = require("../assets/logout.svg");
export const passwordIcon = require("../assets/password.svg");
export const companyIcon = require("../assets/company.svg");
export const nameIcon = require("../assets/name.svg");
export const logoutModalEmailIcon = require("../assets/email.png");
export const deleteModalIcon = require("../assets/deleteIcon.png");
export const logo = require("../assets/logo.png");
export const login = require("../assets/login.svg");
export const rightIcon = require("../assets/rightIcon.png");
export const deleteImg = require("../assets/delete.svg");
export const deletePhotoImg = require("../assets/delete_photo.svg");
export const logoutMainImg = require("../assets/logoutMain.svg");
export const editImg = require("../assets/edit.svg");
export const unchecked = require("../assets/unchecked.svg");
export const checked = require("../assets/checkedIcon.svg");
export const crossIcon = require("../assets/crossIcon.svg");
