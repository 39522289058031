import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import ForgotPasswordGenericController, {
  Props,
} from "./ForgotPasswordGenericController.web";
import { sendForgotPasswordRequest } from "./utils";

export const configJSON = require("./config");

interface S {
  resendTime: number;
  error: string;
  isFetching: boolean;
}

const RESEND_MESSAGE_TIME_SECONDS = 120;

interface S {
  resendTime: number;
  error: string;
}

export default class CheckEmailController extends ForgotPasswordGenericController<
  S
> {
  private timer: ReturnType<typeof setInterval> = 0 as any;
  messageId = "";
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      resendTime: RESEND_MESSAGE_TIME_SECONDS,
      error: "",
      isFetching: false,
    };

    const recoveryEmail = localStorage.getItem("recoveryEmail");
    if (!recoveryEmail) {
      this.goBackToLogin();
    }
  }

  receive(from: string, message: Message): void {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (response && response.message) {
        this.setState({
          error: "",
          // resendTime: RESEND_MESSAGE_TIME_SECONDS,
          isFetching: false,
        });
        return;
      }

      if (response && response.errors) {
        const error = response.errors[0];
        const msg = error.email;
        this.setState({
          error: msg,
          resendTime: RESEND_MESSAGE_TIME_SECONDS,
          isFetching: false,
        });
        return;
      }

      this.setState({
        error: "An error has occured",
        resendTime: RESEND_MESSAGE_TIME_SECONDS,
        isFetching: false,
      });
    }
  }
  async componentDidMount() {
    this.timer = setInterval(() => {
      const { resendTime } = this.state;
      if (resendTime) {
        this.setState({
          resendTime: resendTime - 1,
        });
      }
    }, 1000);
  }

  componentDidUpdate(_: Readonly<Props>, prevState: Readonly<S>): void {
    if (!this.state.resendTime && this.state.error) {
      this.setState({
        error: "",
      });
    }
  }
  async componentWillUnmount() {
    clearInterval(this.timer);
  }

  get resendTime() {
    return new Date(this.state.resendTime * 1000)
      .toISOString()
      .substring(14, 19);
  }

  resend() {
    this.sendRequest();
  }

  private sendRequest() {
    this.setState({
      isFetching: true,
      resendTime: RESEND_MESSAGE_TIME_SECONDS,
    });
    sendForgotPasswordRequest(
      localStorage.getItem("recoveryEmail") as string,
      (id: string) => (this.messageId = id)
    );
  }
}
