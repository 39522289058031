import React from "react";
import {
  Box,
  Button,
  Typography,
  styled,
  Dialog,
  Modal
} from "@material-ui/core";
import {closeModalIcon,imageSecurity} from "./assets";
import { Logo } from "./Logo";

const CustomDialog = styled(Dialog)({
  backgroundColor: "rgba(255, 255, 255, 0.4)",
  '-webkit-backdrop-filter': 'blur(5px)',
  'backdrop-filter': 'blur(5px)',
  "& .MuiDialog-paperFullWidth": {
    backgroundColor: "#FFF",
    padding: "21px 47px 60px 21px",
    borderRadius: "8px",
    maxWidth:"814px",
    width: '100%',
   maxHeight:"809px",
   height:"100%",
    boxSizing:"border-box",
    "@media (max-width: 600px)": {
      maxHeight:"unset",
      height:"unset",
      padding:"20px"
      },
  },
    
});

const CloseDiv = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
});

const CustomCloseIcon = styled("img")({
  cursor:"pointer",
});

const MiddleUpperBox = styled(Box)({
  display:"flex",
  justifyContent:"center"
})

const MiddleBox = styled(Box)({
  display:"flex",
  flexDirection:"column",
  justifyContent:"space-between",
  maxWidth:"536px",
  width:"100%"
})

const FirstBox = styled(Box)({
  display:"flex",
  flexDirection:"column",
  gap:"30px",
})
const OverlayBox = styled(Box)({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  background: 'gray',
  opacity: 0.5,
  filter: 'blur(4px)',
})

const ImageSecurityIcon = styled("img")({
margin:"auto",
width:"40px",
height:"40px",
"@media (max-width: 550px)": {
  marginTop:"60px",
  margin:"0px",
  width:"40px",
  height:"40px",
  alignSelf:"center"
},
"@media (max-width: 350px)": {
  marginTop:"30px",
  width:"30px",
  height:"30px",
}
})

const HeadingText = styled(Typography)({
color:"#181818",
textAlign:"center",
fontFamily:"Poppins",
fontSize:"30px",
fontStyle:"normal",
fontWeight:600,
lineHeight:"normal",
"@media (max-width: 550px)": {
  lineHeight:"45px",
  width:"270px",
  alignSelf:"center",
  marginTop:"20px",
  marginBottom:"40px"
},
"@media (max-width: 380px)": {
  marginBottom:"20px"
},
"@media (max-width: 350px)": {
  lineHeight:"30px",
  fontSize:"22px",
  width:"180px",
  marginBottom:"30px"
}
})

const InformationText = styled(Typography)({
color:"#A6B1B7",
textAlign:"center",
fontFamily:"Poppins",
fontSize:"18px",
fontStyle:"normal",
fontWeight:500,
lineHeight:"normal",
marginTop:"10px",
maxWidth:"536px",
"@media (max-width: 600px)": {
width:"100%",
maxWidth:"unset"
  },
"@media (max-width: 550px)": {
marginLeft:"20px",
marginRight:"20px",
width:"90%",
lineHeight:"27px"
},
"@media (max-width: 380px)": {
  marginTop:"0px",
},
"@media (max-width: 350px)": {
  fontSize:"16px",
  lineHeight:"20px",
  width:"75%",
  margin:"auto"
}
})

const ButtonBox = styled(Box)({
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
  "@media (max-width: 550px)": {
    margin:"0px 20px 40px 20px",
    alignItems:"flex-end",
  },
  "@media (max-width: 350px)": {
    margin:"0px 20px 30px 20px",
    alignItems:"flex-end",
  }
})

const CustomButton = styled(Button)({
maxHeight:"44px",
maxWidth:"536px",
width:"100%",
padding:"10px 16px",
borderRadius:"8px",
backgroundImage:"linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 100%)",
color:"#FFF",
fontFamily:"Poppins",
fontSize:"16px",
fontStyle:"normal",
fontWeight:500,
lineHeight:"24px",
textTransform:"none",
marginTop:"350px",
"@media (max-width: 600px)": {
  marginTop: "50px",
 justifyContent:"center"

  },
  "@media (max-width: 550px)": {
    marginTop:"0px",
    marginBottom:"0px"
  }
});

const LogoImageBox = styled(Box)({
  "@media (max-width: 550px)":{
    display:"flex",
    justifyContent:"center",
    height:"48px",
    alignItems:"center",
    boxShadow:"0px 0px 10px 0px lightgrey"
  }
});

const ModalInnerMainBoxSection = styled(Box)({
  height:"100%",
  display:"flex",
  flexDirection:"column",
  justifyContent:"space-between"
});

const ModalInnerSectionOne = styled(Box)({
  display:"flex",
  flexDirection:"column"
});

const ModalMobileInnerMainBox = styled(Box)({
  height:"100vh",
  width:"100vw",
  background:"#fff",
  display:"flex",
  flexDirection:"column"
});

interface PassSecModalProps{
  openSecModal:boolean
  handleSecurityModalClose:any
  handleOpenChangePassModal:any,
  size:number;
}


export default class PasswordSecurityModal extends React.Component<PassSecModalProps,any> {

 

 
  render() {
    const{ openSecModal,handleSecurityModalClose,handleOpenChangePassModal,} = this.props;
    const{ size} = this.props;
    return (
      <>
     {openSecModal ? <OverlayBox></OverlayBox>:null} 
      {size> 550 ?
      <CustomDialog
          open={openSecModal}
          fullWidth
          maxWidth="sm"
        >
          <CloseDiv>
            <CustomCloseIcon src={closeModalIcon} onClick={handleSecurityModalClose} />
          </CloseDiv>
          <MiddleUpperBox>
          <MiddleBox>
          <FirstBox>
            <Box style={{maxWidth:"1200px",margin:"auto"}}>
          <ImageSecurityIcon src={imageSecurity}/>
          </Box>
          <HeadingText>
          Keep your account secure
          </HeadingText>
          <InformationText>
          In our ongoing effort to protect your data and ensure the confidentiality of your information, we have implemented additional security measures that require a mandatory password change.
          </InformationText>
          </FirstBox>
          <ButtonBox>
          <CustomButton onClick={handleOpenChangePassModal}>Continue</CustomButton>
          </ButtonBox>
          </MiddleBox>
          </MiddleUpperBox>

        </CustomDialog>
        :
        <Box>
          <Modal 
           open={openSecModal}
           onClose={handleSecurityModalClose}
           >
            <ModalMobileInnerMainBox>
                <LogoImageBox>
                  <Logo />
                </LogoImageBox>
                <ModalInnerMainBoxSection>
                  <ModalInnerSectionOne>
                <ImageSecurityIcon src={imageSecurity}/>
                <HeadingText>
                  Keep your account secure
                </HeadingText>
                <InformationText>
                  In our ongoing effort to protect your data and ensure the confidentiality of your information, we have implemented additional security measures that require a mandatory password change.
                </InformationText>
                </ModalInnerSectionOne>
                <ButtonBox>
                  <CustomButton onClick={handleOpenChangePassModal}>Continue</CustomButton>
                </ButtonBox>
                </ModalInnerMainBoxSection>
              </ModalMobileInnerMainBox>
            </Modal>
          </Box>
      }
        </>
    );
  }
}
