import React, { FC } from "react";
// Customizable Area Start
import { SectionTitle } from "./PhotoForm.web";
import { Box, Typography, styled } from "@material-ui/core";
import { deleteImg, logoutMainImg } from "./assets";
// Customizable Area End

// Customizable Area Start
interface Props {
  onDeleteAccountClick: () => void;
  onLogoutClick: () => void;
}
// Customizable Area End

const AccountActiontext = styled(Typography)({
  fontFamily: "Poppins, Inter, sans-serif",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "24px",
});

export const AccountActionSection: FC<Props> = ({
  onDeleteAccountClick,
  onLogoutClick,
}) => {
  return (
    // Customizable Area Start
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      marginTop={"60px"}
      alignItems={"center"}
      // width={"60%"}
      maxWidth={"550px"}
    >
      <SectionTitle>Account actions</SectionTitle>
      <Box
        maxWidth={"650px"}
        display={"flex"}
        flexDirection={"raw"}
        style={{ gap: "50px" }}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          color={"red"}
          style={{ cursor: "pointer", gap: "10px" }}
        >
          <img src={deleteImg} alt="delete" height={"13.5px"} width={"12px"} />
          <AccountActiontext onClick={onDeleteAccountClick}>
            Delete Account
          </AccountActiontext>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          color={"#4FC3FD"}
          style={{ cursor: "pointer", gap: "10px" }}
        >
          <img
            src={logoutMainImg}
            alt="log out"
            height={"13.5px"}
            width={"13.5px"}
          />
          <AccountActiontext onClick={onLogoutClick}>Log Out</AccountActiontext>
        </Box>
      </Box>
    </Box>
    // Customizable Area End
  );
};
