import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {}

interface SS {
  id: any;
}

export default class ForgotPasswordGenericController<
  State = S
> extends BlockComponent<Props, State, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  goBackToLogin() {
    this.props.navigation.navigate("EmailAccountLoginBlock");
  }
}
