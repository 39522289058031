import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  styled,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import {
  searchIconBig,
  micIcon,
  uploadIcon,
  checkboxUnselect,
  checkboxSelected,
} from "./assets";

const data = [
  {
    id: 1,
    labelText: "Building Name (example 100 or A)",
    voiceAndupload: true,
  },
  {
    id: 2,
    labelText: "How many units in Each building.",
    voiceAndupload: false,
  },
  {
    id: 3,
    labelText: "How many floors in Each building.",
    voiceAndupload: false,
  },
  {
    id: 4,
    labelText: "Do the floor plans align and stack?",
    voiceAndupload: true,
  },
  {
    id: 5,
    labelText:
      "What is the approximate size of each building, length and width.",
    voiceAndupload: true,
  },
  {
    id: 6,
    labelText: "What materials are each building constructed from?",
    voiceAndupload: true,
  },
  {
    id: 7,
    labelText: "Is there accessible attic space in each building?",
    voiceAndupload: true,
  },
  {
    id: 8,
    labelText: "Is there accessible basement space in each building?",
    voiceAndupload: true,
  },
  {
    id: 9,
    labelText:
      "Is there common area stairs and/or breezeways  in each building?",
    voiceAndupload: true,
  },
  {
    id: 10,
    labelText: "Is there telecommunications closets in each building?",
    voiceAndupload: true,
  },
  {
    id: 11,
    labelText:
      "If no existing telecommunications closets, are there other closets or locations for IDFs?",
    voiceAndupload: true,
  },
  {
    id: 12,
    labelText:
      "Is there Property provided House electrical Power? Where is it located Mark on Map",
    voiceAndupload: true,
  },
  {
    id: 13,
    labelText: "Are thre enclosed, interior center hallways in the building?",
    voiceAndupload: true,
  },
  {
    id: 14,
    labelText:
      "If there are center hallways, what is the ceiling type (Drop tiles, sheetrock, etc)",
    voiceAndupload: true,
  },
  {
    id: 15,
    labelText: "Is there an elevator in the building?",
    voiceAndupload: true,
  },
  {
    id: 16,
    labelText:
      "Is there any common areas or amenity spaces in the building, if so, where are the located",
    voiceAndupload: true,
  },
  {
    id: 17,
    labelText: "General Comments - add any other info you feel necessary here",
    voiceAndupload: true,
  },
];

const SurveyFormHeadingBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "15px",
});

const SurveyFormHeading = styled(Typography)({
  color: "#000",
  fontFamily: "Poppins",
  fontSize: "30px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
});

export const FieldBox = styled(Box)({
  marginTop: "22.5px",
  marginBottom: "20px",
  display: "flex",
  flexDirection: "column",
});

export const FieldBoxSearch = styled(Grid)({
  marginTop: "22.5px",
  marginBottom: "15px",
  display: "flex",
  justifyContent: "center",
});

export const LabelText = styled(Typography)({
  color: "#000",
  fontFamily: "Poppins",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  marginBottom: "9px",
  textAlign: "left",
});

export const TextFieldCustomSearch = styled(TextField)({
  borderRadius: "8px",
  marginBottom: "30px",
  width: "100%",

  "& input::placeholder": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },

  "& .MuiOutlinedInput-input": {
    width: "100%",
    textAlign: "center",
    borderRadius: "8px",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#DCE1E4",
    borderRadius: "8px",
  },

  "& .MuiSelect-outlined.MuiSelect-outlined": {
    textAlign: "left",
    color: "black !important",
  },
  "& .Mui-focused": {
    "& img": {
      position: "unset",
    },
  },

  '& input[type="number"]': {
    "-moz-appearance": "textfield",

    "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },

  "& .MuiFormHelperText-contained": {
    marginLeft: "4px",
    marginRight: "14px",
  },
});

const CustomInputSearch = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const SearchIconBig = styled("img")({
  cursor: "pointer",
  width: "35px",
  height: "35px",
  position: "absolute",
  left: "calc(100% - 68%)",
  top: 17,
  "@media (max-width: 1050px)": {
    position: "unset",
  },
  "@media (max-width: 1100px)": {
    left: "calc(100% - 79%) !important",
  },
  "@media (max-width: 1460px)": {
    left: "calc(100% - 77%) !important",
  },
  "@media (max-width: 1650px)": {
    left: "calc(100% - 70%) !important",
  },
});

const MicIcon = styled("img")({
  cursor: "pointer",
});

const UploadIcon = styled("img")({
  cursor: "pointer",
});

const VoiceAndUploadBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  gap: "20px",
  width: "100%",

  "@media (max-width: 1330px)": {
    flexDirection: "column",
  },
});

const NormalAndUploadBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  gap: "20px",
  "@media (max-width: 1330px)": {
    flexDirection: "column",
  },
});

export const TextFieldCustomVoice = styled(TextField)({
  width: "100%",
  borderRadius: "4px",
  maxWidth: "814px",

  "@media (max-width: 1330px)": {
    maxWidth: "unset",
  },

  "& input::placeholder": {
    fontStyle: "normal",
    fontFamily: "Poppins",
    fontWeight: 400,
    lineHeight: "normal",
    fontSize: "20px",
  },

  "& .MuiOutlinedInput-input": {
    width: "100%",
    borderRadius: "4px",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "4px",
    borderColor: "#DCE1E4",
  },

  "& .MuiSelect-outlined.MuiSelect-outlined": {
    color: "black !important",
    textAlign: "left",
  },

  '& input[type="number"]': {
    "-moz-appearance": "textfield",

    "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },

  "& .MuiFormHelperText-contained": {
    marginRight: "14px",
    marginLeft: "4px",
  },
});

export const TextFieldCustomUpload = styled(TextField)({
  borderRadius: "4px",
  width: "100%",

  "& .MuiOutlinedInput-input": {
    width: "100%",
    borderRadius: "4px",
  },
  "@media (max-width: 1330px)": {
    maxWidth: "unset",
  },

  "& input::placeholder": {
    fontSize: "20px",
    lineHeight: "normal",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
  },

  "& .MuiFormHelperText-contained": {
    marginLeft: "4px",
    marginRight: "14px",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#DCE1E4",
    borderRadius: "4px",
  },

  "& .MuiSelect-outlined.MuiSelect-outlined": {
    color: "black !important",
    textAlign: "left",
  },
});

const TextFieldCustomNormal = styled(TextField)({
  maxWidth: "258px",
  width: "100%",
  borderRadius: "4px",

  "@media (max-width: 1330px)": {
    maxWidth: "unset",
  },

  "& .MuiOutlinedInput-input": {
    borderRadius: "4px",
    width: "100%",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "4px",
    borderColor: "#DCE1E4",
  },

  "& .MuiSelect-outlined.MuiSelect-outlined": {
    color: "black !important",

    textAlign: "left",
  },

  '& input[type="number"]': {
    "-moz-appearance": "textfield",

    "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },

  "& .MuiFormHelperText-contained": {
    marginLeft: "4px",
    marginRight: "14px",
  },

  "& input::placeholder": {
    fontStyle: "normal",
    fontWeight: 400,
    fontFamily: "Poppins",

    lineHeight: "normal",
    fontSize: "20px",
  },
});

const UpperBorderDesign = styled(Box)({
  borderRadius: "8px 8px 0px 0px",
  background: "#4FC3FD",
  minHeight: "10px",
});

const FormsBox = styled(Box)({
  padding: "0px 119px 0px 139px",
  boxShadow: "0px 1px 8px 0px rgba(0, 0, 0, 0.15)",
  "@media (max-width: 900px)": {
    padding: "0px 20px 0px 20px",
  },
});

const RightBoxHeading = styled(Typography)({
  paddingTop: "24px",
  color: "#000",
  fontFamily: "Poppins",
  fontSize: "25px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  textAlign: "center",
});

const AddFormButton = styled(Button)({
  display: "flex",
  width: "258px",
  height: "44px",
  padding: "10px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  flexShrink: 0,
  borderRadius: "8px",
  background: "#EBF5FE",
  color: "#0090CF",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "24px",
  textTransform: "none",
  "@media (max-width: 900px)": {
    width: "70%",
  },
});

const BelowButtonBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "64px",
  marginBottom: "30px",
  "@media (max-width: 900px)": {
    flexDirection: "column",
  },
});

const SaveButton = styled(Button)({
  display: "flex",
  minWidth: "183px",
  height: "44px",
  padding: "10px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  flexShrink: 0,
  borderRadius: "8px",
  background: "#EBF5FE",
  color: "#0090CF",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "24px",
  textTransform: "none",

  "@media (max-width: 900px)": {
    width: "70%",
  },
});

const NextButton = styled(Button)({
  display: "flex",
  minWidth: "183px",
  height: "44px",
  padding: "10px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  flexShrink: 0,
  borderRadius: "8px",
  background: "linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 100%)",
  color: "#FFF",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "24px",
  textTransform: "none",
  "@media (max-width: 900px)": {
    width: "70%",
    marginTop: "10px",
  },
});

const CheckboxSection = styled(Box)({
  marginTop: "20px",
});

const CheckboxLabel = styled(Typography)({
  color: "#000",
  fontFamily: "Poppins",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  marginBottom: "9px",
});

const CheckboxImage = styled("img")({});

const CustomFormControlLabel = styled(FormControlLabel)({
  width: "258px",
  padding: "8px 0px 8px 0px",
  borderRadius: "4px",
  border: "1px solid #E5E5E5",
  marginLeft: "0px !important",
  marginBottom: "36px",
  "& .MuiFormControlLabel-label": {
    color: "#475569",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },

  "& .MuiFormControlLabel-root": {
    marginLeft: "0px !important",
  },
});

const LowerBorderDesign = styled(Box)({
  borderRadius: "0px 0px 8px 8px",
  background: "#7B61FD",
  minHeight: "10px",
});

interface BuildingInfoMainInterface {
  checkCopy: boolean;
  handleCopyCheckbox: any;
}

// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class BuildingInfoMain extends React.Component<
  any,
  BuildingInfoMainInterface
> {
  constructor(props: BuildingInfoMainInterface) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    const { handleCopyCheckbox } = this.props;
    return (
      // Customizable Area Start
      // Required for all blocks
      <>
        <SurveyFormHeadingBox>
          <SurveyFormHeading>Survey Form: Survey name here</SurveyFormHeading>
        </SurveyFormHeadingBox>
        <TextFieldCustomSearch
          id="outlined-basic"
          data-test-id="searchBox"
          placeholder={"Search anywhere in the survey report"}
          variant="outlined"
          type="text"
          InputProps={{
            startAdornment: (
              <CustomInputSearch>
                <SearchIconBig src={searchIconBig} />
                <InputAdornment position="start"></InputAdornment>
              </CustomInputSearch>
            ),
          }}
        />
        <UpperBorderDesign />
        <FormsBox>
          <RightBoxHeading>Building Information</RightBoxHeading>

          {data.map((elm) => {
            return (
              <FieldBox key={elm.id}>
                {elm.voiceAndupload ? (
                  <>
                    <LabelText>{elm.labelText}</LabelText>
                    <VoiceAndUploadBox>
                      <TextFieldCustomVoice
                        id="outlined-basic"
                        data-test-id="searchBox"
                        placeholder={"Enter text here"}
                        variant="outlined"
                        type="text"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <MicIcon src={micIcon} />
                            </InputAdornment>
                          ),
                        }}
                      />

                      <TextFieldCustomUpload
                        id="outlined-basic"
                        data-test-id="searchBox"
                        placeholder={"Upload image/video"}
                        variant="outlined"
                        type="text"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <UploadIcon src={uploadIcon} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </VoiceAndUploadBox>
                  </>
                ) : (
                  <>
                    <LabelText>{elm.labelText}</LabelText>
                    <NormalAndUploadBox>
                      <TextFieldCustomNormal
                        id="outlined-basic"
                        data-test-id="searchBox"
                        placeholder={"Enter digit here"}
                        variant="outlined"
                        type="text"
                      />

                      <TextFieldCustomUpload
                        id="outlined-basic"
                        data-test-id="searchBox"
                        placeholder={"Upload image/video"}
                        variant="outlined"
                        type="text"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <UploadIcon src={uploadIcon} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </NormalAndUploadBox>
                  </>
                )}
              </FieldBox>
            );
          })}

          <AddFormButton>Add Building</AddFormButton>
          <CheckboxSection>
            <CheckboxLabel>Copy from the last filled?</CheckboxLabel>
            <CustomFormControlLabel
              control={
                <Checkbox
                  checked={false}
                  icon={<CheckboxImage src={checkboxUnselect} />}
                  checkedIcon={<CheckboxImage src={checkboxSelected} />}
                  name="checkCopy"
                  onChange={(e: any) => handleCopyCheckbox(e)}
                />
              }
              label="Yes"
            />
          </CheckboxSection>
        </FormsBox>
        <LowerBorderDesign />
        <BelowButtonBox>
          <SaveButton>Save</SaveButton>
          <NextButton>Next</NextButton>
        </BelowButtonBox>
      </>
      // Customizable Area End
    );
  }
}
