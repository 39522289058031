// Customizable Area Start
import { ChangeEventHandler, LegacyRef, MouseEventHandler } from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { SurveyInfoField } from "./CustomFormController.web";
// Customizable Area End

export type Props = SurveyInfoField & {
  handleChange: (value: string | string[]) => void | Promise<void>;
  value?: string | string[];
  fieldRef?: LegacyRef<HTMLDivElement>;
  openBulkUploadImages(eventFields: React.MouseEvent,uniqueId: number): void;
  isError: boolean;
  handleMouseDown?: MouseEventHandler<HTMLButtonElement>
  handleMouseRelease?: MouseEventHandler<HTMLButtonElement>;
  handleBlurFields: ()=> void;
  dynamicOptions: {
    data: {
      user_survey: {
          id: number | string,
          survey_name: string,
          created_by: string,
          owner_name: string,
          survey_template_id: number | string,
          status: string,
          is_deleted: null | boolean | string,
          created_at: string,
          updated_at: string,
          account_id: number | string
      },
      survey_associated_buildings: string[] | [],
      survey_associated_floors: string[] | []
    }
  }
};

interface S {
  error: string;
  inputValue: string;
  inputCheckboxValues: string[];
  isError: boolean;
  convertedText: string;
}

interface SS {}
export class CustomFieldController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.state = {
      inputCheckboxValues: Array.isArray(this.props.value)
        ? this.props.value
        : [],
      inputValue: typeof this.props.value === "string" ? this.props.value : "",
      error: "",
      isError: this.props.isError,
      convertedText: '',
    };
  }

  // Customizable Area Start
  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    __?: SS | undefined
  ): void {
    if (prevProps.id !== this.props.id) {
      this.setState({
        inputCheckboxValues: [],
        inputValue: "",
        error: "",
      });
    } else if (this.state.inputValue !== prevState.inputValue) {
      this.props.handleChange(this.state.inputValue);
    } else if (
      this.state.inputCheckboxValues.toString() !==
      prevState.inputCheckboxValues.toString()
    ) {
      this.props.handleChange(this.state.inputCheckboxValues);
    } else if (prevProps.value !== this.props.value) {
      this.setState({
        inputValue:
          typeof this.props.value === "string"
            ? this.props.value
            : this.state.inputValue,
        inputCheckboxValues: Array.isArray(this.props.value)
          ? this.props.value
          : this.state.inputCheckboxValues,
      });
    }
  }

  checkboxChangeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    const val = e.target.value;
    let inputCheckboxValues: string[];
    if (this.state.inputCheckboxValues.includes(val)) {
      inputCheckboxValues = this.state.inputCheckboxValues.filter(
        (checkbox) => val !== checkbox
      );
    } else {
      inputCheckboxValues = [...this.state.inputCheckboxValues, val];
    }
    if(inputCheckboxValues.length===0 && this.props.is_mandatory){
      this.setState({ error: "Please enter data", inputCheckboxValues });
    } else {
      this.setState({ error: "", inputCheckboxValues });
    }
  };

  validateNumber = (strNumber: string) => {
    let regExp = /^[0-9\b]+$/;
    this.setState({inputValue: strNumber.replace(/\D/g, '')})
    return regExp.test(strNumber);
  };

  changeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { field_type, is_mandatory } = this.props;
    const { value } = e.target;
    if (field_type === "numeric" && value.length > 0 && !this.validateNumber(value)) {
        this.setState({ error: "Please enter a number" });
    } else if ((field_type === "select" || field_type === 'dynamic_dropdown') && is_mandatory && value.length === 0) {
        this.setState({ error: "", inputValue: '' });
    } else {
        this.setState({ error: "", inputValue: value });
    }
  };



  handleBlur = () => {
    const { is_mandatory, field_type } = this.props;
    const { inputCheckboxValues, inputValue } = this.state;
    if (((field_type === "text" || field_type === "numeric" || field_type === "select" || field_type === "dynamic_dropdown") && is_mandatory && !inputValue) || (field_type === 'checkbox' && is_mandatory && inputCheckboxValues.length===0)) {
        return this.setState({ error: "Please enter data" });
    } else {
        this.props.handleBlurFields();
        this.setState({ error: "" });
    }
};

  get hasError() {
    return this.props.isError;
  }

  getDynamicOptions = (dynamic_dropdown_type: string) => {
    if(dynamic_dropdown_type === 'Building'){
      return this.props.dynamicOptions.data.survey_associated_buildings;
    } else {
      return this.props.dynamicOptions.data.survey_associated_floors;
    }
  }

  getPlaceHolderValue = (dynamic_dropdown_type: string) => {
    if(dynamic_dropdown_type === 'Building'){
      return 'Select from the list of buildings that have been added';
    } else {
      return 'Select from the list of floors that have been added';
    }
  }
  // Customizable Area End
}
