// Customizable Area Start
import React from "react";
import { Box, Slider, Typography, styled } from "@material-ui/core";
import ProfileImageAdjustSliderController, {
  Props,
} from "./ProfileImageAdjustSliderController";

const ZoomContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  paddingTop: "23px",

  backgroundColor: "rgba(220, 225, 228, 1)",
});

const TextContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const ZoomText = styled(Typography)({
  fontFamily: "Poppins, Inter, sans-serif",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "24px",
  background:
    "linear-gradient(90deg, rgba(52,185,252,1) 0%, rgba(91,137,253,1) 50%, rgba(123,97,253,1) 100%)",

  "-webkit-background-clip": "text",
  "-webkit-text-fill-color": "transparent",
  "-moz-background-clip": "text",
  "-moz-text-fill-color": "transparent",
});

const ZoomSlider = styled(Slider)({
  "& >.MuiSlider-root": {
    paddingTop: "16px",
  },
  "& >.MuiSlider-thumb": {
    backgroundColor: "rgba(123,97,253,1)",
  },
  "& >.MuiSlider-rail": {
    backgroundColor: "white",
  },
  "& >.MuiSlider-track": {
    background:
      "linear-gradient(90deg, rgba(52,185,252,1) 0%, rgba(91,137,253,1) 50%, rgba(123,97,253,1) 100%)",
  },
});

const ContentContainer = styled(Box)({
  maxWidth: "536px",
  width: "95%",
});

export default class ProfileImageAdjustSlider extends ProfileImageAdjustSliderController {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { zoom } = this.props;

    return (
      <ZoomContainer>
        <ContentContainer>
          <TextContainer>
            <ZoomText>Zoom</ZoomText>
            <ZoomText>{zoom}</ZoomText>
          </TextContainer>
          <ZoomSlider
            value={zoom}
            min={0.9}
            max={3}
            step={0.1}
            onChange={this.handlezoomChange}
          />
        </ContentContainer>
      </ZoomContainer>
    );
  }
}
// Customizable Area End
