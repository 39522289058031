// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { unchecked, checked, crossIcon } from "./assets";
import storage from "../../../framework/src/StorageProvider.web";
import * as Yup from "yup"
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface ValidResponseType {
  data: object;
}

export interface InvalidResponseType {
  errors: Array<UserPasswordError>;
}

export interface UserPasswordError {
  password: string;
}

export interface UserPassword {
  meta: {
    message: string;
  };
}

export interface ApidataResponseMock {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

interface S {
  // Customizable Area Start
  openDrawer: boolean;
  showSuccessMessage:boolean;
  error: string;
  enableCurrentPassField: boolean;
  currentPass: string;
  enableNewPassField: boolean;
enableConfirmNewPassField: boolean;
newPass: string;
toLowerCase: boolean;
  toUpperCase: boolean;
  toNumber: boolean;
  toCharacter: boolean;
  validationError: string;
  newPassError: boolean;
  continuePopup: boolean;
  confirmPass: string;
  isPasswordChanged: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChangePasswordMobileScreenController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  updatePasswordApiCallId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      openDrawer: false,
      showSuccessMessage: false,
      error: "",
      enableCurrentPassField: true,
      currentPass: "",
      enableNewPassField: true,
      enableConfirmNewPassField: true,
      newPass: "",
      toLowerCase: false,
      toUpperCase: false,
      toNumber: false,
      toCharacter: false,
      validationError: "",
      newPassError: false,
      continuePopup:false,
      confirmPass: "",
      isPasswordChanged: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if(responseJson && ! responseJson.errors){
        if (this.isValidResponse(responseJson)) {
          this.apiSuccessCallBacks(apiRequestCallId);
        }
      }else if(responseJson && responseJson.errors){
         if (this.isInValidResponse(responseJson)) {
          this.apiFailCallBack(apiRequestCallId, responseJson);
        }
      }
       
     
    }
  }

  apiCall = async (data: ApidataResponseMock) => {
    let { contentType, method, endPoint, body, type } = data;
    const token = await storage.get("auth-token");
    const header = {
      "Content-Type": contentType,
      token: token,
    };

    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        type == "formData" ? body:JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  
  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson;
  };

  isInValidResponse = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors;
  };

  apiSuccessCallBacks = (
    apiRequestCallID: string,
  ) => {

    if (apiRequestCallID === this.updatePasswordApiCallId) {
          this.setState({
            isPasswordChanged: true
          })
    }
  };

  apiFailCallBack = (
    apiRequestCallID: string,
    responseJSON: InvalidResponseType
  ) => {
    if (apiRequestCallID === this.updatePasswordApiCallId) {
      this.setState({
        validationError: responseJSON.errors[0].password,
      });
    }
  };

  handleConfirmSubmit = async (
    values:{[key:string]: string}
  ) => {
    const { currentPassword, newPassword, confirmNewPassword } = values;
    const token = await storage.get("auth-token");
    let raw = {
      data: {
        attributes: {
          token: token,
          old_password: currentPassword,
          new_password: newPassword,
          confirm_password: confirmNewPassword,
        },
      },
    };

    this.updatePasswordApiCallId = await this.apiCall({
      contentType: configJSON.accountDeleteApiContentType,
      method: configJSON.patchMethod,
      endPoint: configJSON.changePasswordEndPoint,
      body: raw,
    });
  };

 handleSubmit = (values:{[key:string]:string}) => {
  if(values.newPassword !== values.confirmNewPassword){
    this.setState({
      validationError: "New password and confirm new password doesn't match."
    })
    return;
  }
    this.handleConfirmSubmit(values)
 }

 handleClickShowCurrentPassword = () => {
    this.setState({
        enableCurrentPassField : !this.state.enableCurrentPassField
    })
 }

 handleClickShowNewPassword = () => {
    this.setState({
        enableNewPassField : !this.state.enableNewPassField
    })
 }

 handleClickShowConfirmNewPassword = () => {
    this.setState({
        enableConfirmNewPassField : !this.state.enableConfirmNewPassField
    })
 }

 handleCurrentPassword = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue:(field:string, value:string, shouldValidate?:boolean)=> void) => {
    setFieldValue("currentPassword",event.target.value)
 }

 handleNewPassword = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue:(field:string, value:string, shouldValidate?:boolean)=> void) => {
  this.handlePassword(event)
  setFieldValue("newPassword",event.target.value)
 }

 handleConfirmNewPassword = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue:(field:string, value:string, shouldValidate?:boolean)=> void) => {
    setFieldValue("confirmNewPassword",event.target.value)
 }

 isIcon = (newPass: string, toCase: boolean): string => {
  let iconSource: string;

  if (newPass === "") {
    iconSource = unchecked;
  } else if (toCase) {
    iconSource = checked;
  } else {
    iconSource = crossIcon;
  }
  return iconSource;
};

handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
  const new_pass = event.target?.value;
  if (new_pass === this.state.newPass) {
    return;
  }

 if(new_pass.length < 8){
      this.setState({
        newPass: new_pass,
        validationError: "Your password does not meet all the requirements. Must have minimum character length is 8 characters"
      });
    }else{
      this.setState({
        newPass: new_pass,
        validationError: ""
      });
    }
  this.setState({ newPass: new_pass, newPassError: false }, () => {
    this.validatePassword();
  });
};

validatePassword = () => {
  const { newPass } = this.state;

  let lowerCase = configJSON.lowerLetterRegax;
  let upperCase = configJSON.upperRegax;
  let numbers = configJSON.digitRegax;

  this.setState({
    toLowerCase: false,
    toUpperCase: false,
    toNumber: false,
    toCharacter: false,
  });

  if (newPass?.match(lowerCase)) {
    this.setState({ toLowerCase: true });
  }
  if (newPass?.match(upperCase)) {
    this.setState({ toUpperCase: true });
  }
  if (newPass?.match(numbers)) {
    this.setState({ toNumber: true });
  }
  if (newPass?.length >= 8) {
    this.setState({ toCharacter: true });
  }
};

passwordSchema = () => {
  return Yup.object().shape({
    currentPassword: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/).required(),
    newPassword: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/).required(),
    confirmNewPassword: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/).required(),
  })
}
  // Customizable Area End
}
