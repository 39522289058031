import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const sendRequest = (
  path: string,
  method: string,
  setMessageId: (id: string) => void,
  body?: any,
  customHeader?: any
) => {
  const header = {
    "Content-Type": "application/json",
    ...customHeader,
  };
  const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
  setMessageId(message.messageId);
  message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), path);
  message.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
  if (body) {
    message.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
  }
  runEngine.sendMessage(message.id, message);
};

export const sendForgotPasswordRequest = (
  email: string,
  setMessageId: (id: string) => void
) => {
  const { origin } = window.location;

  const body = {
    data: {
      attributes: {
        email,
      },
    },
    host: origin,
  };
  sendRequest("/bx_block_forgot_password/forgot", "POST", setMessageId, body);
};
