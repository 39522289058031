import { FormControlLabel, RadioGroup, styled } from "@material-ui/core";
import React, { ChangeEventHandler } from "react";
import { CustmomCheckbox } from "./CustomFieldRadioButtons";
import { BlockComponent } from "../../../framework/src/BlockComponent";

interface Props {
  handleChange: ChangeEventHandler<HTMLInputElement>;
  handleBlur: () => void;
  inputValue: string;
  options: string[];
  hasError: boolean;
}

const CustomRadioGroup = styled(RadioGroup)({
  border: "1px solid rgba(229, 229, 229, 1)",
  borderRadius: '8px',
  color: "rgba(71, 85, 105, 1)",
  padding: "4px 16px",

"@media(max-width: 650px)":{ 
  "& > label > span":{
    fontSize: '12px !important',
  },
}
});

export class CustomFieldRadio extends BlockComponent<Props, {}, {}> {
  constructor(props: Props) {
    super(props);
  }
  render(): React.ReactNode {
    const {
      inputValue,
      handleChange,
      handleBlur,
      options,
      hasError
    } = this.props;

    function getBorderColor() {
      if(hasError){
        return "#F87171";
      }
    }
    
    return (
      <CustomRadioGroup
        row
        value={inputValue}
        onChange={handleChange}
        onBlur={handleBlur}
        style={{ borderColor: getBorderColor() }} 
      >
        {options.map((option) => (
          <FormControlLabel
            label={option}
            key={option}
            control={
              <CustmomCheckbox
                option={option}
                isSelected={inputValue === option}
                handleChange={handleChange}
              />
            }
          ></FormControlLabel>
        ))}
      </CustomRadioGroup>
    );
  }
};
