import React from "react";
import { Box, ListItem, Typography, styled } from "@material-ui/core";
import ChangePasswordController, {
  Props
} from "./ChangePasswordController.web";
import { ForgotPasswordWrapper } from "./ForgotPasswordWrapper";
import { imgKey } from "./assets";
import {
  LabelText,
  LoginBox
} from "../../email-account-login/src/EmailAccountLoginBlock.web";
import { PasswordField } from "./PasswordField";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

const ValidImage = styled(CheckCircleIcon)({
  height: "13px",
  width: "13px",
  color: "#059669"
});

const InvalidImage = styled(CancelIcon)({
  height: "13px",
  width: "13px",
  color: "#DC2626"
});

const NoPasswordImage = styled(CheckCircleIcon)({
  height: "13px",
  width: "13px",
  color: "#A6B1B7"
});
const ValidationBox = styled(Box)({
  "@media (max-width: 550px)": {
    marginLeft: "25px",
    marginRight: "25px"
  }
});

export default class ChangePassword extends ChangePasswordController {
  constructor(props: Props) {
    super(props);

    this.backToLogin = this.backToLogin.bind(this);
    this.submit = this.submit.bind(this);
    this.handlePasswordInput = this.handlePasswordInput.bind(this);
    this.handlePasswordRepeatInput = this.handlePasswordRepeatInput.bind(this);
    this.getPasswordValidation = this.getPasswordValidation.bind(this);
  }

  getImage(valid: boolean) {
    if (!this.state.password) {
      return <NoPasswordImage />;
    }

    if (valid) {
      return <ValidImage />;
    }
    return <InvalidImage />;
  }

  render() {
    return (
      <ForgotPasswordWrapper
        title="New password"
        titleImage={imgKey}
        subtitile="Your new password must be different to previously used password"
        titleImageAlt={"key"}
        onSubmitClick={this.submit}
        onCancelClick={this.backToLogin}
        submitButtonText={"Confirm"}
        cancelButtonText={"Back to login"}
        showError={this.state.showError}
        errorMessage={this.state.error}
        isFetching={this.state.isFetching}
      >
        <Box width={"100%"}>
          <LoginBox>
            <PasswordField
              data-test-id="password"
              label="New Password"
              error={Boolean(this.state.error)}
              value={this.state.password}
              handleChange={this.handlePasswordInput}
            />
            <PasswordField
              error={Boolean(this.state.error)}
              data-test-id="password-repeat"
              label="Confirm New Password"
              value={this.state.passwordRepeat}
              handleChange={this.handlePasswordRepeatInput}
            />
          </LoginBox>
          <ValidationBox marginTop={"40px"}>
            <LabelText style={{ fontSize: "16px", textAlign: "left" }}>
              Your password must contain
            </LabelText>
            {Object.values(this.getPasswordValidation()).map(
              ({ message, valid }) => (
                <ListItem
                  key={message}
                  style={{ marginLeft: 0, paddingLeft: 0, paddingBottom: 0 }}
                >
                  {this.getImage(valid)}
                  <Typography
                    key={message}
                    style={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: 300,
                      lineHeight: "17px",
                      textAlign: "left",
                      color: "#A6B1B7",
                      marginLeft: "6px"
                    }}
                  >
                    {message}
                  </Typography>
                </ListItem>
              )
            )}
          </ValidationBox>
        </Box>
      </ForgotPasswordWrapper>
    );
  }
}
