import React from "react";
import { Typography, styled, Box, Button } from "@material-ui/core";
import { alertLogo } from "./assets";

interface DeleteSurveyPopupInterface {
  handleCloseDeleteSurveyPopup: () => void;
  message: string;
  firstBtnText: string;
  secondBtnText: string;
  handleConfirmDeleteSurvey: () => void;
}

const PopUpIcon = styled("img")({
  height: "92px",
  width: "92px",
  "@media(max-width:500px)":{
    height: "58.86px",
    width: "60px",
  }
});

const Message = styled(Typography)({
  fontFamily: "Poppins, Inter, sans-serif",
  fontSize: "25px",
  lineHeight: "37px",
  fontWeight: 400,
  textAlign: "center",
  maxWidth: "555px",
  "@media(max-width: 550px)": {
    fontSize: "22px"
  },
  "@media(max-width: 500px)": {
    fontSize: "16px",
    lineHeight: "normal"
  },
  "@media(max-width: 335px)": {
    fontSize: "19px"
  }
});

const ButtonBox = styled(Box)({
  display: "flex",
  marginTop: "60px",
  justifyContent: "space-between",
  "@media(max-width: 670px)": {
    flexDirection: "column",
    gap: "10px",
    marginTop: "50px"
  },
  "@media(max-width: 500px)": {
    marginTop: "30px",
    gap: "20px",
    flexDirection: "column-reverse",
    width: "100%"
  },
  "@media(min-width: 768px) and (max-width: 1300px)": {
    flexDirection: "column",
    gap: "10px",
    marginTop: "50px"
  },
  gap: "75px"
});

const FirstButton = styled(Button)({
  width: "258px",
  fontWeight: 500,
  lineHeight: "24px",
  textTransform: "none",
  padding: "10px 16px",
  fontSize: "16px",
  borderRadius: "8px",
  height: "44px",
  background: "#C2E1FA",
  color: "#0090CF",
  fontFamily: "Poppins",
  fontStyle: "normal",
  "@media(max-width: 500px)": {
    width: "100%"
  }
});

const SecondButton = styled(Button)({
  width: "258px",
  height: "44px",
  padding: "10px 16px",
  borderRadius: "8px",
  background: "linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 100%)",
  color: "#FFF",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "24px",
  textTransform: "none",
  "@media(max-width: 500px)": {
    width: "100%"
  }
});

const CustomConfiramtionDialog = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  zIndex: 2,
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  padding: "66px",
  borderRadius: "8px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
  height: "250px",
  lineHeight: "2rem",
  width: "50%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "25px",
  "@media(max-width: 767px)": {
    width: "80%"
  },
  "@media(max-width: 600px)": {
    gap: "15px"
  },
  "@media(max-width: 500px)": {
    height: "344px",
    position: "fixed",
    zIndex: 1102,
    boxSizing: "border-box",
    padding: "45px 34px 45px 35px",
    width: "calc(100% - 48px)"
  },
});
export const BlurBackground = styled(Box)({
  backdropFilter: "blur(5px)",
  "-webkit-backdrop-filter": "blur(5px)",
  borderRadius: "16px",
  position: "absolute",
  zIndex: 1,
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  background: "rgba(24, 24, 24, 0.40)",
  border: "1px solid transparent",
  "@media(max-width:500px)":{
  backdropFilter: "unset !important",
  position: "fixed",
  zIndex: 1101,
  }
});
class DeleteSurveyPopup extends React.Component<
  DeleteSurveyPopupInterface,
  any
> {
  constructor(props: DeleteSurveyPopupInterface) {
    super(props);
  }

  render() {
    const {
      handleCloseDeleteSurveyPopup,
      message,
      firstBtnText,
      secondBtnText,
      handleConfirmDeleteSurvey
    } = this.props;
    return (
      <div>
        <BlurBackground />
        <CustomConfiramtionDialog>
          <PopUpIcon src={alertLogo} />
          <Message id="alert-dialog-description">{message}</Message>
          <ButtonBox>
            <FirstButton
              data-test-id="first_btn_text"
              onClick={handleCloseDeleteSurveyPopup}
            >
              {firstBtnText}
            </FirstButton>
            <SecondButton
              data-test-id="second_btn_text"
              onClick={handleConfirmDeleteSurvey}
            >
              {secondBtnText}
            </SecondButton>
          </ButtonBox>
        </CustomConfiramtionDialog>
      </div>
    );
  }
}

export default DeleteSurveyPopup;
