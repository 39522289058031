import React, { ChangeEventHandler, MouseEventHandler } from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";

export interface Props {
  handleChange: ChangeEventHandler<HTMLInputElement>;
  handleBlur: () => void;
  hasError: boolean;
  inputValue: string;
  id: number;
  is_speech_to_text_required: boolean;
  type?: "text" | "numeric";
  disabled?: boolean;
  isAudioInput?: boolean;
  handleMouseDown?: MouseEventHandler<HTMLButtonElement>
  handleMouseRelease?: MouseEventHandler<HTMLButtonElement>;
}

interface S {}

interface SS {}
const UNALLOWED_NUMERIC_CHARS = ["e", "E", "-", "+", ",", "."];
export default class CustomFieldTextController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  handleNumericKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const pressedKey = e.key;
    // check if presedd key allowed character
    if (
      this.props.type === "numeric" &&
      UNALLOWED_NUMERIC_CHARS.includes(pressedKey)
    ) {
      e.preventDefault();
    }
  };
}
