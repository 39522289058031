// Customizable Area Start
import { Area, Point } from "react-easy-crop";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { createImage } from "./utils";

export interface Props {
  imageSrc: string;
  onFileUpload: (
    x: number,
    y: number,
    height: number,
    width: number
  ) => Promise<void>;
  onClose: () => void;
}

interface S {
  x: number;
  y: number;
  croppedX: number;
  croppedY: number;
  height: number;
  width: number;
  zoom: number;
  imageProps: React.CSSProperties;
  showCropper: boolean;
}

interface SS {}

export default class ProfileImageAdjustController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      x: 0,
      y: 0,
      croppedX: 0,
      croppedY: 0,
      height: 0,
      width: 0,
      zoom: 1,
      imageProps: {},
      showCropper: false,
    };
  }

  async componentDidMount(): Promise<void> {
    await this.setImageRatio();
  }

  onCropChange = ({ x, y }: Point) => {
    this.setState({ x, y });
  };

  onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    this.setState({
      height: croppedAreaPixels.height,
      width: croppedAreaPixels.width,
      croppedX: croppedAreaPixels.x,
      croppedY: croppedAreaPixels.y,
    });
  };

  onZoomChange = (zoom: number) => {
    this.setState({ zoom });
  };

  onSave = async () => {
    const { croppedX, croppedY, height, width } = this.state;

    await this.props.onFileUpload(croppedX, croppedY, height, width);
    this.props.onClose();
  };

  //used to set correct image ratio
  private setImageRatio = async () => {
    const image = await createImage(this.props.imageSrc);
    const { width, height } = image;
    console.log('image', image);
    

    const cssProps: React.CSSProperties = {
      objectFit: 'contain'
    };
    if (width > height) {
      cssProps.width = "100%";
    } else if (width < height) {
      cssProps.height = "100%";
    } else {
      cssProps.width = "100%";
      cssProps.height = "100%";
    }

    this.setState({
      imageProps: cssProps,
      showCropper: true,
    });
  };
}
// Customizable Area End
