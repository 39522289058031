// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { PhotosNew, SubsectionAcc, SurveyInfoField } from "./CustomFormController.web";
import React from "react";
import storage from "../../../framework/src/StorageProvider";
interface HeadingRefs {
  [key: string]: React.RefObject<HTMLHeadingElement>;
}
interface SpeechRecognitionEvent {
  results: Array<Array<{ transcript: string; }>>
}

const speech = new (window as any).webkitSpeechRecognition;
// Customizable Area End

export interface Props {
  // Customizable Area Start
  name: string;
  fields: SurveyInfoField[];
  add_similar_section_text?: string;
  copy_from_last_filled?: boolean;
  add_similar_section?: boolean;
  openAccordions: any[];
  handleAccordionToggle(index: string): void;
  handleAddBuilding(): void;
  openAccordionsOpen: boolean;
  fieldValues: {
    [subSectionName: string]: {
      [fieldName: string]: string | string[];
    };
  };
  isMandatoryError: {
    [subSectionName: string]: {
      [fieldName: string]: boolean;
    };
  }
  handleFieldChange: (
    subSectionName: string,
    fieldName: string,
    value: string | string[],
    dynamic_dropdown_type?: string
  ) => void;
  fieldToScrollName: string | null;
  resetFieldToScroll: () => void;
  handleCopyDataPreviousField: (subsectionName:string, value: boolean)=> void;
  copyFromLastFilledChecked: boolean;
  handleDeleteSubsectionPopup:(subSecName:string)=>void;
  openAccordionsNew: SubsectionAcc[],
  openBulkUploadImages(eventFields: React.MouseEvent,fields: number,subsectionName: string): void;
  isUploadingTrue: boolean;
  uploadPictures: any;
  videoNotSupported: string;
  deleteIcon: string;
  deleteImageButton: string;
  handleDescriptionChange: (fileId: PhotosNew, e: string) => void;
  handleDeleteFiles: (fileId: PhotosNew,subSectionNew: string, index:number) => void;
  deleteFileConfirmationPopup: boolean;
  handleCloseDeleteFilePopup:() =>void;
  handleOpenDeletePopup:() => void;
  fileFormat: string;
  subsectionName: string;
  handleScrollToHeading: (subSectionName:string)=> void;
  headingRefs: HeadingRefs;
  handleBlurFields: () => void;
  dynamicOptions: {
    data: {
      user_survey: {
          id: number | string,
          survey_name: string,
          created_by: string,
          owner_name: string,
          survey_template_id: number | string,
          status: string,
          is_deleted: null | boolean | string,
          created_at: string,
          updated_at: string,
          account_id: number | string
      },
      survey_associated_buildings: string[] | [],
      survey_associated_floors: string[] | []
    }
  }
  // Customizable Area End
}
interface S {
  selectedField: HTMLDivElement | null;
  currentField: string;
  currentSubSection: string;
  copyCheckBox: boolean;
  fileData: PhotosNew;
  speech_type: string;
}

interface SS {}

export class SurveyController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedField: null,
      currentField:"",
      currentSubSection:"",
      copyCheckBox: false,
      fileData: {
        id: 0,
        filename: "",
        content_type: "",
        created_at: "",
        byte_size: 0,
        url: "",
        media_id: 0,
        subSectionNew: "",
        description: "",
        fieldId: 0
      },
      speech_type: "",
    };
  }
// Customizable Area Start
  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ): void {
    if (this.props.fieldToScrollName && this.state.selectedField) {
      this.state.selectedField.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      this.props.resetFieldToScroll();
      this.setState({ selectedField: null });
    }
  }

  async componentDidMount() {
    speech.addEventListener("result", this.handleSpeechResult);
    await storage.set("copyCheckBox", "false");
  }

  handleSpeechResult = (event: SpeechRecognitionEvent) => {
    const { speech_type, currentSubSection, currentField, fileData } = this.state;
    if (event.results && event.results.length > 0) {
      const newText = event.results[0][0].transcript;
      if(speech_type === 'field'){
        this.props.handleFieldChange(currentSubSection, currentField, newText)
      } else {
        this.props.handleDescriptionChange(fileData, newText)
      }
    }
  };

  handleSpeechStart = (subSectionName:string, fieldName:string) => {
    speech.start();
    this.setState({
      currentField:fieldName,
      currentSubSection: subSectionName,
      speech_type: 'field'
    })
  }
  
  handleSpeechStop =()=>{
    speech.stop();
  }

  handleSpeechStart1 = (file: PhotosNew) => {
      speech.start();
      this.setState({ fileData: file, speech_type: 'description'});
  }

  onSelectField = (node: HTMLDivElement) => {
    this.setState({ selectedField: node });
  };

  createChangeHandler = (subSectionName: string, fieldName: string, value: string|string[], dynamic_dropdown_type: string) => {
      this.props.handleFieldChange(subSectionName, fieldName, value, dynamic_dropdown_type);
    }

  isScrollableField = (fieldName: string) => {
    return (
      fieldName === this.props.fieldToScrollName && !this.state.selectedField
    );
  };

  checkMondatoryFields = (subSectionName: string, fieldName: string) => {
    if(!subSectionName){
      subSectionName = this.props.name;
    }
    return this.props.isMandatoryError?.[subSectionName]?.[fieldName];
  }

  disableCopyCheckBox = () => {
    this.setState({copyCheckBox: false})
  }

  handleCopyCheck = async (sectionName: string) => {
    let checkValue = await storage.get("copyCheckBox");
    const parsedValue = JSON.parse(checkValue);
    this.setState({copyCheckBox: !parsedValue});
    this.props.handleCopyDataPreviousField(sectionName, !parsedValue);
  };
// Customizable Area End
}
