import React from "react";

// Customizable Area Start
import {
Container,
  Box,
  Button,
  Typography,
  InputAdornment,
IconButton,
  styled,
  TextField
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import {backgroundImg,eyeOff, loginBGImageMobile} from "./assets";
import { Logo } from "./Logo";
 import PasswordSecurityModal from "./PasswordSecurityModal.web";
import ChangePasswordModal from "./ChangePasswordModal.web";



const BackgroundImage = styled("div")({
  background: `url(${backgroundImg})`,
  backgroundSize: "cover",
  backgroundPosition: "center",

  "@media (max-width: 960px)":{
    background: "none",
  }
})

const LogoImageBox = styled(Box)({
  "@media (max-width: 550px)":{
    display:"flex",
    justifyContent:"center",
    height:"48px",
    alignItems:"center",
    boxShadow:"0px 0px 10px 0px lightgrey"
  }
})


export const MainContainer = styled(Container)({
  display:"flex",
  marginTop:"60px",
  paddingLeft:"35px",

"& .MuiContainer-maxWidthLg":{
  maxWidth:"1450px",
  "@media (max-width: 1700px)":{
      maxWidth:"unset !important",
  }
},
"@media (min-width: 1730px)":{
  maxWidth:"unset !important",
  paddingLeft: 170,
},
"@media (max-width: 550px)":{
  paddingLeft:"0px",
  padding:"0px",
  marginTop:"0px",
  display:"block",
}
})

export const MainSection = styled(Box)({
  display: "flex",
 flexDirection: "column",
 alignItems: "flex-start",
 minWidth:"536px",

 "@media (max-width: 960px)":{
  minWidth:"96%",
 },

 "@media (max-width: 600px)":{
  minWidth:"96%",
 },
})

export const LabelTitle = styled(Typography)({
color:"#181818",
fontFamily:"Poppins",
fontSize:"30px",
fontStyle:"normal",
fontWeight:600,
lineHeight:"normal",
marginBottom:"59px",
"@media (max-width: 550px)":{
alignSelf:"center",
marginTop:"60px",
marginBottom:"40px",
fontSize:'25px'
}
})

export const ErrorLoginAlert = styled(Box)({
  minWidth:"536px",
  padding: "12px 10px",
  borderRadius: "4px",
  borderTop: "4px solid #DC2626",
  backgroundColor: "#FEE2E2",
  "@media (max-width: 960px)":{
    minWidth:"97%",
   },
   "@media (max-width: 550px)":{
    width:"100%",
    padding: "12px 0px 12px 0px",
    borderRadius: "0px",
    marginBottom:"-45px"
   }
});

export const ErrorLoginText = styled(Typography)({
  color: "#DC2626",
  fontFamily: "Poppins",
  fontSize: "12px !important",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "18px",
  "@media (max-width: 550px)":{
    paddingLeft: "12px",
   }
});

export const LoginBox = styled(Box)({
  width: "100%", 
  marginTop:"50px",
  "@media (max-width: 550px)":{
    marginTop:"0px",
   }
})

export const FieldBox = styled(Box)({
 marginBottom:"15px",
 "@media (max-width: 550px)":{
  margin:"0px 25px 15px 25px"
 }
})

export const LabelText = styled(Typography)({
  color:"#424C53",
  fontFamily:"Poppins",
  fontSize:"20px",
  fontStyle:"normal",
  fontWeight:500,
  lineHeight:"normal",
  marginBottom:"5px",
  "@media(max-width: 650px)":{
  fontSize:"12px",
  }
});

export const TextFieldCustom = styled(TextField)({
  borderRadius: "8px",
  width: "100%",

  "& input::placeholder": {
    fontFamily:"Poppins",
    fontSize:"20px",
    fontStyle:"normal",
    fontWeight:400,
    lineHeight:"24px",
    "@media(max-width: 650px)":{
    fontSize:"12px !important",
    }
  },


  "& .MuiOutlinedInput-input": {
    width: "100%",
    padding: "10px 8px",
    borderRadius:"8px",

    "@media (max-width: 550px)": {
      width: "100%",
      height:"42px"
    },
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px",
    borderColor: "#DCE1E4",
  },

  "& .MuiSelect-outlined.MuiSelect-outlined": {
    textAlign: "left",
    color: "black !important",
  },

  '& input[type="number"]': {
    "-moz-appearance": "textfield",

    "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },

  "& .MuiFormHelperText-contained": {
    marginLeft: "4px",
    marginRight: "14px",
  },
});

export const EyeOffIcon = styled("img")({

})

export const CustomIconButton = styled(IconButton)({
  color:"#A6B1B7",
  marginRight:"-18px !important",
})



export const AgreeText = styled(Typography)({
color:"#A6B1B7",
fontFamily:"Inter",
fontSize:"14px",
fontStyle:"normal",
fontWeight:400,
lineHeight:"24px",
marginTop:"10px",
marginBottom:"85.5px",
"@media (max-width: 550px)":{
  margin:"30px 25px 50px 25px",
 }
})

export const AgreeTextUnderline = styled("span")({
  color:"#A6B1B7",
fontFamily:"Inter",
fontSize:"14px",
fontStyle:"normal",
fontWeight:500,
lineHeight:"24px",
textDecorationLine:"underline",
padding:"0px 5px 0px 5px",
"&:hover":{
  cursor:"pointer",
},
"@media (max-width: 550px)":{
  padding:"0px 2px 0px 2px",
 }
})

export const LoginButton = styled(Button)({
marginTop:"85.5px",
padding:"10px 16px",
borderRadius:"8px",
background:"linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 100%)",
marginBottom:"20px",
fontFamily:"Poppins",
color:"#FFF",
fontSize:"16px",
fontStyle:"normal",
fontWeight:500,
lineHeight:"24px",
textTransform:"none",
display:"flex",
justifyContent:"center",
alignItems:"center",
"&:hover": {
  cursor:"pointer"
},
"@media (max-width: 550px)":{
  paddingLeft:"30px",
  width:"90%",
  margin:"auto"
 },
 "@media (max-width: 420px)":{
  width:"89%",
 }
})

export const ForgetPasswordText = styled(Typography)({
color:"#4FC3FD",
fontFamily:"Poppins",
fontSize:"16px",
fontStyle:"normal",
fontWeight:500,
lineHeight:"normal",
textAlign:"center",
marginBottom:"299px",
cursor:"pointer",
"@media (max-width: 550px)": {
  margin:"15px 25px 50px 25px",
}
});

export const LoginAndErrorMainBox = styled(Box)({
  width:"100%",
  "@media (max-width: 550px)": {
  display:"flex",
  flexDirection:"column-reverse",
  width:"100%",
  }
});

export const MobileLoginImageBox = styled(Box)({
  "@media (max-width: 2550px)": {
    display:"none"
  },
  "@media (max-width: 550px)": {
    display:"block"
  }
});

export const MobileLoginImageStyle = styled("img")({
  width:"100%",
  "@media (max-width: 450px)": {
    marginTop:"10px"
  }
});

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
      <BackgroundImage>
        
        <PasswordSecurityModal  openSecModal={this.state.openSecModal} 
        size={this.state.screenSize}
        handleOpenChangePassModal={this.handleOpenChangePassModal} 
        handleSecurityModalClose={this.handleSecurityModalClose}/>
        <ChangePasswordModal 
        size={this.state.screenSize}
        openChangePassModal={this.state.openChangePassModal}
        newPassError={this.state.newPassError}
        conNewPassError={this.state.conNewPassError}
        handleChangePassModalClose={this.handleChangePassModalClose}
        setNewPassword={this.setNewPassword}
        handleClickShowNewPass={this.handleClickShowNewPass}
        enableNewPassField={this.state.enableNewPassField}
        setConfNewPassword={this.setConfNewPassword}
        handleClickShowConPass={this.handleClickShowConPass}
        newPass={this.state.newPass}
        enableConPassField={this.state.enableConPassField}
        conNewPass={this.state.conNewPass}
        handleConfirmSubmit={this.handleConfirmSubmit}
        changePassErrorText={this.state.changePassErrorText}
        changePassResponse={this.state.changePassResponse}
        capitalLetterCheck={this.state.capitalLetterCheck}
        lowercaseCheck={this.state.lowercaseCheck}
        atleastOneNumberCheck={this.state.atleastOneNumberCheck}
        minLengthCheck={this.state.minLengthCheck}
        checkIcon={this.state.checkIcon}
          />
         <LogoImageBox>
         <Logo />
         </LogoImageBox>
        <MainContainer>        
          <MainSection>
            <LoginAndErrorMainBox>
            <LabelTitle>
              Log In
            </LabelTitle>
            {this.state.errorLoginResponse ? (
          <ErrorLoginAlert>
            <ErrorLoginText>
             {this.state.errorBoxLoginText} 
            </ErrorLoginText>
          </ErrorLoginAlert>
        ):null }
            </LoginAndErrorMainBox>

            <LoginBox>
              <FieldBox>              
                  <LabelText>
                   Email                   
                  </LabelText>
                  <TextFieldCustom
                    id="outlined-basic"
                    data-test-id = "txtInputEmail"
                    name={"email"}
                    placeholder={"email@email.com"}
                    variant="outlined"
                    type="text"
                    value={this.state.email}
                    onChange={(e)=>this.setEmail(e.target.value)}
                    error={this.state.emailError}
                  />
              </FieldBox>
              <FieldBox>
                  <LabelText>
                   Password                   
                  </LabelText>
                  <TextFieldCustom
                    id="outlined-basic"
                    data-test-id = "txtInputPassword"
                    name={"password"}
                    placeholder={"*********"}
                    variant="outlined"
                    type={this.state.enablePasswordField ? "password" : "text"}
                    value={this.state.password}
                    onChange={(e)=>this.setPassword(e.target.value)}
                    error={this.state.passwordError}
                    InputProps={
                      {
                        endAdornment: (
                          <InputAdornment position="end">
                            <CustomIconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              edge="end" 
                            >
                              {this.state.enablePasswordField ? <EyeOffIcon src={eyeOff} /> : <Visibility />}
                            </CustomIconButton>
                          </InputAdornment>
                        )
                      }
                    }
                  />
                 
              </FieldBox>
              <AgreeText>By proceeding you agree to<> </>
              <AgreeTextUnderline onClick={this.handleNavigatePrivacyPolicy}>Privacy Policy </AgreeTextUnderline>
                and <AgreeTextUnderline data-test-id="termsConditions" onClick={this.handleNavigateTermsConditions}>Terms of Use</AgreeTextUnderline>
                </AgreeText>                         
                <LoginButton
                  data-test-id={"btnEmailLogIn"}
                  variant="contained"
                  fullWidth
                  onClick={()=>this.handleLoginButton()}
                >
                  Log In
                </LoginButton>
              
              <ForgetPasswordText data-test-id="btnForgotPassword" onClick={this.handleNavigateForgotPassword}>
              Forgot Password? Reset Here
              </ForgetPasswordText>
              <MobileLoginImageBox>
                <MobileLoginImageStyle src={loginBGImageMobile}/>
              </MobileLoginImageBox>
            </LoginBox>
          </MainSection>
        </MainContainer>
      </BackgroundImage>
       // Customizable Area End
    );
  }
}
