// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ChangeEvent, ChangeEventHandler, FocusEventHandler } from "react";
import { sendRequest } from "../../forgot-password/src/utils";
import { Message } from "../../../framework/src/Message";
import { createImage, getOrDefault } from "./utils";
import { FormProps } from "./AccountDetailsSection.web";
import { unchecked, checked, crossIcon } from "./assets";
import storage from "../../../framework/src/StorageProvider.web";

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
}

interface S {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  phoneCode: string;
  company: string;
  error: string;
  profileImage: null | File;
  account: UserAccount | null;
  showSuccessMessage: boolean;
  isFetching: boolean;
  openLogoutModal: boolean;
  openDeleteModal: boolean;
  openLogoutPopup: boolean;
  screenSize: number;
  openSecModal: boolean;
  currentPass: string;
  newPass: string;
  confirmPass: string;
  currentPassError: boolean;
  newPassError: boolean;
  conPassError: boolean;
  enableNewPassField: boolean;
  enableCurrentPassField: boolean;
  enableConPassField: boolean;
  toLowerCase: boolean;
  toUpperCase: boolean;
  toNumber: boolean;
  toCharacter: boolean;
  continuePopup: boolean;
  validationError: string;
  openPhotoAfjustModal: boolean;
}

interface SS {}

interface UserAccount {
  first_name: string;
  last_name: string;
  email: string;
  country_code: null | string;
  phone_number: null | string;
  full_phone_number: null | string;
  company_name: null | string;
  photo?: null | any;
}

export interface ApidataResponseMock {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

export interface ValidResponseType {
  data: object;
}

export interface InvalidResponseType {
  errors: Array<UserPasswordError>;
}

export interface UserPasswordError {
  password: string;
}

export interface UserPassword {
  meta: {
    message: string;
  };
}

export interface APIPayloadType {
  contentType: string;
  method: string;
  endPoint: string;
}

export interface DeleteUserProfile {
  data: {
  message: "Your account was successfully deleted";
  }
  
}

const config = require("./config");
const MAX_MB = 5;

const validFiles = ["png", "jpeg", "heic", "jpg"];

export default class AccountController extends BlockComponent<Props, S, SS> {
  getUserCallId = "";
  updatedUserCallId = "";
  deleteUserAccountApiCallId = "";
  updatePasswordApiCallId = "";
  deleteProfileImageCallId = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
    ];

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      phoneCode: "",
      company: "",
      error: "",
      profileImage: null,
      account: {
        first_name: "",
        last_name: "",
        email: "",
        country_code: null,
        phone_number: null,
        full_phone_number: null,
        company_name: null,
      },
      showSuccessMessage: false,
      isFetching: false,
      openLogoutModal: false,
      openDeleteModal: false,
      openLogoutPopup: false,
      screenSize: window.innerWidth,
      openSecModal: false,
      currentPass: "",
      newPass: "",
      confirmPass: "",
      currentPassError: false,
      newPassError: false,
      conPassError: false,
      enableConPassField: true,
      enableNewPassField: true,
      enableCurrentPassField: true,
      toLowerCase: false,
      toUpperCase: false,
      toNumber: false,
      toCharacter: false,
      continuePopup: false,
      validationError: "",
      openPhotoAfjustModal: false,
    };
  }

  async componentDidMount(): Promise<void> {
    if(window.location.pathname === "/profile/logout"){
      this.setState({
        openLogoutPopup: true,
      });
    }
    const token = await storage.get("auth-token");
    const userId = await storage.get("user-id");
    if (!token && !userId) {
      this.props.navigation.navigate("EmailAccountLoginBlock");
      return;
    }
    sendRequest(
      `/account_block/accounts/${userId}`,
      "GET",
      (id) => (this.getUserCallId = id),
      null,
      {
        token,
      }
    );
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("storage", async () => {
      const fullName: string = await storage.get("user-name");

      if (this.fullName !== fullName) {
        const [firstName, lastName] = fullName.split("_");
        this.setState({
          firstName,
          lastName,
        });
      }
    });
   
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ) {
    if (this.state.error && this.state.showSuccessMessage) {
      this.setState({
        showSuccessMessage: false,
      });
    }
  }

  async componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    if (
      message.id === getName(MessageEnum.RestAPIResponceMessage) &&
      this.getUserCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const success = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!success || !success.data) {
        this.props.navigation.navigate("EmailAccountLoginBlock");
        return;
      }

      const userData: UserAccount = success.data.attributes;
  
      if (userData.photo && userData.photo.url) {
        try {
          const response = await fetch(userData.photo.url);
          const blob = await response.blob();
          const file = new File([blob], userData.photo.filename, {type: blob.type});
  
          this.setState({
            profileImage: file,
          });
          await storage.set(
            "user-info",
            userData.photo.url
          );
        } catch (error) {
          console.error("Error fetching image:", error);
        }
      }

      this.setState({
        account: userData,
        firstName: userData.first_name,
        lastName: userData.last_name,
        phoneCode: getOrDefault(userData.country_code, ""),
        phoneNumber: getOrDefault(userData.phone_number, ""),
        company: getOrDefault(userData.company_name, ""),
      });
    } else if (
      message.id === getName(MessageEnum.RestAPIResponceMessage) &&
      this.updatedUserCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.setState({
        isFetching: false,
      });
      const success = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!success) {
        this.setState({
          error: "An unexpected error has occured",
        });
        this.handleNotificationMessage()
        return; 
      }

      if (!success.data) {
        this.setState({
          error: success.message,
        });
        return;
      }

      this.setState({
        showSuccessMessage: true,
        phoneCode: getOrDefault(success.data.attributes.country_code, ""),
        phoneNumber: getOrDefault(success.data.attributes.phone_number, ""),
        company: getOrDefault(success.data.attributes.company_name, ""),
      });
      this.handleNotificationMessage();
      await storage.set(
        "user-name",
        success.data.attributes.first_name +
          "_" +
          success.data.attributes.last_name
      );
      await storage.set(
        "user-info",
        success.data.attributes?.photo?.url
      );
      window.dispatchEvent(new Event("storage"));
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (this.isInValidResponse(responseJson)) {
        this.apiFailCallBack(apiRequestCallId, responseJson);
      }
    }
  }

  handleNotificationMessage = () => {
    setTimeout(() => {
      this.setState({showSuccessMessage:false, error: ""})
    },[30000])
  }

  apiCall = async (data: ApidataResponseMock) => {
    let { contentType, method, endPoint, body, type } = data;
    const token = await storage.get("auth-token");
    const header = {
      "Content-Type": contentType,
      token: token,
    };

    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        type == "formData" ? body:JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  deleteUserProfile = async () => {
    const id = await storage.get("user-id");
    this.deleteUserAccountApiCallId = await this.apiCall({
      contentType: "application/json",
      method: "DELETE",
      endPoint: `account_block/accounts/${id}`,
    });
  };

  deletePhoto= async() => {
    await storage.set(
      "user-info",
       null
    );
    window.dispatchEvent(new Event("storage"));
    if (this.state.profileImage) {
      const id = await storage.get("user-id");
      this.deleteProfileImageCallId = await this.apiCall({
        contentType: "application/json",
            method: "DELETE",
            endPoint: `account_block/accounts/remove_profile_image?account_id=${id}`,
      });
      this.setState({profileImage: null})
    } else {
      return;
    }
  }

  handleResize = () => {
    this.setState({ screenSize: window.innerWidth });
  }

  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson && responseJson.data;
  };

  isInValidResponse = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors;
  };

  apiFailCallBack = (
    apiRequestCallID: string,
    responseJSON: InvalidResponseType
  ) => {
    if (apiRequestCallID === this.updatePasswordApiCallId) {
      this.setState({
        validationError: responseJSON.errors[0].password,
      });
    }
  };

  apiSuccessCallBacks = (
    apiRequestCallID: string,
    responseJson: DeleteUserProfile & UserPassword
  ) => {
    if (apiRequestCallID === this.deleteUserAccountApiCallId) {
      this.setState(
        {
          openDeleteModal: false,
        },
        () => {
          this.handleDeleteProfile();
        }
      );
    }

    if (apiRequestCallID === this.updatePasswordApiCallId) {
      this.setState(
        {
          openSecModal: false,
        },
        () => {
          this.handleContinuePopup();
        }
      );
    }
  };

  get fullName() {
    return `${this.state.firstName} ${this.state.lastName}`;
  }

  async handleSubmit(form: Partial<FormProps>) {
    this.setState({
      isFetching: true,
    });
    let formData = new FormData();
    formData.append("data[country_code]", form.countryCode ? form.countryCode.toString().replace(/[\.\-()]/g, "") : '');
    formData.append("data[phone_number]", form.phoneNumber ? form.phoneNumber.toString().replace(/[()]/g, "") : '');
    formData.append("data[company_name]", form.company ? form.company : '');
    formData.append("data[first_name]", form.firstName ? form.firstName : '');
    formData.append("data[last_name]", form.lastName ? form.lastName : '');
    if (this.state.profileImage) {
      formData.append("data[photo]", this.state.profileImage, this.state.profileImage.name);
    }
    const userId = await storage.get("user-id");

    this.updatedUserCallId = await this.apiCall({
      method: 'PUT',
      endPoint: `account_block/accounts/${userId}`,
      body: formData,
      type: 'formData',
    });
  }

  handleConfirmSubmit = async (
    eventSubmitPassword: React.FormEvent<HTMLFormElement>
  ) => {
    eventSubmitPassword.preventDefault();
    const { currentPass, newPass, confirmPass } = this.state;
    const token = await storage.get("auth-token");
    let raw = {
      data: {
        attributes: {
          token: token,
          old_password: currentPass,
          new_password: newPass,
          confirm_password: confirmPass,
        },
      },
    };

    this.updatePasswordApiCallId = await this.apiCall({
      contentType: configJSON.accountDeleteApiContentType,
      method: configJSON.patchMethod,
      endPoint: configJSON.changePasswordEndPoint,
      body: raw,
    });
  };

  handleFileUpload(e: ChangeEvent<HTMLInputElement>) {
    
    if (!e.target.files || !e.target.files.length) {
      return;
    }
    const file = e.target.files[0];
    if (this.convertBytesToMB(file.size) > MAX_MB) {
      this.setState({
        error: "Picture exceeds maximum size.",
      });
      return;
    }

    const format = file.name.split(".")[1];
    if (!validFiles.includes(format.toLowerCase())) {
      this.setState({
        error: "Invalid file format.",
      });
      return;
    }
    
    this.setState({
      profileImage: file,
      error: ""
    });
    if(window.innerWidth < 901){
      this.handleAsyncUpload(file);
    }
  }

  async handleAsyncUpload(file: File){
    const userId = await storage.get("user-id");

    let formData = new FormData();
    formData.append("data[photo]", file, file.name);

    this.updatedUserCallId = await this.apiCall({
      method: 'PUT',
      endPoint: `account_block/accounts/${userId}`,
      body: formData,
      type: 'formData',
    });
  }

  convertBytesToMB(bytes: number) {
    return bytes / 1000000;
  }

  get uploadedImageSrc() {
    if (!this.state.profileImage) {
      return "";
    }
    return URL.createObjectURL(this.state.profileImage);
  }
 
  get showMessage() {
    const { openDeleteModal, openLogoutPopup, openSecModal } = this.state;
    return !openDeleteModal && !openLogoutPopup && !openSecModal;
  }

  get currentErrorMessage() {
    const { error } = this.state;
    if (this.showMessage && error) {
      return error;
    }
    return "";
  }

  createBlurHandler = (
    fieldName: string,
    handleBlur: FocusEventHandler<any>,
    validateField: (fields: string) => void,
    errors: any
  ) => {
    return (e: React.FocusEvent<any>) => {
      handleBlur(e);
      validateField(fieldName);
      if (errors[fieldName]) {
        this.setState({
          error: errors[fieldName],
        });
      }
    };
  };

  createChangeHandler = (changeHandler: ChangeEventHandler<any>) => {
    return (e: React.FocusEvent<any>) => {
      changeHandler(e);
      this.setState({
        error: "",
      });
    };
  };

  handleLogoutModal = () => {
    this.setState({
      openLogoutModal: !this.state.openLogoutModal,
    });
  };
 
  handleDeleteModal = () => {
    this.setState({
      openDeleteModal: !this.state.openDeleteModal,
    });
  };

  handleLogoutProfile = async () => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    await storage.clearStorage();
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLoginBlock"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(to);
  };

  handleLogoutPopup = () => {
    this.props.navigation.navigate("UserProfileAdvance2")
  };
  handleLogoutPopupCancle =()=>{
    this.props.navigation.navigate("UserProfileAdvance")
  }

  handleDeleteProfile = () => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "DeleteConfirmation"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(to);
  };

  handleChangePassModalClose = () => {
    this.setState({ 
      openSecModal: !this.state.openSecModal,
      currentPass:"",
      newPass: "",
      confirmPass: "",
      validationError: "",
      enableCurrentPassField: true,
      enableNewPassField: true,
      enableConPassField: true   
    });
  };

  setCurrentPassword = (text: string) => {
    this.setState({
      currentPass: text,
      currentPassError: false,
    });
  };

  handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const new_pass = event.target?.value;

    if (new_pass === this.state.newPass) {
      return;
    }

   if(this.state.newPass.length < 8){
        this.setState({
          newPass: new_pass,
          validationError: "Your password does not meet all the requirements. Must have minimum character length is 8 characters"
        });
      }else{
        this.setState({
          newPass: new_pass,
          validationError: ""
        });
      }
    this.setState({ newPass: new_pass, newPassError: false }, () => {
      this.validatePassword();
    });
  };

  validatePassword = () => {
    const { newPass } = this.state;

    let lowerCase = configJSON.lowerLetterRegax;
    let upperCase = configJSON.upperRegax;
    let numbers = configJSON.digitRegax;

    this.setState({
      toLowerCase: false,
      toUpperCase: false,
      toNumber: false,
      toCharacter: false,
    });

    if (newPass?.match(lowerCase)) {
      this.setState({ toLowerCase: true });
    }
    if (newPass?.match(upperCase)) {
      this.setState({ toUpperCase: true });
    }
    if (newPass?.match(numbers)) {
      this.setState({ toNumber: true });
    }
    if (newPass?.length >= 8) {
      this.setState({ toCharacter: true });
    }
  };

  setConfNewPassword = (eventConfirmPassword: string) => {
    if (this.state.newPass !== eventConfirmPassword) {
      this.setState({
        confirmPass: eventConfirmPassword,
        conPassError: true,
      });
    }
    this.setState({
      confirmPass: eventConfirmPassword,
      conPassError: false,
    });
  };

  handleClickShowNewPass = () => {
    this.setState({
      enableNewPassField: !this.state.enableNewPassField,
    });
  };

  handleClickShowConPass = () => {
    this.setState({
      enableConPassField: !this.state.enableConPassField,
    });
  };

  handleClickShowCurrentPass = () => {
    this.setState({
      enableCurrentPassField: !this.state.enableCurrentPassField,
    });
  };

  handleContinuePopup = () => {
    this.setState({
      continuePopup: !this.state.continuePopup,
    });
  };

  isIcon = (newPass: string, toCase: boolean): string => {
    let iconSource: string;

    if (newPass === "") {
      iconSource = unchecked;
    } else if (toCase) {
      iconSource = checked;
    } else {
      iconSource = crossIcon;
    }
    return iconSource;
  };

  togglePhotoAdjustModal = () => {
    if (!this.state.profileImage) {
      return;
    }
    this.setState({
      openPhotoAfjustModal: !this.state.openPhotoAfjustModal,
    })
  }

  handleImageAdjust = async (x: number, y: number, height: number, width: number) => {
    if (!this.state.profileImage) {
      return;
    }
    const newImage = await this.createAdjustedImage(x, y, height, width);
    
    this.setState({
      profileImage: newImage,
    });
  }

  private createAdjustedImage = async (x: number, y: number, height: number, width: number): Promise<File | null> => {
    if (!this.state.profileImage) {
      return null;
    }
    const image = await createImage(this.uploadedImageSrc);
    
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    if (!context) {
      return null;
    }

    context.canvas.width = width;
    context.canvas.height = height;
  
    context.drawImage(image, x, y, width, height, 0, 0, width, height);

    const data = context.getImageData(0, 0, width, height);

    context.canvas.width = width;
    context.canvas.height = height;
    context.putImageData(data, 0, 0);

    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        if (!file) {
          return null;
        }
        resolve(this.blobToFile(file, canvas.toDataURL()));
      });
    });
  }

  private blobToFile = (blob: Blob, name: string) => {
    const timestamp = new Date().getTime();
    const newName = name.replace(/(\.[\w\d_-]+)$/i, `-${timestamp}$1`);

    return new File([blob], newName, {
      type: blob.type,
    })
  }
  handleprofileClicks=()=>{
    this.props.navigation.navigate("UserProfileAdvance");
  };

  goBack = () => {
    if(window.location.pathname === "/profile/logout"){
    this.props.navigation.navigate("UserProfileAdvance")
  }
  this.setState({openDeleteModal:false, openLogoutPopup:false});
  };
}
// Customizable Area End
