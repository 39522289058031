import { styled } from "@material-ui/core";
import React, { ChangeEventHandler } from "react";
import { TextFieldCustom } from "../../email-account-login/src/EmailAccountLoginBlock.web";
import { VoiceInputButton } from "./VoiceInputButton";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import CustomFieldTextController, { Props } from "./CustomFieldTextController";



export const CustomTextInput = styled(TextFieldCustom)({
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":{
    borderColor:'#F87171'
  },
  "@media(max-width: 650px)": {
    width: "100% !important"
  },
  "& .MuiInputBase-root, .Mui-disabled": {
    minWidth: "160px",
  "@media(max-width: 650px)": {
    height: "44px"
  },
    "& input": {
      "&::-webkit-input-placeholder, >select": {
        padding: "15px 8px",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "normal",
        color: "darkgray",
        whiteSpace: "nowrap",
        fontFamily: "Poppins",
        "@media(max-width: 650px)": {
          fontSize: "12px",
        }
      },
      "&:focused,:hover, .Mui-focused,": {
        border: "1px solid rgba(229, 229, 229, 1)",
      },
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
    },
    "& input, select": {
      padding: "16px 15px",
      "&::placeholder": {
        padding: 0,
        margin: 0,
      },
    },
  },
});

export class CustomFieldText extends CustomFieldTextController {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render(): React.ReactNode {
    const {
      inputValue,
      hasError,
      handleBlur,
      handleChange,
      handleMouseDown,
      handleMouseRelease,
      id,
      is_speech_to_text_required,
      type,
      disabled,
      isAudioInput,
    } = this.props;
    return (
      <CustomTextInput
        value={inputValue}
        error={hasError}
        style={{ ...(isAudioInput && { width: "unset" }) }}
        disabled={disabled}
        onKeyDown={this.handleNumericKeyPress}
        onBlur={handleBlur}
        type={type}
        onChange={handleChange}
        placeholder={type === "numeric" ? "Enter digit here" : "Enter text here"}
        data-test-id={"customField-" + id}
        variant="outlined"
        InputProps={{
          ...(is_speech_to_text_required &&
            type === "text" && {
            endAdornment: <VoiceInputButton
             handleMouseDown={handleMouseDown}
             handleMouseRelease={handleMouseRelease}
              />,
            }),
        }}
      ></CustomTextInput>
    );
  }
}
