import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Cfspeechtotext5Controller, {
  Props,
  configJSON,
} from "./Cfspeechtotext5Controller";

export default class Cfspeechtotext5 extends Cfspeechtotext5Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Box 
        data-test-id={"startID"}
        onClick={this.handleSpeechStart} 
        style={webStyle.startSpeech}>
          {configJSON.startText}
        </Box>
        <Box 
        data-test-id={"stopID"}
        onClick={this.handleStopSpeech} 
        style={webStyle.stopSpeech}>
          {configJSON.stopText}
        </Box>
        <Typography>{configJSON.resultText} {this.state.convertedText}</Typography>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  startSpeech:{
    background: "green", 
    height: "100px", 
    width: "200px"
  },
  stopSpeech:{
    background: "red", 
    height: "100px", 
    width: "200px"
  }
};
// Customizable Area End
