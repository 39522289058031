export const createSurveyImg = require("../assets/ceateSurvey.svg");
export const searchIcon = require("../assets/searchIcon.svg");
export const calenderIcon = require("../assets/calender_icon.svg");
export const noDataError = require("../assets/no_data_error.svg");
export const createSurveySmallBtn = require("../assets/create_survey_small_btn.svg");
export const closeIcon = require("../assets/close.svg");
export const deleteSurveyIcon = require("../assets/deleteSurveyIcon.svg");
export const downloadSurveyIcon = require("../assets/downloadSurveyIcon.svg");
export const editSurveyIcon = require("../assets/editSurveyIcon.svg");
export const shareSurveyIcon = require("../assets/shareSurveyIcon.svg");
export const paperSurveyIcon = require("../assets/paperSurveyIcon.svg");
export const popupCrossIcon = require("../assets/popupCrossIcon.svg");
export const alertLogo = require("../assets/alertLogo.svg");
export const confirmationIcon = require("../assets/confirmationIcon.svg");
export const logoutModalIcon = require("../assets/email.png");
export const infoIcon = require("../assets/infoIcon.png");
export const copyIcon = require("../assets/copy.svg");
export const right = require("../assets/right.png");
