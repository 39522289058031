export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const logo = require("../assets/logo.svg");
export const backgroundImg = require("../assets/backgroundimage.svg");
export const eyeOff = require("../assets/eye_off.svg");
export const closeModalIcon = require("../assets/close_modal_icon.svg");
export const imageSecurity = require("../assets/image_security.svg");
export const keyimage = require("../assets/image_iconkey.svg");
export const greenCheckCircle = require("../assets/greenCheckCircle.svg");
export const redCheckCircle = require("../assets/redCheckCircle.svg");
export const loginBGImageMobile = require("../assets/loginBGImage.png");