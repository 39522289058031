import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Box, Typography, styled } from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import ProfileImageAdjust from "./ProfileImageAdjust";
import Back from "@material-ui/icons/ArrowBackIosRounded";

export interface Props {
  open: boolean;
  onClose: () => void;
  image: string;
  onAdjust: (
    x: number,
    y: number,
    height: number,
    width: number
  ) => Promise<void>;
}

const ImageAjustDialog = styled(Dialog)({
  "& .MuiDialog-paperWidthSm": {
    width: "80%",
    maxWidth: "1370px",
    overflow: "visible",
  },
});

const ImageAjustMobileDialog = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  zIndex: 2,
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "8px",
  width: '100%',
  marginTop: '20px',
  "@media(max-width: 550px)": {
    boxSizing: "border-box",
    marginTop: "32px"
  },
  "@media(max-width: 435px)": {
    marginTop: '65px',
  }
});

const PopUpContent = styled(Box)({
  padding: "45px 140px 55px 140px",
  "@media(max-width: 900px)": {
    padding: 0
  }
});

const BackButton = styled(Back)({
  "@media(max-width: 900px)": {
    display: "block",
    width: "9px",
    height: "15px",
    marginRight: "6px",
    transform: "scale(1.8)",
    cursor: "pointer"
  },
});
const PopUpText = styled(Typography)({
  fontFamily: "Poppins, Inter, sans-serif",
  fontWeight: 600,
  fontSize: "20px",
  lineHeight: "30px",
  color: "rgba(24, 24, 24, 1)",
  marginBottom: "35px",
  "@media(max-width: 900px)": {
  fontWeight: 500,
  fontSize: "18px",
  marginBottom: "0",
  cursor: "pointer"
  }
});

const CloseButton = styled(CloseRoundedIcon)({
  cursor: "pointer",
  position: "absolute",
  top: 45,
  right: 45,
  fill: "rgba(66, 76, 83, 1)",
});

export default class ProfileImageAdjustPopUp extends BlockComponent<
  Props,
  {},
  {}
> {
  render() {
    const { open, onClose, image, onAdjust } = this.props;
    return (
      <>
        {
          window.innerWidth < 900 ?
            open && <ImageAjustMobileDialog data-test-id={"mobilePopup"}>
              <PopUpContent data-test-id={"imageAdjustMobilePopUp"}>
                <div onClick={onClose} style={{ display: 'flex', alignItems: 'center', marginBottom: '40px', width:"fit-content" }}>
                  <BackButton />
                  <PopUpText>Adjust position of photo</PopUpText>
                </div>
                <ProfileImageAdjust
                  imageSrc={image}
                  onClose={onClose}
                  onFileUpload={onAdjust}
                />
              </PopUpContent>
            </ImageAjustMobileDialog>

            :
            <ImageAjustDialog
              open={open}
              onClose={onClose}
            >
              <PopUpContent data-test-id={"imageAdjustPopUp"}>
                <PopUpText>Adjust position of photo</PopUpText>
                <CloseButton onClick={onClose} />
                <ProfileImageAdjust
                  imageSrc={image}
                  onClose={onClose}
                  onFileUpload={onAdjust}
                />
              </PopUpContent>
            </ImageAjustDialog>
        }
      </>
    );
  }
}
