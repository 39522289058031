import { Box, Typography, styled } from "@material-ui/core";
import React, { FC } from "react";
import { FormProps } from "./AccountDetailsSection.web";
import {
  companyIcon,
  emailIcon,
  logoutIcon,
  nameIcon,
  passwordIcon,
  phoneIcon
} from "./assets";
import { MobileSelectButton } from "./MobileSelectButton";
import { DeleteOutline } from "@material-ui/icons";
import { configJSON } from "./AccountController.web";
import NavigateNextRounded from "@material-ui/icons/NavigateNextRounded";

interface Props extends FormProps {
  navigation: any;
  onDeleteAccountClick?: () => void;
  onLogoutClick?: () => void;
  handleChangePassModalClose: () => void;
}

const LogoutButtonMainBox = styled(Box)({
  height: "56px",
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  borderRadius: "8px",
  borderBottom: "1px solid var(--Scio-Services-Neutral-100---Neutral, #DCE1E4)",
  padding: "0px 10px 0px 20px"
});

const LogoutImage = styled("img")({
  marginRight: "30px"
});

const LogoutButtonText = styled(Typography)({
  color: "var(--Scio-Services-Neutral-700---Neutral, #424C53)",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "15px"
});

const LogouDeleteMobileMainBox = styled(Box)({
  marginTop: "30px",
  display: "flex",
  borderColor: "red",
  flexDirection: "column",
  height: "100px",
  justifyContent: "space-between"
});

const DeleteButtonBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  cursor: "pointer",
  color: "red",
  justifyContent: "center"
});

const DetailsCardMainBox = styled(Box)({
  "@media(max-width: 550px)": {
    margin: "0px 10px 20px 10px"
  }
});

const LogoutImageTitleBox = styled(Box)({
  display: "flex"
});

export const MobileMainSection: FC<Props> = ({
  navigation,
  lastName,
  firstName,
  email,
  company,
  phoneNumber,
  countryCode,
  onDeleteAccountClick,
  onLogoutClick
}) => {
  const links = [
    {
      title: "Name",
      value: `${firstName} ${lastName}`,
      link: "ProfileName",
      iconSrc: nameIcon
    },
    {
      title: "Phone",
      value: `${countryCode}-${phoneNumber}`,
      link: "ProfilePhone",
      iconSrc: phoneIcon
    },
    {
      title: "Company",
      value: `${company}`,
      link: "ProfileCompany",
      iconSrc: companyIcon
    },
    {
      title: "Email",
      value: `${email}`,
      link: "",
      iconSrc: emailIcon,
      disabled: true
    },
    {
      title: "Password",
      value: "********",
      link: "ChangePasswordMobileScreen",
      iconSrc: passwordIcon,
      disabled: false
    }
  ];

  return (
    <DetailsCardMainBox
      display={"flex"}
      marginTop={"40px"}
      flexDirection={"column"}
      style={{ gap: "15px" }}
    >
      {links.map(link => (
        <MobileSelectButton {...link} navigation={navigation} />
      ))}
      <LogouDeleteMobileMainBox>
        <LogoutButtonMainBox onClick={onLogoutClick}>
          <LogoutImageTitleBox>
            <LogoutImage src={logoutIcon} />
            <LogoutButtonText>{configJSON.logout}</LogoutButtonText>
          </LogoutImageTitleBox>
          <NavigateNextRounded />
        </LogoutButtonMainBox>
        <DeleteButtonBox onClick={onDeleteAccountClick}>
          <DeleteOutline />
          <Typography>{configJSON.deleteAccount}</Typography>
        </DeleteButtonBox>
      </LogouDeleteMobileMainBox>
    </DetailsCardMainBox>
  );
};
