Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "SaveAsPdf";
exports.labelBodyText = "SaveAsPdf Body";

exports.btnExampleTitle = "CLICK ME";

exports.saveReportFileAsPdfEndPoint = "bx_block_saveaspdf2/survey_pdf/guest_survey_pdf_details?guest_survey_id=";
exports.createDatePdf = "Date created";
exports.lastEditPdf = "Last edited";
exports.createByPdf = "Created by";
exports.ownerNamePdf = "Property Owner";
exports.downloadPdfFile = "Download Report";
exports.backButton = "Back";
// Customizable Area End