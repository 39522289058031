import React, { useState } from "react";
import { Box, styled } from "@material-ui/core";
import { MainWrapper } from "../../forgot-password/src/ForgotPasswordWrapper";
import Sidebar from "../../navigationmenu/src/SidebarNavigationMenu.web";
import Header from "./Header.web";

export interface Props {
  navigation: any;
  children: React.ReactChild;
  profileImg?: any;
  account?: {
    id: string | null;
    name: string;
  };
  handleLogoutClick?: () => void;
  handleProfileNavigate?: () => void;
}

const ContentMainWrapper = styled(MainWrapper)({
  paddingLeft: 0,
  maxWidth:"none",
  "@media(max-width: 900px)": {
    padding: 0
  },
  "@media(max-width: 550px)": {
    padding: "0px"
  }
});

export const BodyWrapper = styled(Box)({
  marginTop: "-245px",
  marginLeft: "320px",

  "@media(max-width: 900px)": {
    marginTop: "0px",
    marginLeft: "0"
  },
  "@media(max-width: 550px)": {
    marginTop: "-25px",
    height: "100%",
    width: "100%"
  }
});

const ContentBody = styled(Box)({
  margin: "190px 0px 0px 30px",
  "@media(max-width: 900px)": {
    margin: "100px 100px 100px 100px"
  },
  "@media(max-width: 435px)": {
    margin: "60px 100px 30px 100px"
  }
});

export default function ContentWrapper({ children, ...props }: Props) {
  const [openMobileSideBar, setOpenMobileSideBar] = useState(
    window.innerWidth > 900
  );
  const handleDrowerToggle = () => setOpenMobileSideBar(!openMobileSideBar);
  return (
    <Box>
      <Header
        username={localStorage.getItem("user-name")}
        propfileId={localStorage.getItem("user-id")}
        profileImg={localStorage.getItem("user-info") || ""}
        onMenuClick={handleDrowerToggle}
        onProfileClick={props.handleProfileNavigate}
      />
      <ContentBody>
        <Sidebar
        data-test-id="sidebarId"
          mobileOpen={openMobileSideBar}
          handleClose={handleDrowerToggle}
          handleLogoutClick={() => {
            props.handleLogoutClick && props.handleLogoutClick();
            window.innerWidth < 900 && handleDrowerToggle();
          }}
          navigation={props.navigation}
        />
      </ContentBody>
      <ContentMainWrapper>
        <BodyWrapper>{children}</BodyWrapper>
      </ContentMainWrapper>
    </Box>
  );
}
