import React from "react";
// Customizable Area Start
import {
  keyimage,
  closeModalIcon,
  eyeOff
} from "../../email-account-login/src/assets";
import Visibility from "@material-ui/icons/Visibility";
import Back from "@material-ui/icons/ArrowBackIosRounded";

import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  styled,
  TextField
} from "@material-ui/core";

const CustomDialog = styled(Box)({
  top: "50%",
  position: "absolute",
  zIndex: 2,
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "8px",
  backgroundColor: "white",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
  padding: "0px 20px 20px 20px",
  flexDirection: "column",
  display: "flex",
  height: "750",
  width: "60%",
  "& .MuiDialog-paperFullWidth": {
    backgroundColor: "#FFF",
    padding: "0px 47px 0px 21px",

    borderRadius: "8px",
    maxWidth: "814px",
    width: "100%",
    boxSizing: "border-box",
    "@media (max-width: 600px)": {
      height: "unset",
      maxHeight: "unset",
      padding: "20px"
    },
    "@media (max-width: 450px)": {
      padding: "10px"
    }
  },
  "@media (max-width: 510px)": {
    width: "100%",
    padding: "30px",
    borderradius: "0",
    height: "707px",
    display: "flex",
    justifyContent: "center",
    top: "46%",
    borderRadius: "0px"
  }
});

const ErrorBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "68px",
  width: "100%",
  "@media (max-width: 900px)": {
    justifyContent: "flex-start"
  }
});

const ErrorLoginAlert = styled(Box)({
  minWidth: "80%",
  padding: "12px 10px",
  borderRadius: "4px",
  borderTop: "4px solid #DC2626",
  backgroundColor: "#FEE2E2",
  "@media (max-width: 960px)": {
    minWidth: "77%"
  },
  "@media (max-width: 500px)": {
    minWidth: "75%"
  },
  "@media (max-width: 375px)": {
    minWidth: "50%"
  },
  "@media (max-width: 510px)": {
    minWidth: "100%"
  }
});

const ErrorLoginText = styled(Typography)({
  color: "#DC2626",
  fontFamily: "Poppins",
  fontSize: "12px !important",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "18px"
});

const CloseDiv = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  "@media (max-width: 510px)": {
    display: "none"
  }
});

const CustomCloseIcon = styled("img")({
  cursor: "pointer",
  position: "absolute",
  top: "21px"
});

const MiddleBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between"
});

const Imagediv = styled("div")({
  justifyContent: "center",
  textAlign: "center",
  "@media (max-width: 510px)": {
    display: "none"
  }
});

const Maindiv = styled("div")({
  flexShrink: 1,
  padding: "30px 90px 0px 90px",
  boxSizing: "border-box",
  "@media (max-width: 1199px)": {
    padding: "10px 50px 0px 50px"
  },
  "@media (max-width: 900px)": {
    padding: "50px 20px 0px 20px"
  },
  "@media (max-width: 510px)": {
    padding: "0px 20px 0px 20px"
  }
});

const Buttondiv = styled("div")({
  marginTop: "50px",
  marginBottom: "60px",
  display: "flex",
  justifyContent: "center",
  gap: "10px",
  "@media (max-width: 1250px)": {
    marginTop: "30px"
  },
  "@media (max-width: 992px)": {
    marginTop: "30px"
  },
  "@media (max-width: 575px)": {
    marginTop: "30px"
  },
  "@media (max-width: 510px)": {
    marginTop: "150px",
    width: "100%",
    display: "flex",
    flexDirection: "column-reverse",
    gap: "20px",
    alignItems: "center",
    flexWrap: "wrap-reverse"
  }
});

const CancelButton = styled(Button)({
  flex: "1",
  width: "100%",
  height: "44px",
  padding: "10px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  flexShrink: 0,
  borderRadius: "8px",
  background: "var(--scio-services-light-blue-50-light-blue, #EBF5FE)",
  textTransform: "none",
  color: "#0090CF",
  "@media (max-width: 510px)": {
    background: "white",
    "&:hover": {
      backgroundColor: "white"
    }
  }
});
const EyeOffIcon = styled("img")({});

const ConfirmButton = styled(Button)({
  width: "100%",
  flex: "1",
  height: "44px",
  padding: "10px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  flexShrink: 0,
  borderRadius: "8px",
  background:
    "var(--scio-services-gradient, linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 100%))",
  textTransform: "none",
  color: "#FFFFFF"
});

const Inputdiv = styled("div")({
  marginTop: "30px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "8px",
  "@media (max-width: 900px)": {
    marginTop: "20px"
  }
});

const Heading = styled(Typography)({
  color: "var(--scio-services-basic-black, #181818)",
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "30px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  "@media (max-width: 450px)": {
    fontSize: "14px"
  },
  "@media (max-width: 510px)": {
    display: "none"
  }
});

const LabelText = styled(Typography)({
  color: "#424C53",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  marginRight: "0px",
  alignItems: "flex-start",
  marginBottom: "-8px",
  "@media (max-width: 450px)": {
    fontSize: "10px"
  }
});

const Textfield = styled(TextField)({
  color: "#424C53",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  width: "100%",
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px"
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px 8px"
  },
  background: "var(--scio-services-neutral-50-neutral, #F9FAFA)"
});
export const ListItem = styled("li")({
  display: "flex",
  alignItems: "center",
  marginTop: "8px",
  gap: "8px",
  color: " var(--scio-services-neutral-300-neutral, #A6B1B7)",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 300,
  lineHeight: "normal"
});

const Listul = styled("ul")({
  paddingLeft: "0px",
  marginTop: "-12px"
});
interface ChangePassModalProps {
  validationError: string;
  currentPass?: string;
  setCurrentPass(text: string): void;
  currentPassError?: boolean;
  enableCurrentPassField?: boolean;
  handleClickShowCurrentPass(): void;

  newPassError?: boolean;
  conNewPassError?: boolean;
  handleChangePassModalClose(): void;
  setNewPassword(event: React.ChangeEvent<HTMLInputElement>): void;
  handleClickShowNewPass(): void;
  enableNewPassField?: boolean;

  setConfNewPassword(text: string): void;
  handleClickShowConPass(): void;
  newPass: string;
  enableConPassField?: boolean;
  conNewPass?: string;
  handleConfirmSubmit(
    eventConfirmSubmit: React.FormEvent<HTMLFormElement>
  ): void;
  toLowerCase: boolean;
  toUpperCase: boolean;
  toNumber: boolean;
  toCharacter: boolean;
  changepasswordHeading: string;
  currentPasswordInput: string;
  newPasswordInput: string;
  confirmPasswordInput: string;
  belowValidation: string;
  cancel: string;
  confirm: string;
  character: string;
  numbers: string;
  lowerLetter: string;
  capitalLetter: string;
  isIcon: (newPass: string, toCase: boolean) => string;
}

const BackHeaderDiv = styled("div")({
  display: "none",
  "@media(max-width:510px)": {
    height: "50px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "6px",
    marginTop: "20px",
    marginLeft: "12px"
  }
});

const UserSettingHeaderText = styled(Typography)({
  fontSize: "14px",
  color: "black",
  fontWeight: 500
});

const BackArrowButton = styled(Back)({
  fontSize: "16px"
});

const PasswordHeaderText = styled(Typography)({
  fontSize: "12px",
  color: "black"
});

const SaveChangesButtonLable = styled(Typography)({
  display: "none",
  "@media(max-width:510px)": {
    fontSize: "14px",
    color: "white",
    display: "block"
  }
});

const ConfirmButtonLabel = styled(Typography)({
  display: "block",
  "@media(max-width:510px)": {
    fontSize: "14px",
    color: "white",
    display: "none"
  }
});

const DissmissChangesButtonLable = styled(Typography)({
  display: "none",
  "@media(max-width:510px)": {
    fontSize: "14px",
    color: "bluew",
    display: "block"
  }
});

const CancelButtonLabel = styled(Typography)({
  display: "block",
  "@media(max-width:510px)": {
    fontSize: "14px",
    color: "white",
    display: "none"
  }
});

export default class ChangePabsswordModal extends React.Component<
  ChangePassModalProps,
  any
> {
  renderOldPassword = () => {
    const {
      currentPass,
      enableCurrentPassField,
      setCurrentPass,
      currentPassError,
      currentPasswordInput,
      handleClickShowCurrentPass
    } = this.props;

    return (
      <>
        <Inputdiv>
          <LabelText>{currentPasswordInput}</LabelText>
          <Textfield
            variant="outlined"
            type={enableCurrentPassField ? "password" : "text"}
            value={currentPass}
            placeholder="*********"
            onChange={e => setCurrentPass(e.target.value)}
            error={currentPassError}
            data-test-id="current-password-input"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowCurrentPass}
                    edge="end"
                  >
                    {enableCurrentPassField ? (
                      <EyeOffIcon src={eyeOff} />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Inputdiv>
      </>
    );
  };

  renderNewPassword = () => {
    const {
      newPassError,
      setNewPassword,
      handleClickShowNewPass,
      enableNewPassField,
      newPass,
      newPasswordInput
    } = this.props;
    return (
      <Inputdiv>
        <LabelText>{newPasswordInput}</LabelText>
        <Textfield
          variant="outlined"
          type={enableNewPassField ? "password" : "text"}
          value={newPass}
          placeholder="*********"
          onChange={setNewPassword}
          error={newPassError}
          data-test-id="new-password-input"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowNewPass}
                  edge="end"
                >
                  {enableNewPassField ? (
                    <EyeOffIcon src={eyeOff} />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Inputdiv>
    );
  };

  renderConfirmPassword = () => {
    const {
      conNewPassError,
      setConfNewPassword,
      handleClickShowConPass,
      enableConPassField,
      conNewPass,
      confirmPasswordInput
    } = this.props;
    return (
      <Inputdiv>
        <LabelText>{confirmPasswordInput}</LabelText>
        <Textfield
          variant="outlined"
          type={enableConPassField ? "password" : "text"}
          placeholder="*********"
          value={conNewPass}
          onChange={e => setConfNewPassword(e.target.value)}
          error={conNewPassError}
          data-test-id="connew-password-input"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConPass}
                  edge="end"
                >
                  {enableConPassField ? (
                    <EyeOffIcon src={eyeOff} />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Inputdiv>
    );
  };

  renderValidation = () => {
    const {
      newPass,
      toLowerCase,
      toUpperCase,
      toNumber,
      toCharacter,
      belowValidation,
      character,
      numbers,
      lowerLetter,
      capitalLetter,
      isIcon
    } = this.props;

    return (
      <Inputdiv>
        <LabelText style={{marginBottom: 0}}>{belowValidation}</LabelText>
        <Listul>
          <ListItem>
            <img src={isIcon(newPass, toUpperCase)} alt="cross" />
            {capitalLetter}
          </ListItem>
          <ListItem>
            <img src={isIcon(newPass, toLowerCase)} alt="cross" />
            {lowerLetter}
          </ListItem>
          <ListItem>
            <img src={isIcon(newPass, toNumber)} alt="cross" />
            {numbers}
          </ListItem>
          <ListItem>
            <img src={isIcon(newPass, toCharacter)} alt="cross" />
            {character}
          </ListItem>
        </Listul>
      </Inputdiv>
    );
  };
  render() {
    const {
      validationError,
      handleChangePassModalClose,
      cancel,
      confirm,
      handleConfirmSubmit,
      changepasswordHeading
    } = this.props;
    return (
      <CustomDialog>
        <BackHeaderDiv onClick={handleChangePassModalClose}>
          <BackArrowButton />
          <UserSettingHeaderText>User Settings</UserSettingHeaderText>
          <PasswordHeaderText>Password</PasswordHeaderText>
        </BackHeaderDiv>

        {validationError != "" ? (
          <ErrorBox>
            <ErrorLoginAlert>
              <ErrorLoginText>{validationError}</ErrorLoginText>
            </ErrorLoginAlert>
          </ErrorBox>
        ) : null}

        <CloseDiv>
          <CustomCloseIcon
            src={closeModalIcon}
            onClick={handleChangePassModalClose}
          />
        </CloseDiv>
        <MiddleBox>
          <Maindiv>
            <form onSubmit={handleConfirmSubmit}>
              <Imagediv>
                <img src={keyimage} />
              </Imagediv>
              <Heading>{changepasswordHeading}</Heading>
              {this.renderOldPassword()}

              {this.renderNewPassword()}

              {this.renderConfirmPassword()}

              {this.renderValidation()}
              <Buttondiv>
                <CancelButton onClick={handleChangePassModalClose}>
                  <CancelButtonLabel>cancel</CancelButtonLabel>
                  <DissmissChangesButtonLable>
                    Dismiss Changes
                  </DissmissChangesButtonLable>
                </CancelButton>
                <ConfirmButton type="submit">
                  <ConfirmButtonLabel>confirm</ConfirmButtonLabel>
                  <SaveChangesButtonLable>Save Changes</SaveChangesButtonLable>
                </ConfirmButton>
              </Buttondiv>
            </form>
          </Maindiv>
        </MiddleBox>
      </CustomDialog>
    );
  }
}
// Customizable Area End
