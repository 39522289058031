// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";

export interface Props {
    zoom: number;
    onZoomChange: (zoom: number) => void;
}

interface S {}

interface SS {}

export default class ProfileImageAdjustSliderController extends BlockComponent<Props, S, SS> {
    constructor(props: Props) {
        super(props);
    }

    handlezoomChange = (_: React.ChangeEvent<{}>, value: number | number[]) => this.props.onZoomChange(value as number);
}
// Customizable Area End
