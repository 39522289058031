import { styled } from "@material-ui/core";
import React, { FC } from "react";
import {
  ErrorLoginAlert,
  ErrorLoginText
} from "../../email-account-login/src/EmailAccountLoginBlock.web";

interface Props {
  errorMessage: string;
  showError: boolean;
}

const ErrorWrapper = styled(ErrorLoginAlert)({
  maxWidth: "400px",
  width: "100%",
  minWidth: "0",
  padding: "10px",

  "@media(max-width: 900px)": {
    width: "100%",
    maxWidth: "unset",
    boxSizing: "border-box"
  },
  "@media(max-width: 500px)": {
    width: "100%",
    boxSizing: "border-box"
  }
});

export const ErrorMessage: FC<Props> = ({ showError, errorMessage }) => {
  return (
    <>
      {showError && (
        <ErrorWrapper textAlign={"left"}>
          <ErrorLoginText data-test-id={"errorMsg"}>
            {errorMessage}
          </ErrorLoginText>
        </ErrorWrapper>
      )}
    </>
  );
};
