import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  styled
} from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { logo } from "../../email-account-login/src/assets";
import { Menu } from "@material-ui/icons";

interface Props {
  profileImg?: string;
  username: string | null;
  propfileId: string | null;
  onMenuClick: () => void;
  onHeaderClick?: () => void;
  onProfileClick?: () => void;
}

const HeaderWrapper = styled(AppBar)({
  backgroundColor: "white",
  borderBottom: "1px",
  borderColor: "rgba(255, 255, 255, 1)",
  height: "75px",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  "@media(max-width: 435px)": {
    height: "48px",
  }
});

const AppToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "row",

  "@media(max-width: 900px)": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row"
  }
});

const Logo = styled("img")({
  width: "119px",
  height: "60px",
  margin: "0",
  "@media(max-width: 435px)": {
    height: "36px",
  }
});

const LogoButton = styled(IconButton)({
  margin: 0,
  padding: 0,
  "@media(max-width: 435px)": {
    height: "36px",
  }
});

const ProfileName = styled(Typography)({
  fontFamily: "Poppins, Inter, sans-serif",
  fontSize: "18px",
  fontWeight: 500,
  letterSpacing: "0em",
  textAlign: "center",
  color: "rgba(66, 76, 83, 1)"
});

const MenuButton = styled(IconButton)({
  display: "none",

  "@media(max-width: 900px)": {
    display: "block",
    position: "absolute",
    left: 20
  },
  "@media(max-width: 550px)": {
    left: 10
  }
});

const ProfileInfo = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  "@media(max-width: 900px)": {
    display: "none"
  }
});

const Header: FC<Props> = ({
  propfileId,
  username,
  onMenuClick,
  onHeaderClick,
  onProfileClick
}) => {
  const [img, setImg] = useState(localStorage.getItem("user-info"))
  const handleStorageChange = async() => {
    const imgUrl = await localStorage.getItem("user-info")
    setImg(imgUrl)
  };
  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [])

  return (
    <HeaderWrapper position="fixed">
      <AppToolbar>
        <MenuButton onClick={onMenuClick}>
          <Menu />
        </MenuButton>
        <LogoButton
          style={{
            backgroundColor: "transparent"
          }}
          onClick={onHeaderClick}
        >
          <Logo src={logo} />
        </LogoButton>
        {propfileId && (
          <ProfileInfo>
            <IconButton onClick={onProfileClick}>
              <Avatar src={img|| ""} />
            </IconButton>
            <ProfileName>{username?.replace(/_/g, " ")}</ProfileName>
          </ProfileInfo>
        )}
      </AppToolbar>
    </HeaderWrapper>
  );
};

export default Header;
