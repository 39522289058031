import React from "react";
import { Box, ListItemText, styled } from "@material-ui/core";

interface Props {
  title: string;
  image: any;
  isSelected?: boolean;
  onClick?: () => void;
  selectedImage?: any;
}

const LinkText = styled(ListItemText)({
  fontFamily: "Poppins, Inter, sans-serif",
  fontSize: "18px",
  lineHeight: "27px",
  textAlign: "left",
  letterSpacing: "0em",
  color: "rgba(66, 76, 83, 1)",
});

const SelectedText = styled(LinkText)({
  background:
    "linear-gradient(90deg, rgba(52,185,252,1) 0%, rgba(91,137,253,1) 50%, rgba(123,97,253,1) 100%)",
  "-webkit-background-clip": "text",
  "-webkit-text-fill-color": "transparent",
});

export default function SidebarLink(props: Props) {
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      style={{ gap: "20px", cursor: "pointer" }}
      onClick={props.onClick}
      data-test-id={`nav-link/${props.title}`}
    >
      {props.isSelected ? (
        <>
          {props.selectedImage}
          <SelectedText primary={props.title}>{props.title}</SelectedText>
        </>
      ) : (
        <>
          {props.image}
          <LinkText data-test-id="LinkText" primary={props.title}>
            {props.title}
          </LinkText>
        </>
      )}
    </Box>
  );
}
