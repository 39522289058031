// Customizable Area Start

import { Box, Button, styled } from "@material-ui/core";
import React, { FC, useEffect } from "react";
import { ActionText, SectionTitle } from "./PhotoForm.web";
import { TextInput } from "./TextInput.web";
import { PhoneInput } from "./PhoneInput.web";
import { useFormik } from "formik";
import * as Yup from "yup";
import { editImg } from "./assets";

export interface FormProps {
  firstName: string;
  lastName: string;
  email: string;
  countryCode: string;
  phoneNumber: string;
  company: string;
}

export interface Props extends FormProps {
  isLoading: boolean;
  setError: (err: string) => void;
  handleSubmit: (form: Partial<FormProps>) => void;
  handleChangeModal(): void;
}

const FormWrapper = styled(Box)({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gridGap: "15px",
  marginTop: "25px",
  '& .MuiButton-root.Mui-disabled': {
    cursor: "pointer",
    color: "#0090CF !important",
  },
});

export const CancelButton = styled(Button)({
  flex: "1",
  width: "100%",
  maxWidth: "183px",
  height: "44px",
  padding: "10px 16px",
  justifyContent: "center",
  alignItems: "center",
  flexShrink: 0,
  borderRadius: "8px",
  background: "var(--scio-services-light-blue-50-light-blue, #EBF5FE)",
  textTransform: "none",
  color: "#0090CF !important",
  fontWeight: 500,
  fontFamily: "Poppins, Inter",
  '& MuiButton-root Mui-disabled': {
    cursor: "pointer",
    color: "#0090CF !important",
  },
  "@media(max-width: 435px)": {
    maxWidth: '100%'
  }
});

export const SaveButton = styled(CancelButton)({
  background: "linear-gradient(to right, #34B9FC, #7B61FD)",
  color: "white !important",
  "@media(max-width: 900px)": {
    width: "100%",
  },
  "@media(max-width: 435px)": {
    maxWidth: '100%'
  }
});

export const ButtonContainers = styled(Box) ({
  '& .MuiButton-root.Mui-disabled': {
    cursor: "poiner"
  }
})

const NAME_ERROR = "Please enter a valid first name/last name.";
const COMPANY_ERROR = "Please enter a valid company name.";
const PHONE_ERROR = "Please enter a valid US phone number.";
export const AccountDetails: FC<Props> = ({
  firstName,
  lastName,
  email,
  countryCode,
  phoneNumber,
  company,
  setError,
  handleSubmit,
  handleChangeModal,
  isLoading,
}) => {
  const formik = useFormik({
    initialValues: {
      firstName,
      lastName,
      countryCode,
      phoneNumber,
      company,
    },
    validateOnBlur: true,
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .required(NAME_ERROR)
        .matches(/^[a-zA-Z][a-zA-Z\- ]{0,48}[a-zA-Z]$/, NAME_ERROR)
        .max(64, NAME_ERROR),
      lastName: Yup.string()
        .required(NAME_ERROR)
        .matches(/^[a-zA-Z][a-zA-Z\- ]{0,48}[a-zA-Z]$/, NAME_ERROR)
        .max(64, NAME_ERROR),
      company: Yup.string().max(64, COMPANY_ERROR),
      phoneNumber: Yup.string()
        .matches(/^\d{7}$/, PHONE_ERROR) // Ensure only numeric values
        .min(7, PHONE_ERROR)
        .max(7, PHONE_ERROR)
        .required(PHONE_ERROR),
      countryCode: Yup.string()
        .required(PHONE_ERROR)
        .min(3, PHONE_ERROR)
        .max(3, PHONE_ERROR)
        .matches(/^\d{3}$/, PHONE_ERROR)
    }),
    onSubmit: (values) => handleSubmit(values),
  });
  const handleCancel = () => {
    formik.setValues({
      firstName,
      lastName,
      countryCode,
      company,
      phoneNumber,
    });
  };

  const errors = Object.keys(formik.errors).filter(
    // @ts-ignore
    (prop) => formik.errors[prop] && formik.touched[prop]
  );

  useEffect(() => {
    const errKey = errors[0];

    if (errKey) {
      // @ts-ignore
      setError(formik.errors[errKey]);
    } else {
      setError("");
    }
  }, [errors.length]);

  useEffect(() => {
    if (!email) {
      return;
    }
    formik.setValues({
      firstName,
      lastName,
      countryCode,
      phoneNumber,
      company,
    });
  }, [firstName, lastName, countryCode, phoneNumber, company]);

  return (
    <Box marginTop={"35px"}>
      <SectionTitle>Account details</SectionTitle>
      <FormWrapper>
        <TextInput
          value={formik.values.firstName}
          name="firstName"
          title="First Name *"
          placeholder="User first name"
          handleChange={formik.handleChange}
          error={
            (formik.errors.firstName && formik.touched.firstName) 
          }
          handleBlur={formik.handleBlur}
          data-test-id="firstNameInput"
        />
        <TextInput
          value={formik.values.lastName}
          name="lastName"
          title="Last Name *"
          placeholder="User last name"
          handleChange={formik.handleChange}
          error={
            (formik.errors.lastName && formik.touched.lastName)
          }
          handleBlur={formik.handleBlur}
          data-test-id="lastNameInput"
        />
        <PhoneInput
          handleChangePhone={formik.handleChange}
          handleChangeCode={formik.handleChange}
          handleBlurCode={formik.handleBlur}
          handleBlurPhone={formik.handleBlur}
          phoneNumber={formik.values.phoneNumber}
          countryCode={formik.values.countryCode}
          error={
            (formik.errors.countryCode && formik.touched.countryCode) ||
            (formik.errors.phoneNumber && formik.touched.phoneNumber)
          }
        />
        <TextInput
          value={formik.values.company}
          name="company"
          title="Company"
          placeholder="Company name"
          handleChange={formik.handleChange}
          error={formik.errors.company}
          handleBlur={formik.handleBlur}
        />
        <TextInput
          value={email}
          name="email"
          title="Email *"
          disabled
          placeholder="email@email.com"
          error={false}
        />
        <TextInput
          value=""
          name="firstName"
          title="Password *"
          disabled
          placeholder="*********"
          error={false}
        />
      </FormWrapper>
      <Box
        width={"100%"}
        marginTop={"10px"}
        display={"flex"}
        justifyContent={"flex-end"}
        color={"#4FC3FD"}
      >
        <div style={{ display: 'flex' }} onClick={handleChangeModal}>
          <img
            src={editImg}
            style={{ height: "18px", width: "18px", cursor: "pointer" }}
          />
          <ActionText style={{ cursor: "pointer" }}>
            Change my password
          </ActionText>
        </div>
      </Box>
      <ButtonContainers
        width={"100%"}
        display={"flex"}
        justifyContent={"flex-start"}
        marginTop={"30px"}
        style={{ gap: "30px" }}
      >
        <CancelButton
          data-test-id="cancelBtn"
          onClick={handleCancel}
          disabled={isLoading}
        >
          Cancel
        </CancelButton>
        <SaveButton
          data-test-id="saveBtn"
          onClick={() => formik.handleSubmit()}
          disabled={isLoading}
        >
          Save Changes
        </SaveButton>
      </ButtonContainers>
    </Box>
  );
};
// Customizable Area End
