import React, { FC, useState } from "react";
import {
  CustomIconButton,
  EyeOffIcon,
  FieldBox,
  LabelText,
  TextFieldCustom,
} from "../../email-account-login/src/EmailAccountLoginBlock.web";
import { InputAdornment } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import { eyeOff } from "../../email-account-login/src/assets";

interface Props {
  label: string;
  value: string;
  handleChange: (e: string) => void;
  error: boolean;
}

export const PasswordField: FC<Props> = ({
  label,
  value,
  handleChange,
  error,
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <FieldBox textAlign="left">
      <LabelText>{label}</LabelText>
      <TextFieldCustom
        id="outlined-basic"
        data-test-id="txtInputPassword"
        name={"password"}
        placeholder={"*********"}
        variant="outlined"
        error={error}
        type={!visible ? "password" : "text"}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CustomIconButton
                aria-label="toggle password visibility"
                onClick={() => setVisible(!visible)}
                edge="end"
                data-test-id="showPwdButton"
              >
                {visible ? <Visibility /> : <EyeOffIcon src={eyeOff} />}
              </CustomIconButton>
            </InputAdornment>
          ),
        }}
      />
    </FieldBox>
  );
};
