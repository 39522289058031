// Customizable Area Start
import React from "react";
import ProfileImageAdjustController, {
  Props,
} from "./ProfileImageAdjustController";
import Cropper from "react-easy-crop";
import { Box, styled } from "@material-ui/core";
import ProfileImageAdjustSlider from "./ProfileImageAdjustSlider";
import { CancelButton, SaveButton } from "./AccountDetailsSection.web";

const ProfileImageAdjustContainer = styled(Box)({});

const CropperContainer = styled(Box)({
  position: "relative",
  width: "100%",
  height: "590px",
  margin: "auto",
  "@media (min-width: 900px)":{
    height: "calc(100vh - 360px)",
    "& > img": {
      objectFit: 'contain'
    }
  },

  "& .reactEasyCrop_Container": {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",

    "& .reactEasyCrop_CropArea": {
      maxHeight: " 95% !important",
      border: "4px solid rgba(123, 97, 253, 1)",
      "@media (min-width: 900px)":{
      height:'250px !important',
      width:'250px !important'
      }
    },

    "&::after": {
      content: "'Drag to reposition photo'",
      position: "absolute",
      bottom: 10,
      right: "50%",
      transform: "translate(50%)",
      textAlign: 'center',
      fontFamily: "Poppins, Inter, sans-serif",
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 500,
      color: "white",
    },
  },
});

const ButtonContainer = styled(Box)({
  marginTop: "40px",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: "20px",
  "@media(max-width: 435px)": {
  justifyContent: "center",
    flexDirection: 'column-reverse', 
    alignItems: "flex-start"
  },
});

export default class ProfileImageAdjust extends ProfileImageAdjustController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <ProfileImageAdjustContainer>
        <CropperContainer>
          {this.state.showCropper && (
            <Cropper
              image={this.props.imageSrc}
              crop={{
                x: this.state.x,
                y: this.state.y,
              }}
              zoom={this.state.zoom}
              aspect={1}
              onCropChange={this.onCropChange}
              onCropComplete={this.onCropComplete}
              onZoomChange={this.onZoomChange}
              objectFit="contain"
              cropShape="round"
              showGrid={true}
              style={{
                mediaStyle: this.state.imageProps,
              }}
            />
          )}
        </CropperContainer>
        <ProfileImageAdjustSlider
          zoom={this.state.zoom}
          onZoomChange={this.onZoomChange}
        />
        <ButtonContainer>
          <CancelButton onClick={this.props.onClose}>Cancel</CancelButton>
          <SaveButton data-test-id="adjustBtn" onClick={this.onSave}>
            Apply
          </SaveButton>
        </ButtonContainer>
      </ProfileImageAdjustContainer>
    );
  }
}
// Customizable Area Start
