import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { Typography, styled, Box } from "@material-ui/core";
import { popupCrossIcon } from "./assets";

interface CompleteDetailsInterface {
  handleCloseSurveyDetail: () => void;
  data: {
    id: number;
    survey_name: string;
    date_created: string;
    last_edited: string;
    created_by: string;
    owner: string;
    progress: string;
  };
  openSurveyDetailPopup:boolean,
}

const CustomConfiramtionDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    display: "flex",
    flexDirection: "column",
    borderRadius: "8px",
    width: "327px",
    padding: "50px 13px 33px 25px",
    "@media(max-width:500px)":{
      width: "calc(100% - 48px)",
      padding: "48px 29px 28px 29px",
      position: "relative"
    }
  },
  "& .MuiBackdrop-root":{
    backgroundColor: "rgba(0,0,0,0.4)"
  }
});

const MainDataBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

const CrossIconBox = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  "@media(max-width:500px)":{
    position: "absolute",
    top: "0",
    right: "9px"
  }
});

const TextAndBorderLineBox = styled(Box)({
  width: "91%",
  marginBottom: "15px",
  "@media(max-width:500px)":{
    width: "100%",
    marginBottom: "20px"
  }
});

const IconImage = styled("img")({
  cursor: "pointer",
  position: "absolute",
  top: "13px",
});

const TextAndDateBox = styled(Box)({
  display: "flex",
  gap: "39px",
});

const PopupText = styled(Typography)({
  color: "#000",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  "@media(max-width:500px)":{
    fontSize: "14px"
  }
});

const LastEditPopupText = styled(Typography)({
  color: "#000",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  paddingLeft: "23px",
  "@media(max-width:500px)":{
    fontSize: "14px"
  }
});

const CreateByPopupText = styled(Typography)({
  color: "#000",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  paddingLeft: "19px",
  "@media(max-width:500px)":{
    fontSize: "14px"
  }
});

const OwnerPopupText = styled(Typography)({
  color: "#000",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  paddingLeft: "55px",
  "@media(max-width:500px)":{
    fontSize: "14px"
  },
  "@media(max-width: 350px)": {
    paddingLeft: "45px",
  },
  "@media(max-width: 330px)": {
    paddingLeft: "35px",
  },
});

const ProgressPopupText = styled(Typography)({
  color: "#000",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  paddingLeft: "39px",
  "@media(max-width:500px)":{
    fontSize: "14px"
  },
  "@media(max-width: 350px)": {
    paddingLeft: "29px",
  },
  "@media(max-width: 330px)": {
    paddingLeft: "19px",
  },
});

const BorderLine = styled(Box)({
  border: "1px solid #4d4d4d",
  marginTop: "10px",
});

class CompleteDetailSurveyPopup extends React.Component<
  any,
  CompleteDetailsInterface
> {
  render() {
    const { data,openSurveyDetailPopup,handleCloseSurveyDetail } = this.props;
    return (
      <CustomConfiramtionDialog
        open={openSurveyDetailPopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <MainDataBox>
          <CrossIconBox>
            <IconImage
              src={popupCrossIcon}
              onClick={handleCloseSurveyDetail}
            />
          </CrossIconBox>
          <TextAndBorderLineBox>
            <TextAndDateBox>
              <PopupText>Date Created</PopupText>
              <PopupText>{data.date_created}</PopupText>
            </TextAndDateBox>
            <BorderLine />
          </TextAndBorderLineBox>
          <TextAndBorderLineBox>
            <TextAndDateBox>
              <PopupText>Last Edited</PopupText>
              <LastEditPopupText>{data.last_edited}</LastEditPopupText>
            </TextAndDateBox>
            <BorderLine />
          </TextAndBorderLineBox>
          <TextAndBorderLineBox>
            <TextAndDateBox>
              <PopupText>Owner</PopupText>
              <OwnerPopupText>{data.owner.length > 12 ? (data.owner.slice(0,10) + "...."): data.owner}</OwnerPopupText>
            </TextAndDateBox>
            <BorderLine />
          </TextAndBorderLineBox>
          <TextAndBorderLineBox>
            <TextAndDateBox>
              <PopupText>Progress</PopupText>
              <ProgressPopupText>{data.progress}</ProgressPopupText>
            </TextAndDateBox>
            <BorderLine />
          </TextAndBorderLineBox>
        </MainDataBox>
      </CustomConfiramtionDialog>
    );
  }
}

export default CompleteDetailSurveyPopup;
