import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { sendForgotPasswordRequest } from "./utils";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  email: string;
  error: string;
  showError: boolean;
  isFetching: boolean;
}

interface SS {
  id: any;
}

interface ErrorDetails {
  email: string;
}
interface ErrorResponse {
  errors: [ErrorDetails];
}

interface ErrorDetails {
  email: string;
}
interface ErrorResponse {
  errors: [ErrorDetails];
}

interface SuccessResponse {
  message: string;
}

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  messageId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      email: "",
      error: "",
      showError: false,
      isFetching: false,
    };

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    const error = sessionStorage.getItem("error");

    if (error) {
      this.setState({
        showError: true,
        error,
      });
      sessionStorage.removeItem("error");
    }
  }
  receive(from: string, message: Message): void {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.setState({
        isFetching: false,
      });
      this.handleResponse(message);
    }
  }

  private handleResponse(message: Message) {
    const responseMessageId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (responseMessageId !== this.messageId) {
      return;
    }
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (response && response.message) {
      this.handleSuccess();
    } else if (response && response.errors) {
      this.handleError(response);
    } else {
      this.setState({
        error: "An error has occured.",
      });
    }
  }

  handleInput(email: string) {
    this.setState({
      showError: false,
      email,
    });
  }

  handleBackToLogin() {
    this.props.navigation.navigate("EmailAccountLoginBlock");
  }

  handleSubmit() {
    if (!this.state.email) {
      this.setState({
        showError: true,
        error: "Email is requeired",
      });
      return;
    }
    const isValid = this.validateEmail(this.state.email);
    if (!isValid) {
      this.setState({
        showError: true,
        error: "Email is invalid",
      });

      return;
    }

    localStorage.setItem("recoveryEmail", this.state.email);
    this.sendRequest();
  }

  private validateEmail(email: string) {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  }

  private sendRequest() {
    this.setState({
      isFetching: true,
    });
    sendForgotPasswordRequest(
      this.state.email,
      (id: string) => (this.messageId = id)
    );
  }

  private handleSuccess() {
    this.props.navigation.navigate("CheckEmail");
  }

  private handleError(error: ErrorResponse) {
    const { errors } = error;

    const { email: msg } = errors[0];
    this.setState({
      error: msg,
      showError: true,
    });
  }
}
