Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfspeechtotext5";
exports.labelBodyText = "cfspeechtotext5 Body";

exports.btnExampleTitle = "CLICK ME";
exports.stopText = "Stop";
exports.startText = "Start";
exports.resultText = "Result:-";
// Customizable Area End