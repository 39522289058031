import React from "react";
// Customizable Area Start
import {
  eyeOff
} from "../../email-account-login/src/assets";
import Visibility from "@material-ui/icons/Visibility";

import {
  Box,
  Typography,
  InputAdornment,
  IconButton,
  styled,
  TextField
} from "@material-ui/core";
import { Formik } from "formik";
import ChangePasswordMobileScreenController, { configJSON } from "./ChangePasswordMobileScreenController.web";
import { MobileSettingWrapper } from "./MobileSettingWrapper";
import { emailIcon } from "./assets";

const Maindiv = styled("div")({
  flexShrink: 1,
  padding: "30px 90px 0px 90px",
  boxSizing: "border-box",
  "@media (max-width: 1199px)": {
    padding: "10px 50px 0px 50px"
  },
  "@media (max-width: 900px)": {
    padding: "50px 20px 0px 20px"
  },
  "@media (max-width: 510px)": {
    padding: "0px 20px 0px 20px"
  }
});

const EyeOffIcon = styled("img")({});

const Inputdiv = styled("div")({
  marginTop: "30px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "0",
  "@media (max-width: 900px)": {
    marginTop: "20px"
  }
});

const InputdivCurrentPwd = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "0",
})

const LabelText = styled(Typography)({
  color: "#424C53",
  fontFamily: "Poppins",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  marginRight: "0px",
  alignItems: "flex-start",
  marginBottom: "0px"
});

const StyledTextField = styled(TextField)({
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  width: "100%",
  color: "#424C53",
  fontFamily: "Poppins",
  fontSize: "16px",
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px"
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px 8px"
  },
  background: "var(--scio-services-neutral-50-neutral, #F9FAFA)"
});
const StyledListItem = styled("li")({
  color: "#A6B1B7",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 300,
  lineHeight: "normal",
  display: "flex",
  alignItems: "center",
  marginTop: "8px",
  gap: "8px",
});

const StyledListul = styled("ul")({
  paddingLeft: "0px",
  marginTop: "-12px"
});

export default class ChangePasswordMobileScreen extends ChangePasswordMobileScreenController {

  renderCurrentPassword = (setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void, errors: { currentPassword?: string }, touched?: { currentPassword?: string | boolean }, values?: { currentPassword: string }) => {
    return (
      <>
        <InputdivCurrentPwd>
          <LabelText>Current Password</LabelText>
          <StyledTextField
            variant="outlined"
            type={this.state.enableCurrentPassField ? "password" : "text"}
            value={values && values.currentPassword}
            placeholder="*********"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              this.handleCurrentPassword(event, setFieldValue)
            }}
            name="currentPassword"
            error={errors.currentPassword !== undefined && touched?.currentPassword !== undefined}
            data-test-id="current-password-input"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton data-test-id="current-password-visible"
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowCurrentPassword}
                    edge="end"
                  >
                    {this.state.enableCurrentPassField ? (
                      <EyeOffIcon src={eyeOff} />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </InputdivCurrentPwd>
      </>
    );
  };

  renderNewPassword = (setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void, errors: { newPassword?: string }, touched?: { newPassword?: string | boolean }, values?: { newPassword: string }) => {
    return (
      <>
        <Inputdiv>
          <LabelText>New Password</LabelText>
          <StyledTextField
            variant="outlined"
            type={this.state.enableNewPassField ? "password" : "text"}
            value={values && values.newPassword}
            placeholder="*********"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              this.handleNewPassword(event, setFieldValue)
            }}
            name="newPassword"
            error={errors.newPassword !== undefined && touched?.newPassword !== undefined}
            data-test-id="current-password-input"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowNewPassword}
                    edge="end"
                  >
                    {this.state.enableNewPassField ? (
                      <EyeOffIcon src={eyeOff} />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Inputdiv>
      </>
    );
  };

  renderConfirmNewPassword = (setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void, errors: { confirmNewPassword?: string }, touched?: { confirmNewPassword?: string | boolean }, values?: { confirmNewPassword: string }) => {
    return (
      <>
        <Inputdiv>
          <LabelText>Confirm new password</LabelText>
          <StyledTextField
            variant="outlined"
            type={this.state.enableConfirmNewPassField ? "password" : "text"}
            value={values && values.confirmNewPassword}
            placeholder="*********"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              this.handleConfirmNewPassword(event, setFieldValue)
            }}
            name="confirmNewPassword"
            error={errors.confirmNewPassword !== undefined && touched?.confirmNewPassword !== undefined}
            data-test-id="current-password-input"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton 
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowConfirmNewPassword}
                    edge="end"
                  >
                    {this.state.enableConfirmNewPassField ? (
                      <EyeOffIcon src={eyeOff} />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Inputdiv>
      </>
    );
  };

  renderValidation = () => {
    return (
      <Inputdiv>
        <LabelText>{configJSON.belowValidation}</LabelText>
        <StyledListul>
          <StyledListItem>
            <img src={this.isIcon(this.state.newPass, this.state.toUpperCase)} alt="cross" />
            {configJSON.capitalLetter}
          </StyledListItem>
          <StyledListItem>
            <img src={this.isIcon(this.state.newPass, this.state.toLowerCase)} alt="cross" />
            {configJSON.lowerLetter}
          </StyledListItem>
          <StyledListItem>
            <img src={this.isIcon(this.state.newPass, this.state.toNumber)} alt="cross" />
            {configJSON.numbers}
          </StyledListItem>
          <StyledListItem>
            <img src={this.isIcon(this.state.newPass, this.state.toCharacter)} alt="cross" />
            {configJSON.character}
          </StyledListItem>
        </StyledListul>
      </Inputdiv>
    );
  };
  render() {
    return (
      <StyledWrapper>
        <Formik
        data-test-id="formikID"
        validationSchema={this.passwordSchema}
          initialValues={{
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: ""
          }}
          onSubmit={(values) => {
            this.handleSubmit(values);
          }}
        >
          {({
            handleSubmit,
            values,
            errors,
            touched,
            setFieldValue
          }) => (
            <MobileSettingWrapper
              isPasswordChanged={this.state.isPasswordChanged}
              goBack={this.props.navigation.goBack}
              success={this.state.showSuccessMessage}
              onSubmit={() => handleSubmit()}
              error={this.state.error}
              onCancel={() => {
                setFieldValue("currentPassword", "");
                setFieldValue("newPassword", "");
                setFieldValue("confirmNewPassword", "");
                this.setState({
                  validationError: "",
                  newPass: ""
                })
              }}
              subtitle="Password"
              navigation={this.props.navigation}>
              <>
                {
                  this.state.isPasswordChanged ?
                    <PasswordChanged>
                      <img src={emailIcon} alt="password changed" />
                      <Typography>
                      Password 
                      </Typography>
                      <Typography>
                      successfully changed
                      </Typography>
                    </PasswordChanged>
                    :
                    <>
                      {
                        this.state.validationError &&
                        <StyledError>
                          {this.state.validationError}
                        </StyledError>
                      }
                      <Maindiv>
                        {this.renderCurrentPassword(setFieldValue, errors, touched, values)}
                        {this.renderNewPassword(setFieldValue, errors, touched, values)}
                        {this.renderConfirmNewPassword(setFieldValue, errors, touched, values)}
                        {
                          this.renderValidation()
                        }
                      </Maindiv>
                    </>
                }
              </>
            </MobileSettingWrapper>
          )}
        </Formik>
      </StyledWrapper>
    );
  }
}

const StyledWrapper = styled(Box)({
  "& .MuiInputBase-root": {
    height: "44px"
  }
});
const StyledError = styled(Box)({
  borderTop: "4px solid #DC2626",
  backgroundColor: "#FEE2E2",
  padding: "12px 10px",
  textAlign: "center",
  color: "#DC2626",
  fontSize: "12px",
  lineHeight: "18px",
  fontWeight: 400,
  marginBottom: "20px"
});
const PasswordChanged = styled(Box)({
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection:"column",
  "& img":{
    width: "40px",
    height: "40px",
    marginBottom: "10px"
  },
  "& p":{
    fontSize: "20px",
    fontWeight: 600,
  }
});
// Customizable Area End
