import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { Typography, styled, Box, Button } from "@material-ui/core";
import { alertLogo } from "./assets";


interface SubsecDeletePopupInterface{
  handleCloseDeleteSubsecPopup:()=>void,
  message:string,
  firstBtnText:string,
  secondBtnText:string,
  handleDeleteElementAccordion:()=>void,
}

const CustomConfiramtionDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    padding: "66px 66px 80px 66px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    overflow: "hidden"
  },
});

const PopUpIcon = styled("img")({
  height: "92px",
  width: "92px",
});

const Message = styled(Typography)({
  fontFamily: "Poppins, Inter, sans-serif",
  fontSize: "25px",
  lineHeight: "37px",
  fontWeight: 400,
  textAlign: "center",
  maxWidth: "555px",
  marginTop: "20px"
});

const ButtonBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "90px",
  gap: "95px",
  "@media(max-width: 670px)": {
    flexDirection:"column",
    gap:"10px"
  },
});

const FirstButton = styled(Button)({
  width: "258px",
  height: "44px",
  padding: "10px 16px",
  borderRadius: "8px",
  background: "#C2E1FA",
  color: "#0090CF",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "24px",
  textTransform: "none",
  "&:hover":{
    backgroundColor: "#C2E1FA"
  }
});

const SecondButton = styled(Button)({
  width: "258px",
  height: "44px",
  padding: "10px 16px",
  borderRadius: "8px",
  background: "linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 100%)",
  color: "#FFF",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "24px",
  textTransform: "none",
});

class SubsectionDeletePopup extends React.Component<SubsecDeletePopupInterface, any> {
  constructor(props: SubsecDeletePopupInterface) {
    super(props);
  }

  render() {
    const {handleCloseDeleteSubsecPopup,message,firstBtnText,secondBtnText,handleDeleteElementAccordion} = this.props
    return (
      <CustomConfiramtionDialog
      BackdropProps={{
        invisible: true }}
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <PopUpIcon src={alertLogo} />
        <Message id="alert-dialog-description">
          {message}
        </Message>
        <ButtonBox>
          <FirstButton data-test-id="first_btn_text" onClick={handleCloseDeleteSubsecPopup}>{firstBtnText}</FirstButton>
          <SecondButton data-test-id="second_btn_text" onClick={handleDeleteElementAccordion}>{secondBtnText}</SecondButton>
        </ButtonBox>
      </CustomConfiramtionDialog>
    );
  }
}

export default SubsectionDeletePopup;
