import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Box } from "@material-ui/core";
import { popupCrossIcon } from "./assets";
import { TemplateSelectOption } from "./LandingPageController";

interface Props {
  templates: TemplateSelectOption[];
  isOpen: boolean;
  isFetching: boolean;
  closeModal: () => void;
  handleTemplateSelect: (id: number) => void;
}

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    position: "absolute",
    height: "100.5%",
    width: "100.3%",
    top: -1,
    left: -1,

    backgroundColor: "rgba(24, 24, 24, 0.4)",
    borderRadius: "16px",
    "@media(max-width: 500px)": {
      zIndex: 1101,
      position: "fixed",
    },
    "@media(max-width: 650px)": {
      borderRadius: 0
    }
  },
  paper: {
    backgroundColor: "white",
    padding: "18px 18px 50px 50px",
    width: "calc(94% - 9px - 29px)",
    borderRadius: "8px",

    "@media": {
      maxWidth: "calc(680px - 18px - 50px)",
      padding: "9px 9px 50px 29px"
    },
    "@media(max-width: 500px)":{
      padding: "52px 29px 51px 29px",
      boxSizing: "border-box",
      position: "relative",
      width: "calc(100% - 48px)"
    }
  },
  closeIcon:{
    "@media(max-width: 500px)":{
      position: "absolute",
      top: "8px",
      right: "9px"
    }
  },
  template: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    paddingBottom: "10px",
    borderBottom: "1px solid black",
    cursor: "pointer",
    width: "100%",

    fontFamily: "Poppins, Inter, sans-serif",
    fontWeight: 400,
    fontSize: "20px",

    "& >p": {
      maxWidth: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    "@media(max-width:500px)":{
      fontSize: "16px"
    }
  },
  templateContainer: {
    paddingRight: "33px",
    paddingTop: "17px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "38px",
    "@media(max-width:500px)":{
      paddingRight: "0",
      paddingTop: "0",
      gap: "20px"
    }
  }
}));

const SelectTemplatePopUp: FC<Props> = ({
  templates,
  handleTemplateSelect,
  isOpen,
  closeModal,
  isFetching
}) => {
  const classes = useStyles();

  if (!isOpen) {
    return null;
  }

  return (
    <Box
      className={classes.modal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {!isFetching ? (
        <Paper className={classes.paper}>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            className={classes.closeIcon}
          >
            <img
              data-test-id="close-btn"
              src={popupCrossIcon}
              onClick={closeModal}
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Box className={classes.templateContainer}>
            {templates.map(template => (
              <div
                data-test-id={`template-${template.id}`}
                className={classes.template}
                onClick={() => handleTemplateSelect(template.id)}
              >
                <p style={{ margin: 0 }}>{template.name}</p>
                <NavigateNextIcon />
              </div>
            ))}
          </Box>
        </Paper>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default SelectTemplatePopUp;
