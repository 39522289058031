import React from "react";
import AccountController, { Props } from "./AccountController.web";
import { Formik } from "formik";
import { MobileSettingWrapper } from "./MobileSettingWrapper";
import { TextInput } from "./TextInput.web";
import * as Yup from "yup";

const COMPANY_ERROR = "Please enter a valid company name.";
export default class MobileCompanySettings extends AccountController {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    return (
      <Formik
        initialValues={{
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          countryCode: this.state.phoneCode,
          phoneNumber: this.state.phoneNumber,
          company: this.state.company
        }}
        onSubmit={values => {
          this.handleSubmit(values);
        }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          company: Yup.string().max(64, COMPANY_ERROR)
        })}
      >
        {({
          handleSubmit,
          setValues,
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          validateField
        }) => (
          <MobileSettingWrapper
          goBack={this.props.navigation.goBack}
          success={this.state.showSuccessMessage}
          onSubmit={() => handleSubmit()}
          error={this.state.error}
          onCancel={() => {
            setValues({
              lastName: this.state.lastName,
              countryCode: this.state.phoneCode,
              company: this.state.company,
              phoneNumber: this.state.phoneNumber,
              firstName: this.state.firstName
            });
          }}
          subtitle="Company"
          account={{
            id: localStorage.getItem("user-id"),
            name: this.fullName
          }}
          navigation={this.props.navigation}>
            <TextInput
              value={values.company}
              name="company"
              title="Company"
              data-test-id="companyInput"
              placeholder="Company name"
              handleChange={this.createChangeHandler(handleChange)}
              error={errors.company && touched.company}
              handleBlur={this.createBlurHandler(
                "company",
                handleBlur,
                validateField,
                errors
              )}
            />
          </MobileSettingWrapper>
        )}
      </Formik>
    );
  }
}
