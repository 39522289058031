import React from "react";
import CheckEmailController from "./CheckEmailController.web"
import { ForgotPasswordWrapper} from "./ForgotPasswordWrapper";
import { imgMail } from "./assets";
import { Box } from "@material-ui/core";
import { Props} from "./ForgotPasswordGenericController.web";
import "../assets/styles/index.css";

export default class CheckEmail extends CheckEmailController {
  constructor(props: Props) {
    super(props);
    this.goBackToLogin = this.goBackToLogin.bind(this);
    this.resend = this.resend.bind(this);
  }

  render() {
    return (
      <ForgotPasswordWrapper
        title="Check your email"
        titleImage={imgMail}
        subtitile="We sent a password reset link to"
        subSubTitle={localStorage.getItem("recoveryEmail")}
        titleImageAlt={"eamil"}
        onSubmitClick={this.resend}
        onCancelClick={this.goBackToLogin}
        submitButtonText={"Resend recovery link"}
        cancelButtonText={"Back to login"}
        buttonHint={`You can resend a link in: ${this.resendTime}`}
        labelContainerClasses="check-email"
        errorMessage={this.state.error}
        showError={Boolean(this.state.error)}
        isFetching={this.state.isFetching || this.state.resendTime !== 0}
      >
        <Box marginBottom={"60px"} />
      </ForgotPasswordWrapper>
    );
  }
}
