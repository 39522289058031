// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.investApiContentType="multipart/form-data"
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "DashboardGuests";
exports.labelBodyText = "DashboardGuests Body";
exports.phoneInputRegex=/^\d{10}$/
exports.btnExampleTitle = "CLICK ME";
exports.getVerificationOtp="bx_block_login/logins/send_login_otp";
exports.validateOtpApiMethod="PUT";
exports.validateOtpApiEndpoint="bx_block_login/logins/otp_login";
exports.getCompainesApiEnpoint="bx_block_dashboardguests/companies";
exports.investApiEndpoint="bx_block_dashboardguests/dashboard_guests";
exports.getPortfolioApiEndPoint="bx_block_dashboardguests/portfolio";
exports.portfolioPublicUrl="anupam-265950-react.b265950.dev.eastus.az.svc.builder."
exports.mainHeading="Guest Dashboard";
exports.sidebarNavigationAPiEndPoint = "/bx_block_dashboardguests/dashboard_guests/navigation_menu";
exports.dateCreated = "Date created";
exports.lastEdited = "Last edited";
exports.createdBy = "Created by";
exports.owner = "Owner";
exports.historyDetailsAPiEndPoint = "bx_block_dashboardguests/dashboard_guests/guest_survey_info";
exports.downloadAsPDF = "Download report as PDF";
exports.downloadAsZIP = "ZIP Download";
exports.viewFullSize = "View image full size";
exports.downloadFiles = "Download";
exports.sectionApiEndPoint = "bx_block_dashboardguests/dashboard_guests/guest_survey_section_sub_section_detailed_info";
exports.activeLinkEndPoint = "bx_block_survey/link_share/active_link?link_share_id=";
exports.errorMessage = "This link has expired";
exports.viewFullSizeVideo = "View video full size";
exports.downloadPDF = "Review report as PDF";
exports.backButton = "Back";
exports.nextButton = "Next";
exports.winZipFileEndPoint = "bx_block_dashboardguests/dashboard_guests/guest_survey_uploaded_files?guest_survey_id=";
exports.headingTemp = "New Complex";
exports.created = "Date created";
exports.lastEdited = "Last edited";
exports.createdBy = "Created by";
exports.owner = "Owner";
exports.saveAsPdfEndPoint = "bx_block_saveaspdf2/survey_pdf/guest_survey_pdf_details?guest_survey_id=";
exports.closeButton = "Close";
exports.savePdfUrl = "SaveAsPdf/";

// Customizable Area End
