import { Box, Typography, styled } from "@material-ui/core";
import React, { FC } from "react";
import NavigateNextRounded from "@material-ui/icons/NavigateNextRounded";

export interface Props {
  title: string;
  value: string;
  disabled?: boolean;
  link: string;
  navigation: any;
  iconSrc: string;
  onDeleteAccountClick?: () => void;
  onLogoutClick?: () => void;
}

const Title = styled(Typography)({
  fontFamily: "Poppins, Inter, sans-serif",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "15px",
  color: "#424C53"
});

const Value = styled(Title)({
  fontWeight: 400,
  fontSize: "14px",
  color: "#728088",
  paddingTop: "10px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  maxWidth:"calc(100vw - 137px)",
  lineHeight: "18px"
});

const MenuCardBox = styled(Box)({
  "@media(max-width: 900px)":{
    overflow: "hidden"
  },
  "@media(max-width: 550px)": {
    padding: "0px 10px 0px 15px",
    height: "56px",
    borderRadius: "8px",
    borderBottom:
      "1px solid var(--Scio-Services-Neutral-100---Neutral, #DCE1E4)",
    background: "var(--Scio-Services-Basic-White, #FFF)",
    boxShadow: "none",
  }
});

export const MobileSelectButton: FC<Props> = ({
  navigation,
  link,
  iconSrc,
  disabled,
  value,
  title,
}) => {
  return (
    <MenuCardBox
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      flexDirection={"row"}
      boxShadow={"0 4px 2px -2px rgba(0, 0, 0, 0.15)"}
      borderRadius={"8px"}
      padding={"10px"}
      onClick={() => {
        !disabled && navigation.navigate(link)
      }}
      style={{ cursor: "pointer" }}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"flex-start"}
        style={{ gap: "30px" }}
      >
        <img src={iconSrc} />
        <Box>
          <Title>{title}</Title>
          <Value>{value}</Value>
        </Box>
      </Box>
      {!disabled && <NavigateNextRounded />}
    </MenuCardBox>
  );
};
