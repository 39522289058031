import React, { ChangeEventHandler } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { styled } from "@material-ui/core";
import { CustomTextInput } from "./CustomFieldText";
import { arrowIcon } from "./assets";
import { BlockComponent } from "../../../framework/src/BlockComponent";

interface Props {
  options: string[];
  handleChange: ChangeEventHandler<HTMLInputElement>;
  handleBlur: () => void;
  inputValue: string;
  hasError: boolean;
  placeHolder: string;
}

const CustomAutocomplete = styled(Autocomplete)({
  flex: 1,
});

const SelectTextField = styled(CustomTextInput)({
  "& input": {
    cursor: "pointer",
    caretColor: "white",
    "@media(max-width: 650px)":{
      height: 'auto !important',
      marginTop: "-5px"
    }
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":{
    borderColor:'#F87171 !important'
  },
});
const PopupImg = styled("img")({
  marginTop: '8px'
});
export class CustomFieldSelect extends BlockComponent<Props, {}, {}> {
    constructor(props: Props) {
        super(props);
      }
  render(): React.ReactNode {
    const { options,inputValue, handleChange, handleBlur, hasError } = this.props;
    return (
      <CustomAutocomplete
        renderInput={(params) => (
          <SelectTextField
            variant="outlined"
            {...params}
            placeholder= {this.props.placeHolder}
            error={hasError}
          />
        )}
        value={inputValue}
        options={options}
        popupIcon={<PopupImg src={arrowIcon} alt="open select" />}
        onKeyDown={(e) => e.preventDefault()}
        // onChange={handleChange}
        onSelect={handleChange}
        onBlur={handleBlur}
        disableClearable
      />
    );
  }
}
