// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area End
export interface Props {
  // Customizable Area Start
  navigation?: any;
  children?: React.ReactChild;
  profileImg?: any;
  account?: {
    id: string | null;
    name: string;
  };
  handleLogoutClick?: () => void;
  handleProfileNavigate?: () => void;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  isPermissionModalOpen: boolean;
  isGalleryAccessAllowed: boolean;
  ispermissionGranted: boolean;
  isCameraModalOpen: boolean;
  hasOpenSidebar:boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class UploadNewPhotoMobileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      isPermissionModalOpen: false,
      isGalleryAccessAllowed: true,
      ispermissionGranted: false,
      isCameraModalOpen: false,
      hasOpenSidebar:false
    };
    // Customizable Area End
  }

  // Customizable Area Start
  galleryAccessPermission = () => {
    this.setState({ isGalleryAccessAllowed: false });
  };

  permissionGranted = () => {
    this.setState({ ispermissionGranted: true });
  };

  handleGalleryClick = () =>{
    this.setState({ isPermissionModalOpen: true, isCameraModalOpen: false });
  };

  handleCameraClick = () =>{
    this.setState({ isCameraModalOpen: true, isPermissionModalOpen: false });
  };

  goBack=()=>{
    this.props.navigation.goBack();
  };

  handleDrowerToggle = () => {
    this.setState({hasOpenSidebar:!this.state.hasOpenSidebar})
  };

  navigate=()=>{
    this.props.navigation.navigate("UserProfileAdvance");
  };
  // Customizable Area End
};