import React from "react";
// Customizable Area Start
import { styled, Box, Typography, Button } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { backgroundImg } from "../../email-account-login/src/assets";
import PrivacyPolicyController, {
  Props,
  configJSON
} from "./PrivacyPolicyController.web";
import { mainLogo, hamburger } from "../src/assets";
// Customizable Area End

export default class PrivacyPolicy extends PrivacyPolicyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <PrivacyBackgroundImage>
        <LogoPolicyImageBox>
        <LogoPrivacyImage src={mainLogo} alt="mainlogo" />
        </LogoPolicyImageBox>
        <MainPrivacyPolicy>
          <PrivacyPolicyContainer>
            <ClosePrivacyIcon>
              <Close onClick={()=>this.goBackToLogin()} data-testid="closeBtn"/>
            </ClosePrivacyIcon>
            <DetailsPrivacyPolicy>
              <DetailsContainerPrivacyPloicy>
                <PrivacyPolicyHeading >
                  {configJSON.privacyPolicy}
                </PrivacyPolicyHeading>
                <PrivacyPolicyDetails>
                  <PrivacyParagraph
                   dangerouslySetInnerHTML={{
                    __html: this.state.privacyPolicyData     
                  }}></PrivacyParagraph>
                </PrivacyPolicyDetails>
              </DetailsContainerPrivacyPloicy>
            </DetailsPrivacyPolicy>
          </PrivacyPolicyContainer>
        </MainPrivacyPolicy>
      </PrivacyBackgroundImage>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const LogoPolicyImageBox = styled(Box)({
  width: "100%", 
  marginLeft: "42px",
  marginTop: "7px",
  maxHeight: "60px",
  marginBottom: "60px",
  display:"flex",
  justifyContent:"flex-start",
  margin: "auto",
  "@media (max-width: 1320px)": {
    marginLeft: "25px"
  },
  "@media(max-width:767px)":{
    alignItems: "center",
    marginLeft: "0px",
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
    background: "#FFF",
    marginBottom: "25px"
  }
});

const PrivacyBackgroundImage = styled("div")({
  backgroundSize: "cover",
  background: `url(${backgroundImg})`,
  backgroundPosition: "center",
  "@media(max-width:767px)":{
    background: "none"
  }
});

const MainPrivacyPolicy = styled(Box)({
  marginBottom: "120px",
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  "@media(max-width:767px)":{
    flexDirection: "column",
    marginBottom: "0px"
  }
});

const PrivacyPolicyContainer = styled(Box)({
  backgroundColor: "#ffffff",
  width: "80%",
  boxShadow: "0px 2px 8px 0px #00000014",
  border: "1px solid #C0C8CD",
  borderRadius: "8px",
  padding: "10px",
  "@media(max-width:767px)":{
  width: "100%",
  border: "none",
  boxShadow: "none",
  borderRadius: "0px",
  padding: "0px"
  }
});

const ClosePrivacyIcon = styled(Box)({
  padding:"20px",
  cursor:"pointer",
  display:"flex",
  justifyContent:"flex-end",
  "@media(max-width:767px)":{
    display: "none"
  }
});

const DetailsPrivacyPolicy = styled(Box)({
  justifyContent: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: "65px",
  marginTop:"20px",
  width: "100%",
  "@media(max-width:767px)":{
    marginBottom: "45px"
  }
});

const DetailsContainerPrivacyPloicy = styled(Box)({
  width: "80%",
  "@media(max-width:767px)":{
    width: "95%"
  }
});

const PrivacyPolicyHeading = styled(Typography)({
  fontWeight: 600,
  fontSize: "24px",
  marginBottom: "25px",
  fontFamily: "Poppins",
  "@media(max-width:465px)": {
    fontSize: "20px"
  },
  "@media(max-width:767px)": {
    padding: "0px 17px 0px 17px",
    marginBottom: "15px"
  },
});

const PrivacyParagraph = styled(Typography)({
  fontFamily: "Poppins",
  fontSize: "16px"
});

const PrivacyPolicyDetails = styled(Box)({
  overflowY: "scroll",
  boxShadow: "0px 8px 32px 0px #0000000F inset,0px 4px 8px 0px #00000008 inset",
  overflowX: "hidden",
  height: "380px",
  border: "1px solid #ffffff",
  padding: "10px",
  "@media(max-width:767px)": {
    padding: "0px 17px 0px 17px",
    boxShadow: "none",
    border: "none",
    height: "500px",
  },
});

const LogoPrivacyImage = styled("img")({
  flexShrink: 0,
  height: "60px",
  width: "118.723px",
  "@media(max-width:767px)": {
    margin: "auto",
    textAlign: "center",
    padding: "0px 0px 10px 0px",
    width: "101px",
    height: "56px"
  },
  "@media(max-width:425px)": {
    height: "36px",
    width: "71px"
  }
});
// Customizable Area End
