import { BlockComponent } from "../../../framework/src/BlockComponent";
import { CustomFormLink } from "./CustomFormController.web";


export interface Props {
  navigation: any;
  onLinkClick: (id: number) => void;
  handleScrollToHeading: (name: string) => void;
  surveyName?: string;
  links: CustomFormLink[];
  onNextBtnClick: () => void;
  onBackBtnClick: () => void;
  hasNextSection?: boolean;
  onSubmit: (status:string) => void;
  showPopUp: boolean;
  error: string;
  onPopUpClose: () => void;
  onSaveBtnClick: (status:string) => void;
  saveBtnResponse:boolean;
  searchSpecificSec:string;
  specificSecSearchesData:any,
  handleSectionSearchBar:(text:string)=>void,
  sectionSubSectionList:any,
  handleGlobalSearch: (option: string) => void;
  globalSearchOptions: string[];
  navigationSearchSections:any;
  listOfSectionsSubsections:any;
  linksSectionsSubsections:any;
  handleError: (error: string) => void;
  name:string;
  openAccordions:any[];
  deleteSubSecPopup:boolean;
  handleCloseDeleteSubsecPopup:()=>void;
  subSecName:string;
  handleDeleteElementAccordion:()=>void;

}

interface S {
  hasOpenSidebar: boolean;
  hasOpenGloableSearchBar: boolean;
}

interface SS {}

export class CustomFormWrapperController extends BlockComponent<Props, S, SS> {
  isDesktop: boolean = window.innerWidth > 900;

  constructor(props: Props) {
    super(props);
    this.state = {
      hasOpenSidebar: this.isDesktop,
      hasOpenGloableSearchBar: false
    };
  }

  componentDidMount = async() => {
    if(global.innerWidth > 435){
      this.setState({hasOpenGloableSearchBar: true })
    }
  }
  setOpenSidebar = (open: boolean) => {
    this.setState({ hasOpenSidebar: open });
  };
  toggleSearchbar = ()=>{
    this.setState((pre)=>({
      hasOpenGloableSearchBar: !pre.hasOpenGloableSearchBar
    }))
  }
}
