import React, { FC } from "react";
import {
  ErrorLoginAlert,
  ErrorLoginText,
  ForgetPasswordText,
  LabelTitle,
  LoginButton
} from "../../email-account-login/src/EmailAccountLoginBlock.web";
import { Box, Container, Typography, styled } from "@material-ui/core";
import { logo } from "../../email-account-login/src/assets";
import "../assets/styles/index.css";

const Subtitle = styled(Typography)({
  fontWeight: 500,
  lineHeight: "27px",
  textAlign: "center",
  fontSize: "18px",
  fontFamily: "Poppins",
  color: "#A6B1B7",
  letterSpacing: "0em",
  "& >p": {
    maxWidth: "430px"
  },

  "@media(max-width: 600px)": {
    fontWeight: 500,
    lineHeight: "27px",
    textAlign: "center",
    fontSize: "18px",
    fontFamily: "Poppins",
    color: "#A6B1B7",
    letterSpacing: "0em"
  },
  "@media (max-width: 435px)": {
    fontSize: "16px",
    boxSizing: "border-box",
    paddingRight: "16px",
    paddingLeft: "16px"
  }
});

export const MainWrapper = styled(Container)({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  boxSizing: "border-box",
  "@media (max-width: 435px)": {
    padding: 0
  }
});

const ForgotPasswordContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  height: "100%",
  "@media (max-width: 435px)": {
    marginTop: "48px"
  }
});

const LogoBox = styled(Box)({
  width: "100%",
  backgroundColor: "#fff",
  zIndex: 10,
  "@media(max-width: 435px)": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 2px 8px 0 rgba(0, 0, 0, 0.08)",
    width: "100%",
    height: "48px",
    position: "fixed",
    top: 0,
    left: "auto",
    right: 0
  }
});

const Logo = styled("img")({
  maxWidth: "120px",
  maxHeight: "60px",
  marginTop: "15px",
  cursor: "pointer",
  "@media(max-width: 435px)": {
    height: "36px",
    marginTop: "0"
  }
});

const ImageBox = styled(Box)({
  "@media(max-width: 550px)": {
    position: "absolute",
    top: "20px",
    left: "50%",
    transform: "translate(-50%)"
  }
});

const CenteredBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
});

const ContentWrapper = styled(CenteredBox)({
  width: "540px",

  "@media(max-width: 600px)": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "540px"
  }
});

const ButtonWrapper = styled(Box)({
  width: "100%",
  marginTop: "60px",

  "@media(max-width: 600px)": {
    width: "100%",
    marginTop: "10px"
  }
});

const ErrorWrapper = styled(Box)({
  marginBottom: "3%",
  width: "100%",
  "@media(max-width: 435px)": {
    position: "absolute"
  }
});

export interface Props {
  title: string;
  subtitile?: string;
  subSubTitle?: string | null;
  showError?: boolean;
  errorMessage?: string;
  titleImage: string;
  titleImageAlt: string;
  children:
    | string
    | JSX.Element
    | JSX.Element[]
    | (() => JSX.Element)
    | null
    | undefined;
  onSubmitClick: () => void;
  onCancelClick: () => void;
  submitButtonText: string;
  cancelButtonText?: string;
  buttonHint?: string;
  showCancelButton?: boolean;
  labelContainerClasses?: string;
  isFetching?: boolean;
}

export const ForgotPasswordWrapper: FC<Props> = ({
  title,
  subSubTitle,
  subtitile,
  showError,
  errorMessage,
  titleImage,
  titleImageAlt,
  children,
  onSubmitClick,
  onCancelClick,
  submitButtonText,
  cancelButtonText,
  buttonHint,
  showCancelButton = true,
  labelContainerClasses,
  isFetching
}) => {
  return (
    <MainWrapper style={{ maxWidth: "none" }}>
      <LogoBox data-test-id={"logobox"}>
        <Logo src={logo} onClick={onCancelClick} />
      </LogoBox>
      <ForgotPasswordContainer>
        <ContentWrapper>
          <Box
            style={{
              flexDirection: "column",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              width: "100%",
              position: "relative"
            }}
          >
            <ErrorWrapper>
              {showError ? (
                <ErrorLoginAlert textAlign={"left"}>
                  <ErrorLoginText data-test-id={"errorMsg"}>
                    {errorMessage}
                  </ErrorLoginText>
                </ErrorLoginAlert>
              ) : (
                <Box style={{ height: "45px" }} />
              )}
            </ErrorWrapper>
            <Box
              style={{ width: "100%", position: "relative", marginTop: "48px" }}
              className={labelContainerClasses}
            >
              <ImageBox>
                <img
                  src={titleImage}
                  alt={titleImageAlt}
                  style={{ marginBottom: "30px" }}
                />
              </ImageBox>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
              >
                <LabelTitle style={{ marginBottom: "10px" }}>
                  {title}
                </LabelTitle>
                <Subtitle style={{ maxWidth: "410px" }}>{subtitile}</Subtitle>
                {subSubTitle && (
                  <Subtitle style={{ fontWeight: 600 }}>{subSubTitle}</Subtitle>
                )}
              </Box>
            </Box>
            <Box style={{ marginTop: "20px" }} />
            {children}
          </Box>
          <ButtonWrapper>
            {buttonHint && (
              <Subtitle style={{ fontSize: "16px" }}>{buttonHint}</Subtitle>
            )}
            <LoginButton
              disabled={isFetching}
              style={{ marginTop: "10px", color: "white" }}
              data-test-id={"reset"}
              variant="contained"
              fullWidth
              onClick={onSubmitClick}
            >
              {submitButtonText}
            </LoginButton>
            {showCancelButton && (
              <ForgetPasswordText
                style={{ marginBottom: "20px" }}
                data-test-id="goBack"
                onClick={onCancelClick}
              >
                {cancelButtonText}
              </ForgetPasswordText>
            )}
          </ButtonWrapper>
        </ContentWrapper>
      </ForgotPasswordContainer>
    </MainWrapper>
  );
};
