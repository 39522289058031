import { Box, Drawer, styled } from "@material-ui/core";
import React, { FC } from "react";
import SidebarLink from "./SidebarLink";
import {
  dashboardImg,
  dashboardSelectedImg,
  logoutImg,
  settingsImg,
  settingsSelectedImg,
  logoutSelectedImg
} from "./assets";

export interface Props {
  navigation: any;
  handleClose: () => void;
  mobileOpen: boolean;
  handleLogoutClick?: () => void;
}

export const SidebarDrawer = styled(Drawer)({
  "& .MuiDrawer-paper": {
    position: "unset",
    top: 175,
    left: 40,
    width: "258px",

    border: "none",
    height: "unset",

    "@media(max-width: 900px)": {
      position: "fixed",
      top: 75,
      left: 0,
      width: "100%",
      height: "calc(100vh - 75px)",

      border: "none",
    },
    "@media(max-width: 550px)": {
      top: "48px",
    }
  },
});

export const LinksBorderWrapper = styled(Box)({
  padding: "1px",
  background: "linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 70%)",
  // background: "white",
  borderRadius: "8px",
});

export const LinksWrapper = styled(Box)({
  padding: "23px",
  display: "flex",
  gap: "10px",
  flexDirection: "column",
  backgroundColor: "white",
  borderRadius: "7px",
});

const Sidebar: FC<Props> = ({
  handleClose,
  mobileOpen,
  handleLogoutClick,
  navigation,
}) => {
  const isSelected = (path: string) => {
    const { pathname } = window.location;
    return pathname === path;
  };
  const handleNavigation = (pathKey: string, path: string) => {
    if (isSelected(path)) {
      return;
    }

    navigation.navigate(pathKey);
  };

  const buttonsList = [
    {
      name: "Dashboard",
      img: <img src={dashboardImg} alt="dashboar"/>,
      selectedImg: <img src={dashboardSelectedImg} alt="dashboard"/>,
      isSelected: isSelected("/dashboard"),
      onClick: () => handleNavigation("LandingPage", "/dashboard"),
    },
    {
      name: "Profile settings",
      img: <img src={settingsImg} alt="setting" />,
      selectedImg: <img src={settingsSelectedImg} alt="setting"/>,
      isSelected: isSelected("/profile"),
      onClick: () => handleNavigation("UserProfileAdvance", "/profile"),
    },
    {
      name: "Logout",
      img: <img src={logoutImg} alt="logout"/>,
      selectedImg: <img src={logoutSelectedImg} alt="logout"/>,
      isSelected: isSelected("/profile/logout"),
      onClick: handleLogoutClick,
    },
  ];
  return (
    <SidebarDrawer
      variant="persistent"
      anchor="left"
      onClose={handleClose}
      open={mobileOpen}
      ModalProps={{ keepMounted: true }}
    >
      <LinksBorderWrapper>
        <LinksWrapper>
          {buttonsList.map((btn) => (
            <SidebarLink
              data-test-id={`nav/${btn.name}`}
              key={btn.name}
              title={btn.name}
              image={btn.img}
              isSelected={btn.isSelected}
              onClick={btn.onClick}
              selectedImage={btn.selectedImg}
            />
          ))}
        </LinksWrapper>
      </LinksBorderWrapper>
    </SidebarDrawer>
  );
};

export default Sidebar;
