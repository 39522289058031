import React from "react";
import ForgotPasswordContoller, { Props } from "./ForgotPasswordController.web";
import { ForgotPasswordWrapper } from "./ForgotPasswordWrapper";
import { imgKey } from "./assets";
import { Box } from "@material-ui/core";
import {
  FieldBox,
  LabelText,
  LoginBox,
  TextFieldCustom,
} from "../../email-account-login/src/EmailAccountLoginBlock.web";

export default class ForgotPassword extends ForgotPasswordContoller {
  constructor(props: Props) {
    super(props);

    this.handleInput = this.handleInput.bind(this);
    this.handleBackToLogin = this.handleBackToLogin.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <ForgotPasswordWrapper
        title="Forgot your password?"
        titleImage={imgKey}
        subtitile="No worries, we’ll send you reset instructions"
        titleImageAlt={"key"}
        onSubmitClick={this.handleSubmit}
        onCancelClick={this.handleBackToLogin}
        submitButtonText={"Send a recovery link"}
        cancelButtonText={"Back to login"}
        showError={this.state.showError}
        errorMessage={this.state.error}
        isFetching={this.state.isFetching}
        labelContainerClasses="forgot-password"
      >
        <Box width={"100%"}>
          <LoginBox>
            <FieldBox>
              <LabelText style={{ textAlign: "left" }}>Email</LabelText>
              <TextFieldCustom
                id="outlined-basic"
                data-test-id="txtInputEmail"
                name={"email"}
                placeholder={"Enter your email"}
                variant="outlined"
                type="text"
                error={this.state.showError}
                value={this.state.email}
                onChange={(e) => this.handleInput(e.target.value)}
              />
            </FieldBox>
          </LoginBox>
        </Box>
      </ForgotPasswordWrapper>
    );
  }
}
