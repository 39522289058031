Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.httpGetMethod ="GET";

exports.btnExampleTitle = "CLICK ME";
exports.cancel = "Cancel";
exports.continue = "Continue";
exports.surveyName = "Survey Name *";
exports.propertyName = "Property Owner *";
exports.surveyTemplate = "Survey Template";
exports.createSurveyApiEndPoint = "bx_block_survey/users_survey";
exports.profilepicApiEndPoint = "account_block/accounts/"
exports.logoutParaOne = "Are you sure you want to log out";
exports.logoutParaTwo = "of your account?";
exports.logout = "Log Out";
exports.searchError = "Nothing Found, please try other search criteria";
exports.close = "Close";
exports.copyEmail = "Revoke existing access";
exports.sendMail = "Send";
exports.doneMessage = "Done";
exports.linkSuccessMessage = "Your link has been successfully sent to";
exports.tooltipMessage = "Selecting this option will generate a new shareable link, and all existing access will be revoked.";
exports.generateInitialLinkEndPoint = "bx_block_survey/link_share/generate_link";
exports.linkShareEmailEndPoint = "bx_block_survey/link_share/send_link";
exports.emailRegx = /^[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
exports.revokeAccessCopyEndPoint = "bx_block_survey/link_share/revoke_access";
exports.downloadZipFileEndPoint = "bx_block_survey/user_survey_media/download_uploaded_files";
exports.saveReportAsPdfEndPoint = "bx_block_saveaspdf2/survey_pdf/guest_survey_pdf_details?guest_survey_id=";
exports.createdDatePdf = "Date created";
exports.lastEditedPdf = "Last edited";
exports.createdByPdf = "Created by";
exports.ownerPdf = "Property Owner";
exports.reviewReport = "Review report as PDF";
exports.savePdfReportUrl = "SaveAsPdf/";

// Customizable Area End