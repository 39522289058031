import React, { FC } from "react";
import {
  FieldBox,
} from "../../email-account-login/src/EmailAccountLoginBlock.web";
import { TextInputField, FieldLabel } from "./TextInput.web";
import { Box } from "@material-ui/core";

interface Props {
  handleChangePhone: (e: any) => void;
  handleChangeCode: (e: any) => void;

  handleBlurPhone: (e: any) => void;
  handleBlurCode: (e: any) => void;

  error: any;
  phoneNumber: string;
  countryCode: string;
}

export const PhoneInput: FC<Props> = ({
  error,
  phoneNumber,
  countryCode,
  handleChangePhone,
  handleChangeCode,
  handleBlurCode,
  handleBlurPhone,
}) => {
  return (
    <FieldBox style={{ marginBottom: 0 }}>
      <FieldLabel>Phone Number *</FieldLabel>
      <Box display={"flex"} justifyContent={"flex-start"} flexDirection={"row"}>
        <TextInputField
          style={{ maxWidth: "112px" }}
          id="outlined-basic"
          data-test-id="countryCodeInput"
          name={"countryCode"}
          placeholder="(000)"
          variant="outlined"
          type="number"
          value={countryCode}
          onChange={handleChangeCode}
          onBlur={handleBlurCode}
          error={!!error}
        />
        <TextInputField
          style={{ marginLeft: "18px" }}
          id="outlined-basic"
          data-test-id="phoneNumberInput"
          name={"phoneNumber"}
          placeholder="00000000"
          variant="outlined"
          type="number"
          value={phoneNumber}
          onChange={handleChangePhone}
          onBlur={handleBlurPhone}
          error={!!error}
        />
      </Box>
    </FieldBox>
  );
};
