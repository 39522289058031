// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const downArrow = require("../assets/thenounproject.com.png");
export const mainLogo = require("../assets/logo.png");
export const backArrow = require("../assets/backArrow.png");
export const hamburger = require("../assets/hamburger.png");
export const fullSize = require("../assets/zoomOut.png");
export const download = require("../assets/download.png");
export const errorLogo = require("../assets/alertLogo.svg");
export const back = require("../assets/back.png");
export const next = require("../assets/next.png");

// Customizable Area End
