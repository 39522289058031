import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  ScrollView,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  ActivityIndicator,
} from "react-native";
import CheckBox from "../../../components/src/CustomCheckBox";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { FlatList } from "react-native-gesture-handler";
import moment from "moment";
import { styled, Box, Typography, Button } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { backgroundImg } from "../../email-account-login/src/assets";
import { mainLogo, hamburger } from "../src/assets";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <BackgroundImage>
        <LogoTermImageBox>
        <LogoTermsImage src={mainLogo} alt="logo" /> 
         </LogoTermImageBox>
        <MainTermsCondition>
          <TermsConditionContainer>
            <CloseIcon>
              <Close onClick={this.goBackLogin} data-testid="termsConditionCloseBtn" />
            </CloseIcon>
            <DetailsTermsCondition>
              <DetailsContainer>
                <TermConditionHeading>
                 {configJSON.termCondition}
                </TermConditionHeading>
                <TermsConditionDetails>
                  <TermsConditionParagraph
                   dangerouslySetInnerHTML={{
                      __html: this.state.termsConditionsData     
                    }}>
                  </TermsConditionParagraph>
                </TermsConditionDetails>
              </DetailsContainer>
            </DetailsTermsCondition>
          </TermsConditionContainer>
        </MainTermsCondition>
      </BackgroundImage>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const LogoTermImageBox = styled(Box)({
  marginLeft: "42px",
  marginBottom: "60px",
  marginTop: "7px",
  maxHeight: '60px',
  width: "100%", 
  display:"flex",
  justifyContent:"flex-start",
  margin: 'auto',
  "@media (max-width: 1320px)": {
    marginLeft: "25px"
  },
  "@media(max-width:767px)":{
    alignItems: "center",
    marginLeft: "0px",
    background: "#FFF",
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
    marginBottom: "25px"
  }
});

const BackgroundImage = styled("div")({
  background: `url(${backgroundImg})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  "@media(max-width:767px)":{
    background: "none"
  }
});

const MainTermsCondition = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  marginBottom: "120px",
  "@media(max-width:767px)":{
    flexDirection: "column",
    marginBottom: "0px"
  }
});

const TermsConditionContainer = styled(Box)({
  width: "80%",
  border: "1px solid #C0C8CD",
  boxShadow: "0px 2px 8px 0px #00000014",
  borderRadius: "8px",
  padding: "10px",
  backgroundColor: "#ffffff",
  "@media(max-width:767px)":{
  width: "100%",
  border: "none",
  boxShadow: "none",
  borderRadius: "0px",
  padding: "0px"
  }
});

const CloseIcon = styled(Box)({
  display:"flex",
  justifyContent:"flex-end",
  padding:"20px",
  cursor:"pointer",
  "@media(max-width:767px)":{
    display: "none"
  }
});

const DetailsTermsCondition = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  marginBottom: "65px",
  marginTop:"20px",
  "@media(max-width:767px)":{
    marginBottom: "45px"
  }
});

const DetailsContainer = styled(Box)({
  width: "80%",
  "@media(max-width:767px)":{
    width: "95%"
  }
});

const TermConditionHeading = styled(Typography)({
  fontSize: "24px",
  fontWeight: 600,
  fontFamily: "Poppins",
  marginBottom: "25px",
  "@media(max-width:767px)": {
    padding: "0px 17px 0px 17px",
    marginBottom: "15px"
  },
  "@media(max-width:465px)": {
    fontSize: "20px"
  }
});

const TermsConditionDetails = styled(Box)({
  border: "1px solid #ffffff",
  height: "380px",
  overflowY: "scroll",
  overflowX: "hidden",
  padding: "10px",
  boxShadow: "0px 8px 32px 0px #0000000F inset,0px 4px 8px 0px #00000008 inset",
  "@media(max-width:767px)": {
    boxShadow: "none",
    padding: "0px 17px 0px 17px",
    border: "none",
    height: "500px",
  },
});

const TermsConditionParagraph = styled(Typography)({
  fontSize:"16px",
  fontFamily: "Poppins"
});

const LogoTermsImage = styled("img")({
  flexShrink: 0,
  width: "118.723px",
  height: "60px",
  "@media(max-width:767px)": {
    margin: "auto",
    textAlign: "center",
    width: "101px",
    height: "56px",
    padding: "0px 0px 10px 0px"
  },
  "@media(max-width:425px)": {
    width: "71px",
    height: "36px"
  }
});
// Customizable Area End
