import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  styled,
  Grid
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
//@ts-ignore
import Printer, { print } from "react-pdf-print";
//@ts-ignore
import Pdf from "react-to-pdf";

const ref = React.createRef();

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import { mainLogoScio, checkboxIcon, radioIcon } from "./assets";
import {SurveyReportSection, SurveyReportSubSectionDetail, SurveyReportSubSections, MediaReportFile} from "./SaveAsPdfController";
// Customizable Area End

import SaveAsPdfController, { Props, configJSON } from "./SaveAsPdfController";

export default class SaveAsPdf extends SaveAsPdfController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      <ButtonContainer >
        <BackButton data-test-id = "backButtonId" onClick={this.handleBackToDashboard}>{configJSON.backButton}</BackButton>
      <DownloadButton data-test-id = "downloadReportId" onClick={this.handleDownloadReportFile}>{configJSON.downloadPdfFile}</DownloadButton>
      </ButtonContainer>
      <Box width={"100%"} display={"flex"} flexDirection={"column"}>
        <div id={this.props.matchId}>
          <MainPdfContainer>
            <ImageDiv>
              <ImgFile src={mainLogoScio} alt="logo" />
            </ImageDiv>
            <PdfHeading data-test-id = "surveyReportId">{this.state.sectionPdfDetails.data.attributes.survey_name}</PdfHeading>
            <HrContainer>
            <HrBorderLines/>
            </HrContainer>
            <OuterPdf>
              <PdfBasicDetails>
                <BasicInfo>
                  <BasicPara>{configJSON.createDatePdf}</BasicPara>
                  <BasicPara>{configJSON.lastEditPdf}</BasicPara>
                  <BasicPara>{configJSON.createByPdf}</BasicPara>
                  <BasicPara>{configJSON.ownerNamePdf}</BasicPara>
                </BasicInfo>
                <BasicInfo>
                  <BasicParaNew>{this.handleFormateDate(this.state.sectionPdfDetails.data.attributes.date_created)}</BasicParaNew>
                  <BasicParaNew>{this.handleFormateDate(this.state.sectionPdfDetails.data.attributes.last_edited)}</BasicParaNew>
                  <BasicParaNew>{this.state.sectionPdfDetails.data.attributes.created_by}</BasicParaNew>
                  <BasicParaNew>{this.state.sectionPdfDetails.data.attributes.owner}</BasicParaNew>
                </BasicInfo>
              </PdfBasicDetails>
            </OuterPdf>
          </MainPdfContainer>
            {this.state.sectionPdfDetails.data.attributes.user_survey_sections.map((saveDetails: SurveyReportSection, saveIndex: number) => {
              return (
                <SectionContainer key={saveIndex}>
                  <SectionDetailedContainer>
                    <SectionHeading>{saveDetails.user_survey_section_name}</SectionHeading>
                  </SectionDetailedContainer>
                  <HrContainer>
                  <HrBorderLines />
                  </HrContainer>
                  <MainSectionDetails>
                    {saveDetails.is_survey_subsection_present ? (
                      saveDetails.user_survey_sub_sections.map((subSection: SurveyReportSubSections, subSectionIndex: number) => (
                        <SectionMainContainer key={subSectionIndex}>
                          <SubSectionHeading data-test-id="subSectionId">{subSection.user_survey_sub_section_name}</SubSectionHeading>
                          {subSection.user_survey_sub_section_details.map((items: SurveyReportSubSectionDetail, itemsIndex: number) => (
                            <React.Fragment key={itemsIndex}>
                              <SectionDetails data-test-id="fieldSectionId">{items.field_name}</SectionDetails>
                              {this.handlePdfFieldsTypeFormates(items) && (
                                <RadioButtonSection>
                                  {items.field_value_multiple.map((checkItem: string, indexCheckItem: number) => (
                                    <ImageCheckBox key={indexCheckItem}>
                                      <ImgCheckBox src={checkboxIcon} alt="checkbox" />
                                      <SectionValueDetails data-test-id="sectionCheckboxId">{checkItem}</SectionValueDetails>
                                    </ImageCheckBox>
                                  ))}
                                </RadioButtonSection>
                              )}
                              {this.handleRadioTypeFormat(items) && (
                                <RadioButtonSection>
                                  <img src={radioIcon} alt="radio" height={12} width={12} />
                                  <SectionValueDetails data-test-id="valueSectionId">{items.field_value}</SectionValueDetails>
                                </RadioButtonSection>
                              )}
                              {this.handleSelectFormat(items) && (
                                <SectionSelectValueDetails data-test-id="valueSectionId">{items.field_value}</SectionSelectValueDetails>
                              )}
                              {this.handleAllFieldsTypeFormat(items.field_type) && (
                                <SectionValueDetails data-test-id="valueSectionId">{items.field_value}</SectionValueDetails>
                              )}
                              <ImageSaveMainContainer>
                                {items.photos.length > 0 && (
                                  <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="space-between">
                                    {items.photos.map((subPhotodetails: MediaReportFile, subPhotosIndex: number) => (
                                      <Box width="calc(50% - 8px)" marginBottom={2} key={subPhotosIndex}>
                                        <Box display="flex" flexDirection="column">
                                          <ImageContainer marginBottom={1}>
                                            <ImageSaveFiles
                                              src={subPhotodetails.url}
                                              alt={subPhotodetails.filename}
                                            />
                                          </ImageContainer>
                                          <FilesSaveDescription>
                                            {subPhotodetails.description}
                                          </FilesSaveDescription>
                                        </Box>
                                      </Box>
                                    ))}
                                  </Box>
                                )}
                              </ImageSaveMainContainer>
                            </React.Fragment>
                          ))}
                        </SectionMainContainer>
                      ))
                    ) : (
                      saveDetails.user_survey_sub_sections.map((section: SurveyReportSubSections, sectionsIndex: number) => (
                      <SectionMainContainer key={sectionsIndex}>
                        {section.user_survey_sub_section_details.map((sectionItems: SurveyReportSubSectionDetail, sectionitemsIndex: number) => (
                            <React.Fragment key={sectionitemsIndex}>
                              <SectionDetails data-test-id="fieldSectionId">{sectionItems.field_name}</SectionDetails>
                              {this.handlePdfFieldsTypeFormates(sectionItems) && (
                                <RadioButtonSection>
                                  {sectionItems.field_value_multiple.map((checkItem: string, indexCheckItem: number) => (
                                    <ImageCheckBox key={indexCheckItem}>
                                      <ImgCheckBox src={checkboxIcon} alt="checkbox" />
                                      <SectionValueDetails data-test-id="sectionCheckboxId">{checkItem}</SectionValueDetails>
                                    </ImageCheckBox>
                                  ))}
                                </RadioButtonSection>
                              )}
                              {this.handleRadioTypeFormat(sectionItems) && (
                                <RadioButtonSection>
                                  <img src={radioIcon} alt="radio" height={12} width={12} />
                                  <SectionValueDetails data-test-id="valueSectionId">{sectionItems.field_value}</SectionValueDetails>
                                </RadioButtonSection>
                              )}
                              {this.handleSelectFormat(sectionItems) && (
                                <SectionSelectValueDetails data-test-id="valueSectionId">{sectionItems.field_value}</SectionSelectValueDetails>
                              )}
                              {this.handleAllFieldsTypeFormat(sectionItems.field_type) && (
                                <SectionValueDetails data-test-id="valueSectionId">{sectionItems.field_value}</SectionValueDetails>
                              )}
                              <ImageSaveMainContainer>
                                {sectionItems.photos.length > 0 && (
                                  <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="space-between">
                                    {sectionItems.photos.map((sectionPhotodetails: MediaReportFile, photosIndex: number) => (
                                      <Box width="calc(50% - 8px)" marginBottom={2} key={photosIndex}>
                                        <Box display="flex" flexDirection="column">
                                          <ImageContainer marginBottom={1}>
                                            <ImageSaveFiles
                                              src={sectionPhotodetails.url}
                                              alt={sectionPhotodetails.filename}
                                            />
                                          </ImageContainer>
                                          <FilesSaveDescription>
                                            {sectionPhotodetails.description}
                                          </FilesSaveDescription>
                                        </Box>
                                      </Box>
                                    ))}
                                  </Box>
                                )}
                              </ImageSaveMainContainer>
                            </React.Fragment>
                          ))}
                      </SectionMainContainer>
                      ))
                    )}
                </MainSectionDetails>
              </SectionContainer>
            )
          })}
        </div>
      </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainPdfContainer = styled(Box)({
  width: "100%",
  marginBottom: "90px",
  marginTop: "20px"
});

const PdfHeading = styled(Typography)({
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "40px",
  fontWeight: 600,
  color: "#181818",
  marginTop: "20px",
  "@media(max-width: 992px)": {
    fontSize: "30px"
  },
  "@media(max-width: 435px)": {
     fontSize: "12px",
     marginTop: "40px"
  },
  "@media(max-width: 375px)": {
    marginTop: "20px"
 }
});

const BasicPara = styled(Typography)({
  fontSize: "16px",
  fontWeight: 600,
  color: "#424C53",
  fontFamily: "Poppins",
  "@media(max-width: 992px)": {
    fontSize: "14px"
  },
  "@media(max-width: 435px)": {
     fontSize: "10px"
  },
  "@media(max-width: 375px)": {
    fontSize: "6px"
 }
});

const BasicParaNew = styled(Typography)({
  fontSize: "16px",
  fontFamily: "Poppins",
  color: "#424C53",
  fontWeight: 400,
  "@media(max-width: 435px)": {
      fontSize: "10px"
  },
  "@media(max-width: 992px)": {
      fontSize: "14px"
  },
  "@media(max-width: 375px)": {
    fontSize: "6px"
 }
});

const PdfBasicDetails = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "15px",
  width: "20%",
  marginTop: "20px",
  "@media(max-width: 992px)": {
    width: "80%"
  }
});

const BasicInfo = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "15px"
});

const OuterPdf = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%"
});

const ImageDiv = styled(Box)({
  textAlign: "center",
  height: "130px"
});

const ImgFile = styled("img")({
  height: "100%",
  objectFit: "contain", 
  width: "200px",
  "@media(max-width: 992px)": {
    width: "100px",
    height: "80px"
  },
  "@media(max-width: 435px)": {
    width: "50px",
    height: "30px"
  }
});

const SectionContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%"
});

const SectionDetailedContainer = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "30px"
});

const SectionHeading = styled(Typography)({
  fontSize: "14px",
  fontFamily: "Poppins",
  fontWeight: 600,
  color: "#181818",
  width: "80%",
  "@media(max-width: 992px)": {
    fontSize: "20px"
  },
  "@media(max-width: 435px)": {
     fontSize: "8px"
  }
});

const SectionMainContainer = styled(Box)({
  width: "80%",
  display: "flex",
  flexDirection: "column",
  "@media(max-width: 435px)": {
    marginTop: "10px"
  }
});

const SectionDetails = styled(Typography)({
  fontFamily: "Poppins",
  fontSize: "12px",
  color: "#424C53",
  fontWeight: 500,
  wordBreak: "break-all",
  "@media(max-width: 992px)": {
    fontSize: "8px"
  },
  "@media(max-width: 375px)": {
    fontSize: "6px"
 }
});

const MainSectionDetails = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "15px"
});

const SubSectionHeading = styled(Typography)({
  fontWeight: 400,
  fontSize: "13px",
  fontFamily: "Poppins",
  color: "#181818",
  "@media(max-width: 992px)": {
    fontSize: "14px"
  },
  "@media(max-width: 435px)": {
     fontSize: "6px"
  }
});

const ImageSaveMainContainer = styled(Box)({
  width: "100%",
  marginTop: "10px"
});

const ImageSaveFiles = styled('img')({
  width: "100%",
  borderRadius: "8px",
  height: "100%",
  objectFit: "contain",
  "@media(max-width: 425px)": {
      height: "160px",
      width: "80%"
  }
});

const ImageContainer = styled(Box)({
  height: "200px",
});

const FilesSaveDescription = styled(Typography)({
  color: "#191C1E",
  fontSize: "14px",
  fontFamily: "Poppins",
  fontWeight: 500,
  wordBreak: "break-all",
  "@media(max-width: 992px)": {
   fontSize: "10px"
  },
  "@media(max-width: 435px)": {
     fontSize: "6px"
  }
});

const SectionValueDetails = styled(Typography)({
  fontSize: "16px",
  fontWeight: 500,
  color: "#191C1E",
  fontFamily: "Poppins",
  wordBreak: "break-all",
  "@media(max-width: 992px)": {
    fontSize: "8px"
  },
  "@media(max-width: 375px)": {
    fontSize: "6px"
  }
});

const SectionSelectValueDetails = styled(Button)({
  borderRadius: "100px",
  background: "#E5E5E5",
  color: "#191C1E",
  width: "fit-content",
  fontFamily: "Poppins",
  textTransform: "none",
  fontSize: "10px",
  marginTop: "5px"
});

const RadioButtonSection = styled(Box)({
  display: "flex",
  gap: "5px",
  alignItems: "center"
});

const HrBorderLines = styled("hr")({
  width: "90%", 
  borderColor: "#f5f0f01f",
  textAlign: "center"
});

const ImgCheckBox = styled("img")({
  borderRadius: "2px",
  width: "12px",
  height: "12px"
});

const ImageCheckBox = styled(Box)({
  display: "flex",
  gap: "5px",
  alignItems: "center"
});

const DownloadButton = styled(Button)({
  background: "linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 100%)",
  color: "#FFF",
  borderRadius: "8px",
  height: "44px",
  width: "235px",
  fontSize: "16px",
  fontWeight: 500,
  padding: "10px 16px 10px 16px",
  lineHeight: "24px",
  fontFamily: "Poppins",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 100%)",
    color: "#FFF"
  },
  "@media(max-width: 992px)": {
    fontSize: "14px"
  },
  "@media(max-width: 550px)": {
    height: "45px",
    width: "100%",
    padding: "0px"
  }
});

const BackButton = styled(Button)({
  display: "none",
  backgroundColor: "rgb(237,245,253)",
  fontFamily: "Poppins",
  color: "#0090CF",
  fontWeight: 500,
  borderRadius: "8px",
  textTransform: "capitalize",
  height: "44px",
  fontSize: "16px",
  width: "235px",
  padding: "10px 16px 10px 16px",
  lineHeight: "24px",
  "&:hover": {
    backgroundColor: "rgb(237,245,253)",
    color: "#0090CF"
  },
  "@media(max-width: 992px)": {
    fontSize: "14px"
  },
  "@media(max-width: 550px)": {
    height: "45px",
    width: "100%",
    padding: "0px"
  }
});

const ButtonContainer = styled(Box)({
  display: "flex",
  gap: "30px",
  margin: "10px 0px 0px 10px",
  "@media print": {
    display: "none"
  }
});

const HrContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%"
});
// Customizable Area End