import React, { ChangeEventHandler } from "react";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  styled,
} from "@material-ui/core";
import { BlockComponent } from "../../../framework/src/BlockComponent";

interface Props {
  handleChange: ChangeEventHandler<HTMLInputElement>;
  handleBlur: () => void;
  options: string[];
  selectedOptions: string[];
  hasError: boolean;
}

const CheckboxUnselected = styled(CheckBoxOutlineBlankOutlinedIcon)({
  fill: "rgba(71, 85, 105, 1)",
  "@media(max-width: 900px)":{
    fill: "#CBD5E1",
  }
});
const CheckboxSelected = styled(CheckBoxOutlinedIcon)({
  fill: "rgba(71, 85, 105, 1)",
});

const CheckboxGroup = styled(FormGroup)({
  border: "1px solid rgba(229, 229, 229, 1)",
  borderRadius: "8px",
  color: "rgba(71, 85, 105, 1)",
  padding: "4px 50px 4px 16px",
  display: "grid",
  gridTemplateColumns:"repeat(3,1fr)",
  justifyContent: "space-between",
  flexDirection: "row",
  gap: '10px',
  width: 'fit-content',
  boxSizing: "border-box",

"@media(max-width: 650px)":{ 
  "& > label > span":{
    fontSize: '12px !important',
  },
}
});

const CustomFieldCheckbox = styled(Checkbox)({
  "&:hover": {
    background: "none",
  },
});

const CheckboxLabel = styled(FormControlLabel)({
  margin: 0,
});

type CustmomCheckboxProps = Omit<
  Partial<Props>,
  "options" | "selectedOptions"
> & {
  option: string;
  isSelected: boolean;
};
export class CustmomCheckbox extends BlockComponent<
  CustmomCheckboxProps,
  {},
  {}
> {
  constructor(props: CustmomCheckboxProps) {
    super(props);
  }
  render(): React.ReactNode {
    const { option, handleBlur, handleChange, isSelected } = this.props;
    return (
      <CustomFieldCheckbox
        checkedIcon={<CheckboxSelected />}
        icon={<CheckboxUnselected />}
        value={option}
        disableRipple
        disableFocusRipple
        disableTouchRipple
        onChange={handleChange}
        onBlur={handleBlur}
        checked={isSelected}
      />
    );
  }
}

export class CustomFieldCheckboxes extends BlockComponent<Props, {}, {}> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { handleBlur, handleChange, options, selectedOptions, hasError } = this.props;

    function getGroupStyle() {
      if (window.innerWidth < 900) {
        if (options.length > 1) {
          return "100%"
        } else {
          return "fit-content"
        }
      } else {
        return "fit-content"
      }
    }

    function getBorderColor() {
      if(hasError){
        return "#F87171";
      }
    }

    return (
      <CheckboxGroup style={{ width: getGroupStyle(), borderColor: getBorderColor() }} row>
        {options.map((option) => (
          <CheckboxLabel
            key={option}
            label={option}
            control={
              <CustmomCheckbox
                handleBlur={handleBlur}
                handleChange={handleChange}
                option={option}
                isSelected={selectedOptions.includes(option)}
              />
            }
          />
        ))}
      </CheckboxGroup>
    );
  }
}
