import { ChangeEventHandler } from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import storage from "../../../framework/src/StorageProvider.web";
import { sendRequest } from "../../forgot-password/src/utils";
import { handleResponseMessage } from "../../utilities/src/handle-response-message";
import { debounce } from "@material-ui/core";
export interface GlobalSearchBarProps {
  handleSearch: (option: string) => void;
  navigation: any;
  handleError: (err: string) => void;
}

interface GlobalSearchBarState {
  selectedOption?: string;
  options: string[];
}

export default class GlobalSearchBarController extends BlockComponent<
  GlobalSearchBarProps,
  GlobalSearchBarState,
  {}
> {
  getListOfAllFieldsMessageID = "";
  constructor(props: GlobalSearchBarProps) {
    super(props);
    this.state = { selectedOption: undefined, options: [] };

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  receive(from: string, message: Message): void {
    if (
      this.getListOfAllFieldsMessageID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const messageData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!messageData) {
        this.props.handleError("An unexpeted error occured");
        return;
      }

      handleResponseMessage({
        responseJson: messageData.search_field_details,
        errorJson: messageData.errors,
        onFail: () => this.props.handleError(messageData.errors[0]),
        onSuccess: () =>
          this.setState({ options: messageData.search_field_details }),
      });
    }
  }

  handleChange = (_: React.ChangeEvent<{}>, value: string | undefined) => {
    this.setState(
      {
        selectedOption: value,
      },
      () => {
        this.search();
        this.clearSelectedOption();
      }
    );
  };

  handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    if (!value) {
      this.clearOptions();
      return;
    }
    this.fetchAvailbaleFields(value);
  };

  clearOptions = () => this.setState({ options: [] });

  search = () => {
    if (this.state.selectedOption) {
      this.props.handleSearch(this.state.selectedOption);
    }
  };

  get fieldValue() {
    if (!this.state.selectedOption) {
      return null;
    }
    return this.state.selectedOption;
  }

  private fetchAvailbaleFields = debounce(async (searchQuery: string = "") => {
    const token = await storage.get("auth-token");
    const templateID = this.extractFromQueryParams("templateId");
    const surveyID = this.extractFromQueryParams("surveyId");

    const endpoint = `/bx_block_survey/survey_advanced_search/survey_report_search?survey_template_id=${templateID}&user_survey_id=${surveyID}&search_query=${searchQuery}`;
    sendRequest(
      endpoint,
      "GET",
      (id) => {
        this.getListOfAllFieldsMessageID = id;
      },
      null,
      { token }
    );
  }, 300);

  private extractFromQueryParams = (param: string) => {
    const queryParam = this.props.navigation.getParam(param);
    return queryParam;
  };

  private clearSelectedOption = () => {
    return setTimeout(() => this.setState({ selectedOption: undefined }), 100);
  };
}
