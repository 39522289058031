import React from "react";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { keyimage,closeModalIcon ,eyeOff,greenCheckCircle,redCheckCircle} from "./assets";
import Visibility from "@material-ui/icons/Visibility";
import { Logo } from "./Logo";
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  styled,
  TextField,
  Dialog,
  Modal
} from "@material-ui/core";

const OverlayBox = styled(Box)({
  right: 0,
  left: 0,
  background: 'gray',
  opacity: 0.5,
  filter: 'blur(4px)',
  position: 'absolute',
  top: 0,
  bottom: 0,
  
})


const CustomDialog = styled(Dialog)({
  "& .MuiDialog-paperFullWidth": {
    backgroundColor: "#FFF",
    padding: "0px 47px 0px 21px",

    borderRadius: "8px",
   maxWidth:"814px",
   width: '100%',
    boxSizing:"border-box",
    "@media (max-width: 600px)": {
      height:"unset",
      maxHeight:"unset",
      padding:"20px"
    
      },
      "@media (max-width: 450px)": {
                padding:"10px"
        },
  },
});

const ErrorBox = styled(Box)({
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
  gap:"68px",
   "@media (max-width: 550px)":{
      justifyContent:"center",
      width:"100%",
      height:"42px",
      padding: "0px",
      borderRadius: "0px",
      marginBottom:"-42px",
   },
   "@media (max-width: 320px)":{
      width:"100%",
      padding: "0px",
      borderRadius: "0px",
      marginBottom:"-42px",
   },

})

const ErrorLoginAlert = styled(Box)({
  minWidth:"536px",
  padding: "12px 10px",
  borderRadius: "4px",
  borderTop: "4px solid #DC2626",
  backgroundColor: "#FEE2E2",
  "@media (max-width: 960px)":{
    minWidth:"77%",
   },
   "@media (max-width: 550px)":{
      width:"100%",
      display:"flex",
      alignItems:"center",
   },
   "@media (max-width: 350px)":{
      width:"100%",
   },
   "@media (max-width: 295px)":{
      height:"14px"
   }
});

const ErrorLoginText = styled(Typography)({
  color: "#DC2626",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "18px",
  "@media (max-width: 350px)":{
    fontSize:"10px",
   }
});



const CloseDiv = styled("div")({
  display: "flex",
  justifyContent: "flex-end",

});

const CustomCloseIcon = styled("img")({
  cursor:"pointer",
  position:"absolute",
  right: 56,
  top: 21,
  "@media (max-width: 600px)":{
    right:"-8px",
    top:"8px"
   },
});

const MiddleBox = styled(Box)({
  display:"flex",
  flexDirection:"column",
  justifyContent:"space-between",
})

const Imagediv = styled("div")({
  justifyContent: "center",
  textAlign:"center",
  "@media (max-width: 600px)":{
    position:"relative"
   },
   "@media (max-width: 550px)": {
    marginTop:"60px",
    marginBottom:"20px",
    margin:"0px",
    width:"40px",
    height:"40px",
    alignSelf:"center"
  },
  "@media (max-width: 350px)": {
    marginTop:"35px",
    width:"30px",
    height:"30px",
  },
});

const Maindiv = styled("div")({
  flexShrink: 1,
  backgroundColor: "white",
  padding: "50px 90px 0px 90px",
  boxSizing: "border-box", 
  "@media (max-width: 1199px)": {
  padding: "10px 50px 0px 50px",
  },
  "@media (max-width: 900px)": {
  padding: "10px",
  },
});

const Buttondiv = styled("div")({
  marginTop: "178px",
  marginBottom:"60px",
  display: "flex", 
  justifyContent: "center",
  "@media (max-width: 1199px)": {
    marginTop: "40px",
    },
  "@media (max-width: 550px)": {
    margin:"0px 20px 40px 20px",
    alignItems:"flex-end",
  },
  "@media (max-width: 350px)": {
    margin:"0px 20px 30px 20px",
    alignItems:"flex-end",
  }
});

const CancelButton = styled(Button)({
  flex: "1",
  maxWidth:"258",
  width:"100%",
  height: "44px",
  padding: "10px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  flexShrink: 0,
  borderRadius: "8px",
  background: "var(--scio-services-light-blue-50-light-blue, #EBF5FE)",
  marginLeft: "10px",
  textTransform: "none",
  color:'#0090CF'
});
const EyeOffIcon = styled("img")({
})


const ConfirmButton = styled(Button)({
  maxWidth:"258",
  width:"100%",
  flex: "1", 
  height: "44px",
  padding: "10px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  flexShrink: 0,
  borderRadius: "8px",
  background: "var(--scio-services-gradient, linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 100%))",
  marginLeft: "20px",
  textTransform: "none",
  color:'#FFFFFF',
  "@media (max-width: 550px)": {
    margin:"0px 20px 40px 20px",
    maxWidth:"100%",
  },
  "@media (max-width: 350px)": {
    margin:"0px 20px 30px 20px",
  }
});

const Inputdiv = styled("div")({
  marginTop: "40px",
  display: "flex", 
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "8px",
  "@media (max-width: 900px)": {
    marginTop: "20px",
  
    },
  "@media (max-width: 550px)":{
    margin:"0px 25px 20px 25px"
   }
  
});

const Heading = styled(Typography)({
  color: "var(--scio-services-basic-black, #181818)",
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "30px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  "@media (max-width: 550px)": {
    lineHeight:"45px",
    width:"210px",
    alignSelf:"center",
    marginTop:"20px",
  },
  "@media (max-width: 350px)": {
    lineHeight:"30px",
    fontSize:"22px",
    width:"180px",
  },
});

const HeadingYourPassword = styled(Typography)({
    "@media (max-width: 550px)": {
      color: "var(--scio-services-basic-black, #181818)",
      textAlign: "center",
      fontFamily: "Poppins",
      fontSize: "30px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight:"45px",
      width:"240px",
      alignSelf:"center",
      marginBottom:"40px",
    },
    "@media (max-width: 380px)": {
      marginBottom:"40px"
    },
    "@media (max-width: 350px)": {
      lineHeight:"30px",
      fontSize:"22px",
      marginBottom:"30px"
    }
});

const LabelText = styled(Typography)({
  color: "#424C53",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  marginRight: "0px", 
  alignItems: "flex-start",
  marginBottom:"0px",
  "@media (max-width: 550px)": {
    lineHeight:"12px",
    fontSize: "18px",
  },
  "@media (max-width: 350px)": {
    lineHeight:"12px",
    fontSize: "14px",
  }
});

const Textfield = styled(TextField)({
  color: "#424C53",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  width: "100%", 
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px 8px",
  },
  background: "var(--scio-services-neutral-50-neutral, #F9FAFA)",
  "@media (max-width: 550px)": {
    width: "100%",
    height:"44px",
    "& .MuiOutlinedInput-input": {
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      height:"48px"
    },
  },
  "@media (max-width: 350px)": {
    width: "100%",
    height:"38px",
    "& .MuiOutlinedInput-input": {
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      height:"38px"
    },
  },
});
export const ListItem = styled("li")({
  display: "flex",
  alignItems: "center",
  marginTop: "8px",

  color:" var(--scio-services-neutral-300-neutral, #A6B1B7)",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 300,
  lineHeight: "normal",
  "@media (max-width: 550px)": {
    marginTop: "4px",
  },
});

const CheckIcon = styled(CheckCircleIcon)({
  marginRight: "8px",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 300,
  lineHeight: "normal",

});

const GreenCheckCircle = styled("img")({
  width:"11px",
  height:"11px",
  paddingRight:"8px",
  paddingLeft:"1px",
}
  )

  const RedCheckCircle = styled("img")({
    width:"11px",
    height:"11px",
    paddingRight:"8px",
    paddingLeft:"1px",
  })


const Listul = styled("ul")({
  paddingLeft:"0px",
  marginTop:"-12px",
  "@media (max-width: 550px)":{
    marginTop:"0px",
  }
});

export const CustomIconButton = styled(IconButton)({
  color:"#A6B1B7",
  marginRight:"-18px !important",
})

const ModalMobileInnerMainBox = styled(Box)({
  height:"100vh",
  width:"100vw",
  background:"#fff",
  display:"flex",
  flexDirection:"column",
});

const LogoImageBox = styled(Box)({
  "@media (max-width: 550px)":{
    display:"flex",
    justifyContent:"center",
    height:"48px",
    alignItems:"center",
    boxShadow:"0px 0px 10px 0px lightgrey"
  }
});

const ModalInnerMainBoxSection = styled(Box)({
  height:"100%",
  display:"flex",
  flexDirection:"column",
  justifyContent:"space-between"
});

const ModalInnerSectionOne = styled(Box)({
  display:"flex",
  flexDirection:"column"
});

const ConfirmButtonMobileMainBox = styled(Box)({
  padding:"0px",
  margin:"0px",
  display:"flex",
});

const ButtonBox = styled(Box)({
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
  "@media (max-width: 550px)": {
    margin:"0px 20px 40px 20px",
    alignItems:"flex-end",
  },
  "@media (max-width: 350px)": {
    margin:"0px 20px 30px 20px",
    alignItems:"flex-end",
  }
});

const PasswordRequirementsMobileMainBox  = styled(Box)({
  marginTop:"20",
});

const listItems = [
  "At least one capital letter",
  "At least one lowercase letter",
  "At least one number",
  "Minimum character length is 8 characters",
];

interface ChangePassModalProps{
  openChangePassModal:boolean;
  newPassError:boolean;
  conNewPassError:boolean;
  handleChangePassModalClose:any;
  setNewPassword:any;
  handleClickShowNewPass:any;
  enableNewPassField:any;
  setConfNewPassword:any;
  handleClickShowConPass:any;
  newPass:any;
  enableConPassField:any;
  conNewPass:any;
  handleConfirmSubmit:any;
  changePassErrorText:string;
  changePassResponse:boolean;
  capitalLetterCheck:boolean,
  lowercaseCheck:boolean,
  atleastOneNumberCheck:boolean,
  minLengthCheck:boolean,
  checkIcon:boolean,
  size:number;
}

  
export default class ChangePasswordModal extends React.Component<ChangePassModalProps,any> {

 
 
  render() {
    const { openChangePassModal,newPassError,conNewPassError,handleChangePassModalClose,setNewPassword,
      handleClickShowNewPass,enableNewPassField,setConfNewPassword,handleClickShowConPass,
      newPass,enableConPassField,conNewPass,handleConfirmSubmit,changePassErrorText,changePassResponse,lowercaseCheck,capitalLetterCheck,atleastOneNumberCheck,minLengthCheck,checkIcon } = this.props;
      const{ size} = this.props;
      const checkCondition = checkIcon && !newPass;


      const renderErrorMessage = () => {
        return changePassResponse ? (
          <ErrorBox>
            <ErrorLoginAlert>
              <ErrorLoginText>{changePassErrorText}</ErrorLoginText>
            </ErrorLoginAlert>
          </ErrorBox>
        ) : null;
      };

      const renderPasswordRequirements = () => {
        return (
          <Inputdiv>
            <LabelText>Your password must contain:</LabelText>
            <Listul>
              {renderRequirementItem(checkCondition, capitalLetterCheck, "At least one capital letter")}
              {renderRequirementItem(checkCondition, lowercaseCheck, "At least one lowercase letter")}
              {renderRequirementItem(checkCondition, atleastOneNumberCheck, "At least one number")}
              {renderRequirementItem(checkCondition, minLengthCheck, "Minimum character length is 8 characters")}
            </Listul>
          </Inputdiv>
        );
      };

      const renderRequirementItem = (condition:any, check:any, text:any) => {
        return (
          <ListItem>
            {condition ? <CheckIcon /> : null}
            {check ? <GreenCheckCircle src={greenCheckCircle} /> : null}
            {!check && newPass ? <RedCheckCircle src={redCheckCircle} /> : null}
            {text}
          </ListItem>
        );
      };

      const renderInputField = (label:any, value:any, onChange:any, type:any, enableField:any, error:any, dataTestId:any, onClickShow:any) => {
        return (
          <Inputdiv>
            <LabelText>{label}</LabelText>
            <Textfield
              variant="outlined"
              type={enableField ? "password" : "text"}
              value={value}
              placeholder="*********"
              onChange={(e) => onChange(e.target.value)}
              error={error}
              data-test-id={dataTestId}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CustomIconButton aria-label="toggle password visibility" onClick={onClickShow} edge="end">
                      {enableField ? <EyeOffIcon src={eyeOff} /> : <Visibility />}
                    </CustomIconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Inputdiv>
        );
      };
    return (
      <>
        {openChangePassModal ? <OverlayBox></OverlayBox>:null} 
        {size> 550 ? 
      <CustomDialog
          open={openChangePassModal}
          fullWidth
          maxWidth="sm"
        >  
        
           {renderErrorMessage()}

        <CloseDiv>
          </CloseDiv>
          <MiddleBox>
          <Maindiv>
      <Imagediv> <CustomCloseIcon src={closeModalIcon}  onClick={handleChangePassModalClose}/><img src={keyimage}></img></Imagediv>
        <Heading>Change your password</Heading>
        {renderInputField(
            "New password *",
            newPass,
            setNewPassword,
            enableNewPassField ? "password" : "text",
            enableNewPassField,
            newPassError,
            "new-password-input",
            handleClickShowNewPass
          )}

          {renderInputField(
            "Confirm new password *",
            conNewPass,
            setConfNewPassword,
            enableConPassField ? "password" : "text",
            enableConPassField,
            conNewPassError,
            "connew-password-input",
            handleClickShowConPass
          )}

          {renderPasswordRequirements()}

        
        <Buttondiv>
          <CancelButton onClick={handleChangePassModalClose}>Cancel</CancelButton>
          <ConfirmButton onClick={handleConfirmSubmit}>Confirm</ConfirmButton>
        </Buttondiv>
      </Maindiv>
          </MiddleBox>
        </CustomDialog>
        :
        <Box>
          <Modal 
           open={openChangePassModal}
           >
            <ModalMobileInnerMainBox>
              <LogoImageBox>
                <Logo />
              </LogoImageBox>
              <ModalInnerMainBoxSection>
                <ModalInnerSectionOne>
                  {renderErrorMessage()}
                  <Imagediv> <img src={keyimage}></img></Imagediv>
                  <Heading>Change</Heading>
                  <HeadingYourPassword>your password</HeadingYourPassword>
                  {renderInputField(
                    "New password",
                    newPass,
                    setNewPassword,
                    enableNewPassField ? "password" : "text",
                    enableNewPassField,
                    newPassError,
                    "new-password-input",
                    handleClickShowNewPass
                  )}
                  {renderInputField(
                    "Confirm new password",
                    conNewPass,
                    setConfNewPassword,
                    enableConPassField ? "password" : "text",
                    enableConPassField,
                    conNewPassError,
                    "connew-password-input",
                    handleClickShowConPass
                  )}
                  <PasswordRequirementsMobileMainBox>
                    {renderPasswordRequirements()}
                  </PasswordRequirementsMobileMainBox>
                </ModalInnerSectionOne>
                <ConfirmButtonMobileMainBox>
                  <ConfirmButton onClick={handleConfirmSubmit}>Confirm</ConfirmButton>
                </ConfirmButtonMobileMainBox>
              </ModalInnerMainBoxSection>
            </ModalMobileInnerMainBox>
          </Modal>
        </Box>
      }
        </>
    );
  }
}
