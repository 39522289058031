import React, { ReactNode } from "react";
// Customizable Area Start
import {
  Box,
  styled,
  Typography,
  TextField,
  InputAdornment
} from "@material-ui/core";
import {
  searchIcon,
  noDataError,
  calenderIcon,
  createSurveySmallBtn
} from "./assets";
import DateRangeCalender from "./DateRangeCalender.web";
import CreatedSurveyTable from "./CreatedSurveyTable.web";
import { UserSurvey, configJSON, DateRangeProp } from "./LandingPageController";
import DeleteSurveyPopup from "./DeleteSurveyPopup.web";
import SuccessDeletePopup from "./SuccessDeletePopup.web";

const MainHistorySection = styled(Box)({
  borderRadius: "4px",
  background: "#FFF",
  boxShadow: "0px 1px 8px 0px rgba(0, 0, 0, 0.15)",
  padding: "24px 25px 0px 25px",
  marginTop: "30px",
  maxWidth: "1132px",
  width: "100%",
  marginBottom: "17px",
  "@media (min-width: 500px) and (max-width: 1580px)": {
    width: "80%"
  },
  "@media (max-width: 1480px)": {
    width: "90% !important"
  },
  "@media (max-width: 500px)": {
    boxShadow: "none",
    paddingInline: "24px",
    width: "100% !important",
    marginTop: "0",
    paddingTop: "0",
    boxSizing: "border-box",
    marginBottom: "0"
  }
});

const UpperSection = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "9px",

  "@media (max-width: 1180px)": {
    flexDirection: "column",
    gap: "9px"
  }
});

const UpperSectionBox = styled(Box)({
  "@media (max-width: 1580px)": {
    width: "100%"
  }
});

const UpperSectionTextBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  "@media (max-width: 1580px)": {
    width: "100%",
    justifyContent: "flex-start"
  }
});

const UpperSectionText = styled(Typography)({
  color: "#000",
  fontFamily: "Poppins",
  fontSize: "25px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  "@media(max-width:500px)": {
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500
  }
});

const HistorySearchField = styled(TextField)({
  borderRadius: "8px",
  width: "625px",
  paddingLeft: "220px",
  maxHeight: "50px",
  lineHeight: "normal",
  boxSizing: "border-box",

  "@media(max-width: 1580px)": {
    width: "380px",
    paddingLeft: "0px",
    "& .MuiInputBase-input::placeholder": {
      fontSize: "16px"
    }
  },
  "@media(max-width:1480px)": {
    width: "340px"
  },
  "@media(max-width:1380px)": {
    "& .MuiInputBase-input::placeholder": {
      fontSize: "14px"
    }
  },
  "@media(max-width:1181px)": {
    width: "100%",
    "& .MuiInputBase-input::placeholder": {
      fontSize: "18px"
    }
  },
  "& .MuiOutlinedInput-adornedStart": {
    background: "white",
    paddingLeft: "0px"
  },

  "& input::placeholder": {
    fontWeight: 400,
    lineHeight: "normal",
    fontStyle: "normal",
    fontFamily: "Poppins",
    fontSize: "20px",
    "@media (max-width: 543px)": {
      fontSize: "14px"
    }
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px"
  },

  "& .MuiOutlinedInput-input": {
    borderRadius: "8px",
    background: "white",
    padding: "12px 10px 12px 14px",
    width: "100%",
    height: "26px",
    "@media(max-width:500px)": {
      height: "34px"
    },
    "@media (max-width: 375px)": {
      padding: "12px 5px 12px 5px"
    }
  },

  "& .MuiFormHelperText-contained": {
    marginRight: "14px",
    marginLeft: "4px"
  },

  "& .MuiSelect-outlined.MuiSelect-outlined": {
    color: "black !important",
    textAlign: "left"
  },
  "& .MuiInputBase-root": {
    height: "45px"
  },
  "@media(max-width: 1180px)": {
    "& .MuiInputBase-root": {
      height: "50px"
    },
    "& .MuiOutlinedInput-adornedStart": {
      padding: "14px !important"
    },
    "& .MuiInputBase-input::placeholder": {
      fontSize: "18px"
    }
  },
  "@media (max-width: 500px)": {
    "& .MuiInputBase-input::placeholder": {
      fontSize: "14px !important"
    }
  },
  "@media (max-width: 340px)": {
    "& .MuiInputBase-input::placeholder": {
      fontSize: "12px !important"
    }
  }
});

const CustomInputAdornmentStart = styled(InputAdornment)({
  marginRight: "0px"
});

const CalenderSearchField1 = styled(TextField)({
  borderRadius: "8px",
  width: "305px",
  display: "block",
  cursor: "pointer",

  "& .MuiOutlinedInput-adornedStart": {
    background: "white"
  },

  "& input::placeholder": {
    fontWeight: 400,
    lineHeight: "normal",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontSize: "20px",
    paddingLeft: "10px"
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px"
  },

  "& .MuiOutlinedInput-input": {
    background: "white",
    width: "100%",
    padding: "12px 10px 12px 13px",
    borderRadius: "8px",
    height: "26px",
    cursor: "pointer"
  },

  "& .MuiSelect-outlined.MuiSelect-outlined": {
    color: "black !important",
    textAlign: "left"
  },

  '& input[type="number"]': {
    "-moz-appearance": "textfield",

    "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    }
  },

  "& .MuiFormHelperText-contained": {
    marginLeft: "4px",
    marginRight: "14px"
  },

  "@media (max-width: 1580px)": {
    display: "none"
  }
});

const CustomInputAdornmentEnd = styled(InputAdornment)({
  marginLeft: "0px"
});

const CalenderSearchField2 = styled(TextField)({
  display: "none",
  "@media (max-width: 1580px)": {
    display: "block",
    borderRadius: "8px",
    width: "100%",
    cursor: "pointer",

    "& input::placeholder": {
      fontFamily: "Poppins",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      "@media (max-width: 543px)": {
        fontSize: "14px"
      }
    },

    "& .MuiOutlinedInput-adornedStart": {
      background: "white",
      width: "100%"
    },

    "& .MuiInputAdornment-positionStart": {
      order: 2,
      "@media(max-width:1181px)": {
        order: "unset"
      }
    },

    "& .MuiOutlinedInput-input": {
      width: "100%",
      borderRadius: "8px",
      height: "26px",
      background: "white",
      padding: "12px 10px 12px 5px",
      cursor: "pointer",
      "@media (max-width: 500px)": {
        padding: "12px 5px 12px 0px",
        height: "34px"
      },
      "@media (max-width: 340px)": {
        padding: "12px 5px 12px 0px"
      }
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px"
    },

    "& .MuiSelect-outlined.MuiSelect-outlined": {
      textAlign: "left",
      color: "black !important"
    },

    '& input[type="number"]': {
      "-moz-appearance": "textfield",

      "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0
      }
    },

    "& .MuiFormHelperText-contained": {
      marginLeft: "4px",
      marginRight: "14px"
    },
    "& .MuiOutlinedInput-root": {
      height: "45px",
      display: "flex"
    }
  },
  "@media(max-width:1580px)": {
    "& .MuiInputBase-input::placeholder": {
      fontSize: "16px"
    }
  },
  "@media(max-width:1380px)": {
    "& .MuiInputBase-input::placeholder": {
      fontSize: "14px"
    }
  },
  "@media(max-width:1280px)": {
    width: "260px"
  },
  "@media(max-width:1181px)": {
    width: "100%"
  },
  "@media(max-width: 1180px)": {
    "& .MuiOutlinedInput-root": {
      height: "50px"
    },
    "& .MuiInputBase-input::placeholder": {
      fontSize: "18px"
    }
  },
  "@media (max-width: 500px)": {
    "& .MuiInputBase-input::placeholder": {
      fontSize: "14px"
    }
  },

  "@media (max-width: 340px)": {
    "& .MuiInputBase-input::placeholder": {
      fontSize: "12px"
    }
  }
});

const IconSearchImg = styled("img")({
  cursor: "pointer",
  paddingLeft: "15px",
  "@media (max-width: 500px)": {
    width: 24,
    paddingLeft: "20px !important"
  },
  "@media (max-width: 375px)": {
    paddingLeft: "0px !important"
  },
  "@media (max-width: 1180px)": {
    paddingLeft: "34px"
  }
});

const IconCalenderImg1 = styled("img")({
  cursor: "pointer"
});

const IconCalenderImg2 = styled("img")({
  cursor: "pointer",
  "@media (max-width: 1580px)": {
    paddingRight: "7px"
  },
  "@media(max-width:1181px)": {
    paddingLeft: "34px !important"
  },
  "@media (max-width: 500px)": {
    width: 24,
    paddingLeft: "20px !important"
  },
  "@media (max-width: 375px)": {
    paddingLeft: "0px !important",
    paddingRight: "0px !important"
  }
});

const CentreBlueLineBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginTop: "69px",
  "@media (max-width: 543px)": {
    marginTop: "7px"
  }
});
const CentreBlueLine = styled(Box)({
  height: "1px",
  width: "100%",
  background: "#CBD5E1"
});

export const ImageAlert = styled("img")({
  width: "35px",
  height: "35px",
  marginRight: "33px"
});

export const TextFieldView = styled("div")({
  display: "flex",
  width: "714px",
  padding: "5px 60px",
  justifyContent: "center",
  alignItems: "center",
  gap: "14px",
  borderRadius: "8px",
  border: "1px solid  #E2E8F0",
  background: "#red"
});

const ErrorBoxDiv = styled(Box)({
  display: "flex",
  justifyContent: "center"
});

const ErrorBox = styled(Box)({
  minWidth: "491px",
  padding: "12px 10px",
  borderRadius: "4px",
  borderTop: "4px solid #DC2626",
  backgroundColor: "#FEE2E2",
  "@media (max-width: 960px)": {
    minWidth: "97%"
  },
  "@media (max-width: 500px)": {
    minWidth: "95%"
  }
});

const ErrorBoxText = styled(Typography)({
  color: "#DC2626",
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  "@media (max-width: 543px)": {
    fontSize: "14px",
    fontWeight: 500
  },
  "@media (max-width: 400px)": {
    fontSize: "12px"
  }
});

const ErrorImageDiv = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginBottom: "104px"
});
const ErrorImage = styled("img")({
  marginTop: "57px",
  width: "278px",
  height: "278px"
});

const CreateSurveySmallBtnBox = styled(Box)({
  display: "none",
  cursor: "pointer",
  "@media (max-width: 500px)": {
    marginBottom: "0px",
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    position: "fixed",
    bottom: "20px",
    right: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0 0.365px 2.921px 0 rgba(0, 0, 0, 0.15)",
    backgroundColor: "#fff"
  }
});

const CreateSurveySmallBtn = styled("img")({});

interface EmptyHistoryInterface {
  onSelectPopup: any;
  openDatePicker: boolean;
  handleDatePicker: any;
  handleCloseDatePicker: any;
  handleSaveDatePicker: any;
  selection: any;
  handleDateChange: () => void;
  showDateRangeText: boolean;
  rangees: DateRangeProp[];
  months: any;
  startDateRequired: any;
  monthOfStartDate: any;
  endDateRequired: any;
  monthOfEndDate: any;
  createdSurveyLists: UserSurvey;
  openSurveyDetailPopup: boolean;
  surveyPopupData: object;
  handleOpenSurveyDetail: () => void;
  handleCloseSurveyDetail: () => void;
  handleDeleteSurvey: () => void;
  handleEditSurvey: () => void;
  openDeleteSurveyPopup: boolean;
  handleCloseDeleteSurveyPopup: () => void;
  handleConfirmDeleteSurvey: () => void;
  successDeletePopup: boolean;
  handleCloseSucessDeletePopup: () => void;
  showEmptyHistory: boolean;
  totalPages: number;
  searchTextValue: string;
  handleChangeSearchText: (searchEvent: string) => void;
  surveyLoader: boolean;
  handleLinkShare: () => void;
  handleDownloadSurvey: () => void;
  startDate: Date | null;
  endDate: Date | null;
  formatMonthAndDate: () => void;
}

const ErrorMainSection = () => {
  return (
    <>
      <CentreBlueLineBox>
        <CentreBlueLine />
      </CentreBlueLineBox>
      <ErrorBoxDiv>
        <ErrorBox>
          <ErrorBoxText>No Data Found, start by creating survey</ErrorBoxText>
        </ErrorBox>
      </ErrorBoxDiv>
      <ErrorImageDiv>
        <ErrorImage src={noDataError} />
      </ErrorImageDiv>
    </>
  );
};

export default class EmptyHistory extends React.Component<
  any,
  EmptyHistoryInterface
> {
  constructor(props: EmptyHistoryInterface) {
    super(props);
    this.setState({
      showDateRangeText: false
    });
  }

  render(): ReactNode {
    const {
      onSelectPopup,
      openDatePicker,
      handleDatePicker,
      handleCloseDatePicker,
      formatMonthAndDate,
      handleDateChange,
      handleSaveDatePicker,
      createdSurveyLists,
      openSurveyDetailPopup,
      rangees,
      startDate,
      endDate,
      handleOpenSurveyDetail,
      handleCloseSurveyDetail,
      surveyPopupData,
      handleDeleteSurvey,
      handleEditSurvey,
      openDeleteSurveyPopup,
      handleCloseDeleteSurveyPopup,
      handleConfirmDeleteSurvey,
      successDeletePopup,
      handleCloseSucessDeletePopup,
      showEmptyHistory,
      totalPages,
      handleChangePagination,
      searchTextValue,
      handleChangeSearchText,
      surveyLoader,
      handleLinkShare,
      handleDownloadSurvey,
      handleFormateDate
    } = this.props;

    return (
      <>
        <MainHistorySection>
          <UpperSection>
            <UpperSectionTextBox>
              <UpperSectionText>History</UpperSectionText>
            </UpperSectionTextBox>

            <UpperSectionBox>
              <HistorySearchField
                id="outlined-basic"
                data-test-id="searchTextId"
                placeholder={"Search what you are looking for"}
                value={searchTextValue}
                onChange={searchEvent => handleChangeSearchText(searchEvent)}
                variant="outlined"
                type="text"
                InputProps={{
                  startAdornment: (
                    <CustomInputAdornmentStart position="start">
                      <IconSearchImg src={searchIcon} />
                    </CustomInputAdornmentStart>
                  )
                }}
              />
            </UpperSectionBox>
            <UpperSectionBox>
              <CalenderSearchField2
                fullWidth
                id="outlined-basic"
                data-test-id="searchBox"
                placeholder={"Search survey by date"}
                value={
                  startDate
                    ? `${formatMonthAndDate(startDate)} - ${formatMonthAndDate(
                        endDate
                      )}`
                    : ""
                }
                onClick={handleDatePicker}
                variant="outlined"
                type="text"
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconCalenderImg2
                        src={calenderIcon}
                        onClick={handleDatePicker}
                      />
                    </InputAdornment>
                  ),
                  style: {
                    color: "#000",
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 300,
                    lineHeight: "normal"
                  }
                }}
              />
              <CalenderSearchField1
                fullWidth
                id="outlined-basic"
                data-test-id="searchBox"
                placeholder={"Search survey by date"}
                onClick={handleDatePicker}
                value={
                  startDate
                    ? `${formatMonthAndDate(startDate)} - ${formatMonthAndDate(
                        endDate
                      )}`
                    : ""
                }
                variant="outlined"
                type="text"
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <CustomInputAdornmentEnd position="end">
                      <IconCalenderImg1
                        src={calenderIcon}
                        onClick={handleDatePicker}
                      />
                    </CustomInputAdornmentEnd>
                  ),
                  style: {
                    color: "#000",
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 300,
                    lineHeight: "normal"
                  }
                }}
              />
              {openDatePicker && (
                <DateRangeCalender
                  handleDateChange={handleDateChange}
                  rangees={rangees}
                  handleSaveDatePicker={handleSaveDatePicker}
                  handleCloseDatePicker={handleCloseDatePicker}
                />
              )}
            </UpperSectionBox>
          </UpperSection>
          {!searchTextValue &&
            createdSurveyLists.length === 0 &&
            showEmptyHistory && <ErrorMainSection />}
          {searchTextValue &&
            createdSurveyLists.length === 0 &&
            showEmptyHistory && (
              <Box>
                <CentreBlueLineBox>
                  <CentreBlueLine />
                </CentreBlueLineBox>
                <ErrorBoxDiv>
                  <ErrorBox>
                    <ErrorBoxText>{configJSON.searchError}</ErrorBoxText>
                  </ErrorBox>
                </ErrorBoxDiv>
                <ErrorImageDiv>
                  <ErrorImage src={noDataError} />
                </ErrorImageDiv>
              </Box>
            )}
          {createdSurveyLists.length > 0 && (
            <CreatedSurveyTable
              createdSurveyLists={createdSurveyLists}
              openSurveyDetailPopup={openSurveyDetailPopup}
              handleOpenSurveyDetail={handleOpenSurveyDetail}
              surveyPopupData={surveyPopupData}
              handleCloseSurveyDetail={handleCloseSurveyDetail}
              handleDeleteSurvey={handleDeleteSurvey}
              handleEditSurvey={handleEditSurvey}
              totalPages={totalPages}
              handleChangePagination={handleChangePagination}
              surveyLoader={surveyLoader}
              handleLinkShare={handleLinkShare}
              handleDownloadSurvey={handleDownloadSurvey}
              handleFormateDate={handleFormateDate}
            />
          )}
          {openDeleteSurveyPopup && (
            <DeleteSurveyPopup
              handleCloseDeleteSurveyPopup={handleCloseDeleteSurveyPopup}
              message={"Are you sure you want to delete the survey?"}
              firstBtnText={"No"}
              secondBtnText={"Yes"}
              handleConfirmDeleteSurvey={handleConfirmDeleteSurvey}
            />
          )}
          {successDeletePopup && (
            <SuccessDeletePopup
              confirmationText="The survey you wanted to remove has been removed successfully"
              buttonText="Done"
              handleCloseSucessDeletePopup={handleCloseSucessDeletePopup}
            />
          )}
          {searchTextValue && (
            <CreateSurveySmallBtnBox>
              <CreateSurveySmallBtn
                src={createSurveySmallBtn}
                onClick={onSelectPopup}
              />
            </CreateSurveySmallBtnBox>
          )}
        </MainHistorySection>
      </>
    );
  }
}
// Customizable Area End
