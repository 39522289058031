import React from "react";
import { Typography, styled, Button, Box } from "@material-ui/core";
import { confirmationIcon } from "./assets";

interface SuccessDeletePopupInterface {
  confirmationText: string;
  buttonText: string;
  handleCloseSucessDeletePopup: () => void;
}

const CustomConfiramtionDialog = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  zIndex: 2,
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  padding: "40px",
  borderRadius: "8px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
  height: "250px",
  lineHeight: "2rem",
  width: "55%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "25px",
  "@media(max-width: 995px)": {
    width: "65%",
  },
  "@media(max-width: 600px)": {
    gap: "15px"
  },
  "@media(max-width: 500px)": {
    position: "fixed",
    zIndex: 1101,
    height: "281px",
    width: "calc(100% - 48px)",
    boxSizing: "border-box",
    padding: "45px 26px",
    gap: "unset"
  }
});

const PopUpIcon = styled("img")({
  height: "92px",
  width: "92px",
  "@media(max-width: 500px)": {
    height: "60px",
    width: "60px",
    marginBottom:'10px'
  }
});

const Message = styled(Typography)({
  fontFamily: "Poppins, Inter, sans-serif",
  fontSize: "25px",
  lineHeight: "37px",
  fontWeight: 400,
  textAlign: "center",
  maxWidth: "555px",
  "@media(max-width: 500px)": {
    fontSize: "16px",
    lineHeight: "normal",
    letterSpacing:"0"
  },
  "@media(max-width: 370px)": {
    fontSize: "14px",
    lineHeight: "normal",
    letterSpacing:"0"
  }
});

const CustomButton = styled(Button)({
  width: "183px",
  padding: "10px 16px",
  textAlign: "center",
  borderRadius: "8px",
  backgroundColor: "#EBF5FE",
  textTransform: "none",
  color: "white",
  fontWeight: 500,
  fontFamily: "Poppins, Inter",
  background: "linear-gradient(to right, #34B9FC, #7B61FD)",

  "@media(max-width: 650px)": {
    width: "70%"
  },
  "@media(max-width: 500px)": {
    width: "calc(100% - 17px)",
    padding: "10px 10px",
    marginTop:'29px'
  }
});

export const BlurBackground = styled(Box)({
  "-webkit-backdrop-filter": "blur(5px)",
  borderRadius: "16px",
  position: "absolute",
  zIndex: 1,
  width: "100%",
  height: "100%",
  top: -1,
  left: -1,
  background: "rgba(24, 24, 24, 0.40)",
  border: "1px solid transparent",
  "@media(max-width:500px)": {
    position: "fixed",
    zIndex: 1101,
    borderRadius: "0"
  }
});

class SuccessDeletePopup extends React.Component<
  any,
  SuccessDeletePopupInterface
> {
  render() {
    const {
      confirmationText,
      buttonText,
      handleCloseSucessDeletePopup
    } = this.props;
    return (
      <div>
        <BlurBackground />

        <CustomConfiramtionDialog>
          <PopUpIcon src={confirmationIcon} />
          <Message id="alert-dialog-description">{confirmationText}</Message>
          <CustomButton
            data-test-id="success-popup-btn"
            onClick={handleCloseSucessDeletePopup}
          >
            {buttonText}
          </CustomButton>
        </CustomConfiramtionDialog>
      </div>
    );
  }
}

export default SuccessDeletePopup;
