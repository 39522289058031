import { Box, Button, Typography, styled } from "@material-ui/core";
import React from "react";
import Header from "../../user-profile-advanced/src/Header.web";
import { CustomFormSidebar } from "./CustomFormSidebar";
import { GlobalSearchBar } from "./GlobalSearchBar";
import {
  CustomFormWrapperController,
  Props,
} from "./CustomFormWrapperController";
import ConfirmationDialog from "./ConfiramtionPopUp";
import SubsectionDeletePopup from "./SubsectionDeletePopup.web";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';

const ContentWrapper = styled(Box)({
  margin: "100px 40px 50px 0px",
  width:'100%',
  padding: 0,

  "@media(max-width: 900px)": {
    margin: "115px 0px 0px 0px",
    padding: "13px 0px 25px 0px",
  },
  "@media(max-width: 435px)": {
    margin: "46px 0px 0px 0px",
  }
});

const SurveyTitleBox = styled(Box)({
  textAlign: 'center',
  marginBottom:"15px",
  "@media(max-width: 900px)": {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    margin: '13px 24px 20px 24px',
  }
})

const SurveyTitle = styled(Typography)({
  fontFamily: "Poppins, Inter, sans-serif",
  fontWeight: 400,
  fontSize: "30px",
  lineHeight: "45px",
  textAlign: "center",
  padding: "0 10px",
  "@media(max-width: 435px)": {
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "uppercase",
    marginBottom: 0,
    lineHeight: "24px",
  }
});

const ButtonsWrapper = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flexStart",
  flexDirection: "row",
  marginTop: "40px",
  "@media(max-width: 900px)": {
    padding: "0 24px"
  },
  "@media(max-width: 650px)": {
    marginTop: "0px",
    flexDirection: "column",
    justifyContent: "center",
    gap: "20px",
    alignItems: "center",
  },
});

export const SaveButton = styled(Button)({
  width: "183px",
  padding: "10px 16px",
  textAlign: "center",
  borderRadius: "8px",
  backgroundColor: "#EBF5FE",
  textTransform: "none",
  color: "#0090CF",
  fontWeight: 500,
  fontFamily: "Poppins, Inter",
  "&:hover":{
  backgroundColor: "#EBF5FE",
  },
  "@media(max-width: 650px)": {
    width: "70%",
  },
});

export const NextButton = styled(SaveButton)({
  background: "linear-gradient(to right, #34B9FC, #7B61FD)",
  color: "white",
});

export const AddSimilarSectionButton = styled(Button)({
  padding: "10px 80px 10px 80px",
  marginTop: "20px",
  textTransform: "none",
  textAlign: "center",
  backgroundColor: "#EBF5FE",
  color: "#0090CF",
  borderRadius: "8px",
  fontFamily: "Poppins, Inter",
  fontWeight: 500,
  "&:hover":{
    backgroundColor: "#EBF5FE",
  },
  "@media(max-width: 650px)": {
    width: "70%",
  },
});


export const Back = styled(ArrowBackIcon)({
  display: 'none',
  "@media(max-width: 900px)":{
    height: '45px',
    cursor:"pointer",
    display: "block",
    zIndex: 1,
    transform: "Scale(1.5)"
  },
  "@media(max-width: 435px)":{
    height: '24px',
    transform: "Scale(1)"
  }
})
export const Search = styled(SearchIcon)({
  display: 'none',
  "@media(max-width: 900px)":{
    height: '45px',
    display: "block",
    cursor:"pointer",
    zIndex: 1,
    transform: "Scale(1.5)"
  },
  "@media(max-width: 435px)":{
    height: '24px',
    transform: "Scale(1)"

  }
})
export class CustomFormWrapper extends CustomFormWrapperController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      onLinkClick,
      surveyName,
      links,
      children,
      onNextBtnClick,
      onBackBtnClick,
      hasNextSection = true,
      onSaveBtnClick,
      searchSpecificSec,
      handleSectionSearchBar,
      specificSecSearchesData,
      sectionSubSectionList,
      navigationSearchSections,
      listOfSectionsSubsections,
      linksSectionsSubsections,
      name,
      openAccordions,
      deleteSubSecPopup,
      handleCloseDeleteSubsecPopup,
      subSecName,
      handleDeleteElementAccordion,
    } = this.props;
    const { hasOpenSidebar, hasOpenGloableSearchBar } = this.state;

    return (
      <Box>
        <div style={{zIndex:10}}>
        <Header
        data-test-id="header-id"
          username={localStorage.getItem("user-name")}
          propfileId={localStorage.getItem("user-id")}
          profileImg=""
          onMenuClick={() => this.setOpenSidebar(!hasOpenSidebar)}
          onHeaderClick={()=>this.props.navigation.navigate("LandingPage")}
          onProfileClick={()=>this.props.navigation.navigate("UserProfileAdvance")}
        />
        </div>        
        <div style={{display:'flex', marginTop:'0px', gap:'20px'}}>
        <CustomFormSidebar
          onLinkClick={onLinkClick}
          isOpen={hasOpenSidebar}
          handleClose={() => this.setOpenSidebar(false)}
          links={links}
          searchSpecificSec={searchSpecificSec}
          specificSecSearchesData={specificSecSearchesData}
          handleSectionSearchBar={handleSectionSearchBar}
          setOpenSidebar={this.setOpenSidebar}
          sectionSubSectionList={sectionSubSectionList}
          navigationSearchSections={navigationSearchSections}
          listOfSectionsSubsections={listOfSectionsSubsections}
          linksSectionsSubsections={linksSectionsSubsections}
          subSectionName={name}
          openAccordions={openAccordions}
          
        />
        <ConfirmationDialog
          open={this.props.showPopUp}
          handleClose={this.props.onPopUpClose}
          saveBtnResponse={this.props.saveBtnResponse}
          error={this.props.error}
        />
        <ContentWrapper
          style={{
            ...(hasOpenSidebar && !this.isDesktop && { display: "none" }),
          }}
        >
            <SurveyTitleBox>
              <Back data-test-id="back-btn-id" onClick={onBackBtnClick} />
              <SurveyTitle data-test-id="section-title">
                Survey Form: {surveyName}                
              </SurveyTitle>
              <Search data-test-id="global-search-btn"  onClick={this.toggleSearchbar} />
            </SurveyTitleBox>

              {hasOpenGloableSearchBar &&  <GlobalSearchBar
                handleSearch={this.props.handleGlobalSearch}
                handleError={this.props.handleError}
                navigation={this.props.navigation}
              />}      
          {children}
          <ButtonsWrapper>
            <SaveButton
              data-test-id="save-btn"
              onClick={() => onSaveBtnClick("Started")}
            >
              Save
            </SaveButton>
            {hasNextSection ? (
              <NextButton data-test-id="next-btn" onClick={onNextBtnClick}>
                Next
              </NextButton>
            ) : (
              <NextButton
                data-test-id="submit-btn"
                onClick={() => this.props.onSubmit("Completed")}
              >
                Submit
              </NextButton>
            )}
          </ButtonsWrapper>
        {deleteSubSecPopup && <SubsectionDeletePopup 
        handleCloseDeleteSubsecPopup={handleCloseDeleteSubsecPopup}
         message={`Are you sure you want to delete ${subSecName}?`} 
         firstBtnText={"No"} 
         secondBtnText={"Yes"}
         handleDeleteElementAccordion={handleDeleteElementAccordion}
         />}
        </ContentWrapper>
        </div>
      </Box>
    );
  }
}
