import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  password: string;
  passwordRepeat: string;
  showError: boolean;
  error: string;
  isFetching: boolean;
}

interface SS {
  id: any;
}

const createPasswordValidation = (regex: RegExp) => (pwd: string) =>
  regex.test(pwd);

export default class ChangePasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  validationMessageId = "";
  changePasswordMessageId = "";
  private token: string | null = "";

  passwordRequirement = {
    capitalLetter: {
      message: "At least one capital letter",
      validate: createPasswordValidation(/[A-Z]/),
    },
    lowercaseLetter: {
      message: "At least one lowercase letter",
      validate: createPasswordValidation(/[a-z]/),
    },
    number: {
      message: "At least one number",
      validate: createPasswordValidation(/\d/),
    },
    minLength: {
      message: "Minimum character length is 8 characters",
      validate: createPasswordValidation(/^.{8,}$/),
    },
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      password: "",
      passwordRepeat: "",
      showError: false,
      error: "",
      isFetching: false,
    };
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.token = this.parseToken();
  }

  receive(from: string, message: Message): void {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.setState({
        isFetching: false,
      });
      this.handleTokenValidationResponse(message);
      this.handleChangePasswordResponse(message);
    }
  }

  async componentDidMount(): Promise<void> {
    this.sendTokenValidationRequest();
  }

  handlePasswordInput(password: string) {
    this.setState({
      password,
      showError: false,
    });
  }

  handlePasswordRepeatInput(password: string) {
    this.setState({
      passwordRepeat: password,
      showError: false,
    });
  }

  submit() {
    const { password, passwordRepeat } = this.state;
    if (!password) {
      this.setState({
        error: "Enter password.",
        showError: true,
      });
      return;
    }
    const validationResults = this.getPasswordValidation();
    const error = validationResults.find((v) => !v.valid);

    if (error) {
      const errorMessage = `Your password does not meet all the requirements. Must have ${error.message.toLocaleLowerCase()}.`;
      this.setState({
        showError: true,
        error: errorMessage,
      });
      return;
    }
    if (!passwordRepeat) {
      this.setState({
        error: "Repeat password.",
        showError: true,
      });
      return;
    }
    if (password !== passwordRepeat) {
      this.setState({
        error: "Passwords are not matching.",
        showError: true,
      });
      return;
    }
    this.sendChangePasswordRequest();
  }

  backToLogin() {
    this.props.navigation.navigate("EmailAccountLoginBlock");
  }

  getPasswordValidation() {
    const { password } = this.state;
    return Object.values(this.passwordRequirement).map(
      ({ validate, message }) => ({
        valid: validate(password),
        message,
      })
    );
  }

  private parseToken() {
    const search = location.search;
    const query = new URLSearchParams(search);
    return query.get("token");
  }

  private sendTokenValidationRequest() {
    this.setState({
      isFetching: true,
    });
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.validationMessageId = message.messageId;

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_forgot_password/forgot_password?token=${this.token}`
    );
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

    runEngine.sendMessage(this.validationMessageId, message);
  }

  private sendChangePasswordRequest() {
    this.setState({
      isFetching: true,
    });
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));

    const body = {
      data: {
        attributes: {
          token: this.token,
          new_password: this.state.password,
          confirm_password: this.state.passwordRepeat,
        },
      },
    };
    const header = {
      "Content-Type": "application/json",
    };

    this.changePasswordMessageId = message.messageId;

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_forgot_password/reset"
    );
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PATCH");

    runEngine.sendMessage(this.changePasswordMessageId, message);
  }

  private handleTokenValidationResponse(message: Message) {
    if (
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) !==
      this.validationMessageId
    ) {
      return;
    }

    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (response && response.token) {
      this.setState({
        showError: false,
        error: "",
      });

      return;
    }

    if (response && response.errors) {
      const error = response.errors[0].token;
      sessionStorage.setItem("error", error);
      this.props.navigation.navigate("ForgotPassword");
      return;
    }

    const error = "An error has occured";
    sessionStorage.setItem("error", error);
    this.props.navigation.navigate("ForgotPassword");
  }

  private handleChangePasswordResponse(message: Message) {
    if (
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) !==
      this.changePasswordMessageId
    ) {
      return;
    }

    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (response && response.data) {
      this.props.navigation.navigate("ResetConfirmation");
      return;
    }

    if (response && response.errors) {
      const error = response.errors[0];
      this.setState({
        showError: true,
        error: error.token ?? error.password,
      });
      return;
    }

    this.setState({
      showError: true,
      error: "An error has occured",
    });
  }
}
