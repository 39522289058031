import React from "react";

// Customizable Area Start
import {
  styled,
  Box,
  Typography,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import BuildingInfoNav from "./BuildingInfoNav.web";
import BuildingInfoMain from "./BuildingInfoMain.web";
import {buildingInfoLogo,personImage,menuIcon,drawerClose,searchIconSmall} from "./assets"


const surveyForms = [
  {
    id: 1,
    name: "Property Info",
  },
  {
    id: 2,
    name: "Building Information",
  },
  {
    id: 3,
    name: "Midrise or highrise floor info",
  },
  {
    id: 4,
    name: "MDF Info",
  },
  {
    id: 5,
    name: "IDF detail info",
  },
  {
    id: 6,
    name: "Unit Survey",
  },
  {
    id: 7,
    name: "Common Areas",
  },
  {
    id: 8,
    name: "OSP Survey",
  },
];


const HeaderComponent = styled(Box)({
  padding:"8px 45px 8px 42px",
  display:"flex",
  justifyContent:"space-between",
  alignItems:"center",
  marginBottom:"60px",
  boxShadow:"0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
  "@media (max-width: 900px)": {
    padding:"8px 25px 8px 25px"
  },

})


const ResponsiveDrawer = styled(Box)({
  display: "none",
  "@media (max-width: 900px)": {
    display: "flex",
    zIndex: 111,
    flexDirection: "column",
    background: "linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 100%)",
    borderRadius: "0px 20px 20px 0px",
    border: "none",
    height: "100vh",
    width:"100%",
    textAlign:"left",
    position: 'fixed',
    left: 0,
    top: 100,
  },
});

const DrawerCloseIcon = styled("img")({
  paddingLeft:"25px",
  marginTop:"10px",
  width:"24px",
  height:"24px",
  cursor:"pointer",
})

const SurveyFormsBoxDrawer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "9px",
  paddingLeft: "25px",
  marginTop: "42.24px",
  marginBottom: "23.6px",
});

const SurveyFormsBoxDrawerText = styled(Typography)({
  color: "#FFF",
  fontFamily: "Poppins",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  cursor: "pointer",
});const LeftSearchBarBox = styled(Box)({
  padding: "0px 25px 0px 25px",
});

const LeftDrawerSearchBox = styled(TextField)({
  borderRadius: "8px",
  width: "100%",
  border: "1px solid #34B9FC",

  "& input::placeholder": {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },

  "& .MuiOutlinedInput-adornedStart": {
    background: "white",
  },

  "& .MuiOutlinedInput-input": {
    width: "100%",
    borderRadius: "8px",
    background: "white",

    "@media (max-width: 340px)": {
      minWidth: "280px",
      width: "100%",
    },
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#DCE1E4",
    borderRadius: "8px",
  },

  "& .MuiSelect-outlined.MuiSelect-outlined": {
    textAlign: "left",
    color: "black !important",
  },

  '& input[type="number"]': {
    "-moz-appearance": "textfield",

    "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },

  "& .MuiFormHelperText-contained": {
    marginLeft: "4px",
    marginRight: "14px",
  },
});


const LeftBoxSearchIcon = styled("img")({
  paddingTop: "8px",
  cursor: "pointer",
});


const MenuIcon = styled("img")({
  display:"none",
  "@media (max-width: 900px)":{
    display:"block",
    cursor:"pointer",
  }
})



const LogoImage = styled("img")({
})

const ProfileSection = styled(Box)({
display:"flex",
justifyContent:"space-between",
alignItems:"center",
gap:"10px",
"@media (max-width: 900px)":{
  display:"none"
}
})

const EmptyHeaderBox = styled(Box)({
  display:"none",
  "@media (max-width: 900px)":{
    display:"block"
  }
})

const ProfileImage = styled("img")({
  width:"32px",
  height:"32px",
  borderRadius:"50%",
  border:"1px solid #C2E1FA",
  cursor:"pointer",
})

const ProfileText = styled(Typography)({
color:"#424C53",
textAlign:"center",
fontFamily:"Poppins",
fontSize:"18px",
fontStyle:"normal",
fontWeight:500,
lineHeight:"normal",
cursor:"pointer",
})




const CompleteSection = styled(Box)({
  display:"flex",
  padding:"0px 40px 0px 40px",
  gap:"20px",
})

const NavSection = styled(Box)({
  width:"20%",
  "@media (max-width: 900px)": {
    display: 'none'
  },
})

const MainSection = styled(Box)({
  width:"80%",
  "@media (max-width: 900px)": {
    width:"100%",
  },
})
 

// Customizable Area End

import BuildingInformationController, {
  Props,
} from "./BuildingInformationController.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class BuildingInformation extends BuildingInformationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 // Customizable Area End
  render() {
          
    return (
       // Customizable Area Start
      // Required for all blocks
      <>
       <HeaderComponent>
        <MenuIcon src={menuIcon} onClick={this.handleOpenDrawer} />
        <LogoImage src={buildingInfoLogo}/>
        <ProfileSection>
          <ProfileImage src={personImage}/>
          <ProfileText>User name</ProfileText>
          </ProfileSection> 
        <EmptyHeaderBox/> 
        </HeaderComponent>
        {this.state.mobileOpen && 
         <ResponsiveDrawer>
          <DrawerCloseIcon src={drawerClose} onClick={this.handleCloseDrawer}/>

          <SurveyFormsBoxDrawer>
            {surveyForms.map((element) => {
              return (
                <SurveyFormsBoxDrawerText
                  key={`mobile_survey_forms_${element.id}`}
                >
                  {element.name}
                </SurveyFormsBoxDrawerText>
              );
            })}
          </SurveyFormsBoxDrawer>
          <LeftSearchBarBox>
            <LeftDrawerSearchBox
              id="outlined-basic"
              data-test-id="searchBox"
              placeholder={"Jump to specific section"}
              variant="outlined"
              type="text"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LeftBoxSearchIcon src={searchIconSmall}/>
                  </InputAdornment>
                ),
              }}
            />
          </LeftSearchBarBox>
        </ResponsiveDrawer>
        }
       
      <CompleteSection>
     
        <NavSection>
        <BuildingInfoNav  mobileOpen={this.state.mobileOpen} selectedForm={this.state.selectedForm} openDrawer={this.state.openDrawer}  />
        </NavSection>
        <MainSection>
        <BuildingInfoMain checkCopy={this.state.checkCopy} handleCopyCheckbox={this.handleCopyCheckbox}/>
        </MainSection>
      </CompleteSection>
      </>
       // Customizable Area End
    );
  }
}
