import React,{Component, ReactNode}   from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { styled, Box, Typography } from "@material-ui/core";
import moment from "moment-timezone";
import { enGB } from "date-fns/locale";
const momentTwo = require('moment');
import { format } from 'date-fns';
import { DateRangeProp } from "./LandingPageController";


const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const months = [
"Jan",
"Feb",
"Mar",
"Apr",
"May",
"Jun",
"Jul",
"Aug",
"Sep",
"Oct",
"Nov",
"Dec",
 ];


const MainBoxDateRange = styled(Box)({
  background: "rgb(242,245,249)",
  maxWidth: 350,
  width: "100%",
  borderRadius: 25,
  position:"absolute",
  marginLeft: "-20px",
  marginTop: "20px",
  "@media(max-width: 500px)": {
    left: "50%",
    transform: "translateX(-50%)",
    width:"calc(100% - 84px)",
    zIndex: 2
    },
  "@media(max-width: 360px)": {
   width:"calc(100% - 60px)"
   },

  "@media(max-width: 1580px)": {
   marginLeft:"0px"
  }
});

const CustomDateTextHeading = styled(Typography)({
  color: "#1C1B1F",
  fontFamily: "Poppins",
  fontSize: "28px",
  fontWeight: 400,
  paddingLeft: 30,
  paddingTop: 20,
  borderBottom:"1px solid rgba(202, 196, 208, 0.25)",
});

const CustomDateRange = styled(DateRange)({
  background: "rgb(242,245,249)",
  borderRadius: "20px",
  fontSize: "13px",
  padding: "12px",
  paddingTop: 0,
  "& .rdrDayToday .rdrDayNumber span:after": {
    content: "none",
  },
  "& .rdrDayNumber": {
    height: 30,
    fontFamily: "Poppins",
    "@media(max-width:500px)":{
      height: "100%"
    }
  },
  "& .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge": {},
  "& .rdrMonth": {
    width: "unset",
    paddingBottom: "0",
    "@media(max-width:380px)":{
      padding: "0"
    }
  },
  "& .rdrDays": {
    width: "unset",
    "@media(max-width:500px)":{
      display: "grid",
      gridTemplateColumns: "repeat(7, 1fr)"
    }
  },
  "& .rdrWeekDay": {
    fontFamily: "Poppins",
  },
  "& .rdrDayStartPreview": {
    "@media(max-width:500px)":{
      height: "100%",
      width: "100%",
      borderRadius: "100%",
      position: "absolute",
      left: "-1px",
      top: "4px"
    }
  },
  "& .rdrDayEndPreview": {
    "@media(max-width:500px)":{
      height: "100%",
      width: "100%",
      borderRadius: "100%",
      position: "absolute",
      left: "-1px",
      top: "4px"
    }
  },
  "& .rdrDay": {
    width: "43px",
    height: "40px",
    "@media(max-width:500px)":{
      width:"32px",
      height:"32px",
      position:"relative"
    }
  },
  "& .rdrMonth .rdrWeekDays": {
    width: "unset",
    "@media(max-width:500px)":{
      display: "grid",
      gridTemplateColumns: "repeat(7, 1fr)"
    }
  },
  "&.rdrCalendarWrapper": {
    width: 350,
    "@media(max-width: 500px)": {
      width:"100%"
      },
  },
  "& .rdrMonthAndYearWrapper": {
    paddingTop: 0,
    height: "unset",
    paddingBottom: 10,
  },
  "& .rdrDateDisplay": {
    display: "none",
  },

  "& .rdrNextPrevButton": {
    display: "none",
  },

  "& .rdrMonthAndYearPickers": {
    justifyContent: "flex-end",
  },

  "& .rdrStartEdge": {
    background: "linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 100%)",
  },

  "& .rdrInRange": {
    backgroundColor: "rgb(188,192,247)",
  },
  "& .rdrEndEdge": {
    background: "linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 100%)",
    "@media(max-width:500px)":{
      height: "100%",
      width: "100%",
      left: "0",
      borderRadius: "100%"
    },
  },
});

const ButtonBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "20px",
  marginTop:"40px",
  justifyContent: "flex-end",
  padding: "0 20px 10px 0",
  "@media(max-width: 500px)":{
    marginTop:"0",
  }
});

const CancelText = styled(Typography)({
  color: "#000",
  fontFamily: "Poppins",
  fontSize: "14px !important",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "20px",
  letterSpacing: "0.1px",
  cursor: "pointer",
});

const SaveButton = styled(Box)({
  padding: "10px 12px",
  borderRadius: "32px",
  border: "1px solid #E2E8F0",
  background: "#FFF",
  color: "#000",
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "20px",
  letterSpacing: "0.1px",
  cursor: "pointer",
  "@media(max-width:500px)":{
    background:"unset"
  }
});

const BlurBackground = styled(Box)({
  display:"none",
  "@media(max-width: 500px)":{
    display:"block",
    backdropFilter: "blur(3px)",
    "-webkit-backdrop-filter": "blur(3px)",
    borderRadius: "16px",
    position: "absolute",
    height: "calc(100vh - 245px)",
    width:"calc(100% - 48px)",
    zIndex: 1
  }
})



interface DateRangeCalenderInterface {
  handleCloseDatePicker:()=>void,
  handleSaveDatePicker:()=>void,
  handleDateChange:()=>void;
  rangees: DateRangeProp[];
}

export default class DateRangeCalender extends Component<
  any,
  DateRangeCalenderInterface
> {
  constructor(props: DateRangeCalenderInterface) {
    super(props);
  }

  render(): ReactNode {
    const {handleCloseDatePicker,handleDateChange,handleSaveDatePicker,rangees} = this.props;
    
    const currentDate = new Date();
    const dayOfWeek = format(currentDate, 'EEE'); 
    const month = format(currentDate, 'MMM');
    const dateOfMonth = format(currentDate, 'dd');


    return (
      <>
      <BlurBackground />
      
      <MainBoxDateRange>
        <CustomDateTextHeading>{`${dayOfWeek}, ${month} ${dateOfMonth}`}</CustomDateTextHeading>

        <CustomDateRange    
          editableDateInputs={false}
          onChange={handleDateChange}
          moveRangeOnFirstSelection={false}
          ranges={rangees}
          showDateDisplay={false} 
        />
        <ButtonBox>
          <CancelText onClick={handleCloseDatePicker}>Cancel</CancelText>
          <SaveButton onClick={handleSaveDatePicker}>Save</SaveButton>
        </ButtonBox>
      </MainBoxDateRange>
      </>
    );
  }
}
